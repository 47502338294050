<ng-container *ngIf="canShowOnboarding$ | async">
  <div (click)="done()" class="onboarder open"  *ngIf="currentSection$ | async as currentSection">

    <div class="container onboarder-inner">
      <div class="onboarder-cont" *ngIf="currentSection">
        <div class="onboarder-title">{{currentSection.title}}</div>
        <div class="onboarder-text">
          <p>{{currentSection.description}}</p></div>
         <div class="onboarder-img d-flex justify-content-center">
          <img [src]="currentSection.image" alt="">
        </div>
      </div>
        <button class="btn btn-woke-action red onboard" (click)="done($event)" *ngIf="isFinished$ | async as isFinished; else isDone"> {{'onboardings.done' | translate }}</button>
        <ng-template #isDone>
          <button class="btn btn-woke-action red onboard" (click)="continue($event)">{{'onboardings.continue' | translate }}</button>
        </ng-template>
    </div>
  </div>
</ng-container>
