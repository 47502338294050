<div class="d-flex flex-column p-3">
  <h6 class="mb-4">{{'checkout.select-payment-method.title' | translate }}</h6>
  <ng-container *ngIf="paymentMethods$ | async as payments">
    <div class="jumbo-mask p-2 mb-4 {{partialIndex === item.id ? 'active' : ''}}" *ngFor="let item of payments" (click)="partialSelect(item)">

      <div *ngIf="item.id =='spei'; then spei; else card"></div>

      <ng-template #spei>
          <div [ngClass]="getClass(item.brand)"></div>
          <div class="mt-2 mb-2">
            <div>SPEI</div>
          </div>
      </ng-template>

      <ng-template #card>
        <div [ngClass]="getClass(item.brand)"></div>
        <div class="mb-4">
          <div class="gray">{{'checkout.select-payment-method.card-number' | translate }}</div>
          <div>{{item.data?.card_number}}</div>
        </div>
        <div>
          <div class="gray">{{'checkout.select-payment-method.expiration' | translate }}</div>
          <div>{{item.data?.expiration_month.length > 1 ? item.data?.expiration_month : '0'+item.data?.expiration_month }}/{{item.data?.expiration_year}}</div>
        </div>
      </ng-template>

    </div>
    <div class="d-flex">
      <button class="btn btn-action btn-gray mr-3" type="button" (click)="addNew()">{{'checkout.select-payment-method.add' | translate }}</button>
      <button class="btn btn-action btn-red" type="button" (click)="selectPaymentMethod()">{{'checkout.select-payment-method.select' | translate }}</button>
    </div>
  </ng-container>
</div>
