<div>
  <p class="black  mb-4 text-left">{{'licenses.copy-add-member' | translate }}</p>
  <form>
    <div class="form-group">
      <input autocomplete="off" name="teamMember" [formControl]="teamMemberControl" class="jumbo-mask" type="text" placeholder="{{'workspace.myOffice.add-member-modal.username' | translate }}">
    </div>
    <app-spinner *ngIf="searching; else searchedTemplate"></app-spinner>
    <ng-template #searchedTemplate>
      <div class="users-found">
        <ng-container *ngIf="!usersFound.length && searched; else usersNotFoundTemplate">
          <div style="width: 100%" class="red semi-bold d-flex align-items-center justify-content-center">{{'workspace.myOffice.add-member-modal.user-not-found' | translate }}</div>
        </ng-container>
        <ng-template #usersNotFoundTemplate>
          <div class="user-found" *ngFor="let userFound of usersFound" (click)="selectUser(userFound)">
            <div class="avatar" [ngClass]="{'selected': userFound.selected}">
              <img [src]="userFound.image" alt="">
            </div>
            <div class="user-name">{{userFound.username}}</div>
          </div>
        </ng-template>
      </div>
    </ng-template>
    <button type="button" class="btn btn-woke-action red mt-4" (click)="addUserConfirm()">
      <span *ngIf="!saving;else loaderTeamMember">{{'workspace.myOffice.add-member-modal.confirm' | translate }}</span>
      <ng-template #loaderTeamMember>
        <div class="spinner-grow spinner-grow-sm" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </ng-template>
    </button>
  </form>
</div>
