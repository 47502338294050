<div class="background-gradient">
    <div class="navbar">
        <!-- * Logotype -->
        <a [routerLink]="'/'"><img class="logo" src="/assets/img/logos/Logo_Adstop.png" alt="Adstop"></a>

        <button class="close-public" (click)="goBack()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="row ">
        <div class="form-container">
            <p class="white-text text-center fw-600">{{'landing.login.log-in' | translate }}</p>

            <!-- * Login Form -->
            <form class="minimalist transparent" [formGroup]="authForm" (ngSubmit)="logIn()">

                <!-- * Email Field -->
                <div class="form-group">
                    <label for="email">{{'landing.login.email' | translate }}</label>
                    <input type="email" class="form-control" id="email" placeholder="{{'landing.login.email-ph' | translate }}" formControlName="username">
                    <small *ngIf="!errorMessage" (click)="showModal()" style="cursor: pointer;" class="text-alert text-alert-valid">{{'landing.login.forgot' | translate }}</small>
                    <small *ngIf="errorMessage" class="text-alert">{{'landing.login.email-error' | translate }}</small>
                </div>

                <!-- * Password Field -->
                <div class="form-group">
                    <label for="password">{{'landing.login.password' | translate }}</label>
                    <input type="password" class="form-control" id="password" placeholder="********" formControlName="password">
                    <small *ngIf="errorMessage" class="text-alert text-alert-valid">{{'landing.login.pass-error' | translate }}</small>
                    <!-- <small *ngIf="errorMessage" (click)="showModal()" style="cursor: pointer;" class="text-alert text-alert-valid">I forgot my password</small> -->
                </div>

                <!-- * Forgot Password Button -->

                <!-- <button type="button" (click)="showModal()" class="btn btn-link">{{'landing.login.forgot' | translate }}</button> -->
                <ng-container *ngIf="errorMessage && error">
                    <!-- * Invalid Credentials -->
                    <p (click)="showModal()" class="white-text forgot fw-600">{{'landing.login.forgot' | translate }}</p>
                </ng-container>

                <!-- * Submit Button -->
                <div class="buttons">
                    <!-- * Continue -->
                    <button type="submit" class="btn btn-woke btn-white" [disabled]="loading">
              <span *ngIf="!loading;else loader">{{'landing.login.continue' | translate }}</span>
              <ng-template #loader>
                <div class="spinner-grow spinner-grow-sm" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </ng-template>
            </button>
                    <!-- * Create Account Button -->
                    <button (click)="goRegister()" class="btn btn-woke btn-wine">{{'landing.login.create-account' | translate }}</button>
                </div>
            </form>

            <p (click)="goManager()" style="cursor: pointer; color: balck;" class="text-center text-alert text-alert-valid mt-5">{{'landing.login.spaces' | translate }} <span style="color: #5ED2AA;">Inicia sesión aquí</span></p>
        </div>
        <!-- Social Networks -->
        <!-- <ul class="social-networks">
      <li><a class="tw" href="https://twitter.com/supportwoke" target="_system"><i class="fab fa-twitter"></i></a></li>
      <li><a class="fb" href="https://www.facebook.com/wokemx" target="_system"><i class="fab fa-facebook-f"></i></a></li>
      <li><a class="ig" href="https://www.instagram.com/wokemx" target="_system"><i class="fab fa-instagram"></i></a></li>
      <li><a class="ld" href="https://www.linkedin.com/company/wokemx" target="_system"><i class="fab fa-linkedin-in"></i></a></li>
    </ul> -->
    </div>

</div>
