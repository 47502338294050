import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-woke-comments',
  templateUrl: './woke-comments.component.html',
  styleUrls: ['./woke-comments.component.scss']
})
export class WokeCommentsComponent implements OnInit {

  myCommentControl: UntypedFormControl;
  myProfilePicture = 'https://e.radio-oxigeno.io/normal/2018/06/13/440244_626265.jpg'
  myComments = [];
  doneProject = false;


  constructor(
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.initMyComment();

  }

  initMyComment() {
    this.myCommentControl = this.fb.control('');
  }

  resetMyComment() {
    this.myCommentControl.patchValue('');
  }

  addComment() {
    if (this.myCommentControl.value) {
      const comment = {
        text: this.myCommentControl.value
      };
      this.myComments = [...this.myComments, comment];
      this.resetMyComment();
    }
  }

}
