import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalsService } from '../modals.service'
import { takeUntil, take } from 'rxjs/operators';
import { Subject } from 'rxjs';

const EMAIL_REGEX = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  public forgotForm: UntypedFormGroup;
  public loading: boolean = false;
  public submittedForm: boolean = false;
  notFound:boolean
  killall = new Subject()
  constructor(public form: UntypedFormBuilder, public modalRef: BsModalRef, private modalService: ModalsService) {
    this.forgotForm = this.form.group({
      email: form.control('', [Validators.required, Validators.email])
    });
  }

  ngOnInit() {}

  ngOnDestroy(){
    this.killall.next();
    this.killall.complete();
  }
  recoverPass() {
    if(this.forgotForm.valid) {
      console.log(this.forgotForm.get('email').value);

      this.loading = true;
      this.modalService.forgotPassword(this.forgotForm.get('email').value).pipe(
        take(1),
        takeUntil(this.killall)
      ).subscribe({
        next: response =>{
          console.log(response);
          this.loading = false;
          this.notFound = false;
          this.submittedForm = true;

        },
        error: err =>{
          console.log(err);
          this.notFound = true;
          this.loading = false;
        }
      })
    }
  }


}
