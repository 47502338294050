<div class="background-gradient">
    <div class="navbar">
        <!-- * Logotype -->
        <a [routerLink]="'/'"><img class="logo" src="/assets/img/logos/Logo_Adstop.png" alt="Adstop"></a>

        <button class="close-public" (click)="goBack()">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>

    <div class="row">
        <div class="form-container">
            <!--  ? Contact -->
            <p class="white-text text-center fw-600">{{'landing.contact.contact' | translate }}</p>

            <!-- * Contact Form -->
            <form class="minimalist transparent pb-5" [formGroup]="contactForm" (ngSubmit)="contact(modalContactSuccess)">

                <!-- * First Name Field -->
                <div *ngIf="!contactSpace" class="form-group">
                    <label for="firstName">{{'landing.contact.first-name' | translate }}</label>
                    <input type="text" class="form-control" id="firstName" placeholder="Nombre" formControlName="firstName">
                    <small *ngIf="contactForm.controls.firstName.invalid && contactForm.controls.firstName.touched" class="text-alert">{{'landing.contact.only-letters' | translate }}</small>
                </div>

                <!-- ? Space Person Name Field -->
                <div *ngIf="contactSpace" class="form-group">
                    <label for="name">{{'landing.contact.first-name' | translate }}</label>
                    <input type="text" class="form-control" id="name" placeholder="Nombre" formControlName="name">
                    <small *ngIf="contactForm.controls.name.invalid && contactForm.controls.name.touched" class="text-alert">{{'landing.contact.only-letters' | translate }}</small>
                </div>

                <!-- * Last Name Field -->
                <div *ngIf="!contactSpace" class="form-group">
                    <label for="lastName">{{'landing.contact.last-name' | translate }}</label>
                    <input type="text" class="form-control" id="lastName" placeholder="Apellido" formControlName="lastName">
                    <small *ngIf="contactForm.controls.lastName.invalid && contactForm.controls.lastName.touched" class="text-alert">{{'landing.contact.only-letters' | translate }}</small>
                </div>

                <!-- ? Space Name  -->
                <div *ngIf="contactSpace" class="form-group">
                    <label for="spaceName">{{'landing.contact.space-name' | translate }}</label>
                    <input type="text" class="form-control" id="spaceName" placeholder="{{'landing.contact.space-name-ph' | translate }}" formControlName="spaceName">
                    <!-- <small *ngIf="contactForm.controls.spaceName.invalid && contactForm.controls.spaceName.touched" class="text-alert">{{'landing.contact.only-letters' | translate }}</small> -->
                </div>

                <!-- ? Space Email Field -->
                <div *ngIf="contactSpace" class="form-group">
                    <label for="mail">{{'landing.contact.email' | translate }}</label>
                    <input type="email" class="form-control" id="mail" placeholder="Correo@mail.com" formControlName="mail">
                    <small *ngIf="contactForm.controls.mail.invalid && contactForm.controls.mail.touched" class="text-alert">{{'landing.contact.invalid-email' | translate }}</small>
                </div>

                <!-- ? Space Phone Field -->
                <div *ngIf="contactSpace" class="form-group">
                    <label for="phone">{{'landing.contact.phone' | translate }}</label>
                    <input type="number" class="form-control" id="phone" placeholder="+55 555555555" formControlName="phone">
                    <small *ngIf="contactForm.controls.phone.invalid && contactForm.controls.phone.touched" class="text-alert">{{'landing.contact.invalid-email' | translate }}</small>
                </div>

                <!-- * Email Field -->
                <div *ngIf="!contactSpace" class="form-group">
                    <label for="email">{{'landing.contact.email' | translate }}</label>
                    <input type="email" class="form-control" id="email" placeholder="Correo@gmail.com" formControlName="email">
                    <small *ngIf="contactForm.controls.email.invalid && contactForm.controls.email.touched" class="text-alert">{{'landing.contact.invalid-email' | translate }}</small>
                </div>

                <!-- * Subject Field -->
                <div *ngIf="!contactSpace" class="form-group">
                    <label for="subject">{{'landing.contact.subject' | translate }}</label>
                    <input type="text" class="form-control" id="subject" placeholder="{{'landing.contact.subject-placeholder' | translate }}" formControlName="subject">
                    <small *ngIf="contactForm.controls.subject.invalid && contactForm.controls.subject.touched" class="text-alert">{{'landing.contact.field-required' | translate }}</small>
                </div>

                <!-- * Message Field -->
                <div class="form-group">
                    <label for="message">{{'landing.contact.message' | translate }}</label>
                    <textarea class="form-control" id="message" placeholder="{{'landing.contact.message-placeholder' | translate }}" formControlName="message" rows="3"></textarea>
                    <small *ngIf="contactForm.controls.message.invalid && contactForm.controls.message.touched" class="text-alert">{{'landing.contact.field-required' | translate }}</small>
                </div>

                <!-- * Recaptcha Field -->
                <recaptcha *ngIf="!contactSpace" formControlName="recaptcha"></recaptcha>

                <!-- * Privacy Notice Button -->
                <p class="policy">
                    {{'landing.contact.by-sending' | translate }}
                    <span class="terms-conditions" (click)="showPolicies(wokeTemplate)">{{'landing.contact.policy' | translate }}</span>
                    <!-- <b (click)="showTerms(wokeTemplate)" >{{'landing.contact.terms' | translate }}</b>
            {{'landing.contact.and' | translate }}
            <b (click)="showPolicies(wokeTemplate)">{{'landing.contact.policy' | translate }}</b>
         --></p>

                <!-- * Submit Button -->
                <button type="submit" class="btn btn-woke btn-white" [disabled]="loading">
            <span *ngIf="!loading;else loader">{{'landing.contact.send' | translate }}</span>
            <ng-template #loader>
              <div class="spinner-grow spinner-grow-sm" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </ng-template>
          </button>

            </form>

        </div>
    </div>

</div>

<ng-template #wokeTemplate>
    <div class="modal-header">
        <div class="back_arrow" (click)="modalRef.hide()"></div>
        <h5 class="modal-title">{{title}}</h5>
    </div>
    <app-woke-terms [content]=content></app-woke-terms>
</ng-template>

<ng-template #modalContactSuccess>
    <div class="modal-header">
        <!-- * Thank you for contacting us -->
        <h5 class="modal-title">Gracias por contactarnos</h5>
    </div>
    <div class="modal-body">
        <p class="text-center">En breve te contactaremos</p>
    </div>
    <div class="modal-footer">
        <button (click)="modalRef.hide()" [routerLink]="['/login']" type="button" class="btn btn-link red" data-dismiss="modal">Ok</button>
    </div>
</ng-template>