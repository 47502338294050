import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { CheckoutService } from '../../checkout.service';
import { Subject } from 'rxjs';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { takeUntil } from 'rxjs/operators';
import { SelectPaymentMethodComponent } from '../select-payment-method/select-payment-method.component';
import { AddPaymentMethodComponent } from '../add-payment-method/add-payment-method.component';
import { PaymentMethod } from 'src/app/api.interfaces';
import { StripeScriptTag } from 'stripe-angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-default-payment-method',
  templateUrl: './default-payment-method.component.html',
  styleUrls: ['./default-payment-method.component.scss']
})
export class DefaultPaymentMethodComponent implements OnInit, OnDestroy {

  paymentDefault$ = this.checkoutService.paymentDefault$;
  private publishableKey:string = environment.STRIPE_KEY

  modalConfig: ModalOptions = { class: 'modal-dialog-centered modal-xl' };
  @Output() paymentMethodChange = new EventEmitter<PaymentMethod>();

  killall = new Subject();

  constructor(
    private checkoutService: CheckoutService,
    private modalService: BsModalService,
    public StripeScriptTag:StripeScriptTag

  ) {
    this.StripeScriptTag.setPublishableKey( this.publishableKey )
   }

  ngOnInit() {
    this.checkoutService.loadPaymentMethods();
    this.paymentDefault$.pipe(
      takeUntil(this.killall)
    ).subscribe({
      next: paymentMethod => {
        this.paymentMethodChange.emit(paymentMethod);
      },
      error: () => {},
      complete: () => console.log('Payment Method Default stopped')
    });
  }

  ngOnDestroy() {
    this.closeModal();
    this.killall.next();
    this.killall.complete();
  }

  closeModal() {
    this.modalService.hide();
    document.querySelector('body').classList.remove('modal-open');
  }

  changePaymentMethod() {
    this.modalService.show(SelectPaymentMethodComponent, this.modalConfig);
  }

  addPaymentMethod() {
    this.modalService.show(AddPaymentMethodComponent, this.modalConfig);
  }

  getClass = (brand: string) => {
    switch (brand) {
      case 'MasterCard':
        return 'master_icon';
      case 'Visa':
        return 'visa_icon';
      case 'American Express':
        return 'american_icon';
      case 'spei':
        return 'spei';
      default:
        return 'nobrand';
    }
  }

}
