<div [ngStyle]="{'background':bg === undefined?'#f5f5f5':'white'}" (click)="toggleShowOptions()" class="jumbo-mask select value" [ngClass]="{'open': showOptions,'round':className === 'round', 'calc':className === 'calc', 'calc-two':className === 'calc-two'}">
    <!-- Esta es la opción seleccinada class="select-value"-->
    <div [ngStyle]="{'background':bg === undefined?'#f5f5f5':'white'}">
        <div class="arrow" [ngClass]="{'open': showOptions}"></div>
        <ng-container *ngIf="value && !showPlaceholder; else placeholderTemplate">
            <div>
                <ng-container [ngTemplateOutlet]="selected" [ngTemplateOutletContext]="{ value: value }"></ng-container>
            </div>
        </ng-container>
        <ng-template #placeholderTemplate>
            <div class="placeholder">{{placeholder}}</div>
        </ng-template>
    </div>

</div>
<div class="jumbo-mask select options" *ngIf="showOptions">
    <!-- Estas son las opcioness -->
    <div class="select-options" [ngClass]="{'round':className === 'round', 'calc':className === 'calc', 'calc-two':className === 'calc-two'}">
        <!-- * isTitle es pàra los titulos de tipos en landing -->
        <div class="select-option" [ngClass]="{'isTitle':option.isTitle}" *ngFor="let option of options" (click)="selectOption(option)">
            <ng-container [ngTemplateOutlet]="item" [ngTemplateOutletContext]="{ option: option }">
            </ng-container>
        </div>
    </div>
</div>
