<div class="jumbo-mask">
  <div class="gray mb-4">Revision 1</div>
  <div class="align-items-center d-flex mb-4">
    <div class="woke-thumb">
      <img src="http://www.soundspheremag.com/wp-content/migration/images/stories/Crystal_Castles4-400x300.jpg" alt="">
    </div>
    <div class="woke-shadow py-3 px-4 ml-4">
      No creo necesitar el manual de identidad
    </div>

  </div>

  <div class="align-items-center d-flex mycomment mb-4" *ngFor="let comment of myComments">
    <div class="woke-shadow py-3 px-4 mr-4">
      {{comment.text}}
    </div>
    <div class="woke-thumb">
      <img [src]="myProfilePicture" alt="">
    </div>

  </div>

  <div *ngIf="!doneProject">
    <input maxlength="500" [formControl]="myCommentControl" type="text" class="comment_input" placeholder="Comment" (keydown.enter)="addComment()" />
  </div>

</div>
