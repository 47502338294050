import { Component, OnInit, OnDestroy, TemplateRef } from '@angular/core';
import { Router } from "@angular/router";
import { take, takeUntil, map, filter } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Subject, combineLatest } from 'rxjs';
import { Workspace, PaymentMethod, MyOffice } from 'src/app/api.interfaces';
import { QuickModel } from 'src/app/logged/workspaces/workspace/quick/quick.model';
import { CheckoutWorkspaceViewModel } from './checkout-workspace.view-model';
import { CheckoutWorkspaceService } from './checkout-workspace.service';
import { LoggedService } from 'src/app/logged/logged.service';
import { WalletFacade } from 'src/store/wallet/wallet.facade';

@Component({
  selector: 'app-checkout-workspace',
  templateUrl: './checkout-workspace.component.html',
  styleUrls: ['./checkout-workspace.component.scss']
})
export class CheckoutWorkspaceComponent implements OnInit, OnDestroy {

  loading = false;

  workspace: Workspace;
  bookingType: string;
  offices: MyOffice[] = [];

  quickModel: QuickModel;
  formGroup: UntypedFormGroup;
  invites: string[] = [];
  useBusinessPlan = false;
  paymentMethod: PaymentMethod;
  officeControl: UntypedFormControl;

  total: {
    text?: string;
    remainingHours?: string;
    coins?: number;
  };

  killall = new Subject();

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private checkoutWorkspaceService: CheckoutWorkspaceService,
    public loggedService: LoggedService,
    private walletFacade: WalletFacade
  ) { }

  ngOnInit() {
    this.initControl();
    this.initQuickModel();
    this.walletFacade.loadPaymentMethods();
  }

  ngOnDestroy() {
    this.loggedService.closeAll();
    this.killall.next();
    this.killall.complete();
  }

  onWorkspace(workspace: Workspace) {
    this.workspace = workspace;
  }

  onPayment(paymentMethod: PaymentMethod) {
    this.paymentMethod = paymentMethod;
  }

  onInvites(invites: string[]) {
    this.invites = invites;
  }

  getTotal(office: MyOffice) {
    if (!office) {
      return undefined;
    }
    const complement = (office as MyOffice).remainingHours;
    const timeSelected = this.quickModel.getTimeSelected();
    if (complement) {
      return {
        text: `-${timeSelected} of ${complement} hours`,
        remainingHours: complement
      }
    }
    return {
      text: `${timeSelected} hours`
    }
  }

  getRemainingHours(id: number) {
    return this.offices.find(office => office.id === id);
  }

  initControl() {
    this.formGroup = this.fb.group({
      nameBooking: '',
      specialRequest: ''
    });
    this.officeControl = this.fb.control('Office');
    this.officeControl.valueChanges.pipe(
      map(id => this.getRemainingHours(+id)),
      filter(officeFound => officeFound !== undefined),
      map(officeFound => this.getTotal(officeFound)),
      takeUntil(this.killall)
    ).subscribe(total => {
      this.total = total;
    });
  }

  initQuickModel() {
    combineLatest(
      this.checkoutWorkspaceService.quickModel$,
      this.checkoutWorkspaceService.bookingType$,
      this.checkoutWorkspaceService.offices$,
    ).pipe(
      take(1),
      takeUntil(this.killall),
    ).subscribe({
      next: ([quickModel, bookingType, offices]) => {
        if (quickModel === undefined) {
          return this.router.navigate(['workspace/'])
        }
        this.bookingType = bookingType;
        this.quickModel = quickModel;
        this.offices = offices;
        console.log(this.offices);

      },
      error: error => {
        console.warn(error);
        this.loggedService.showError(error);
      }
    });
  }

  confirm(template: TemplateRef<any>) {
    const checkoutPayload = {
      quickModel: this.quickModel,
      nameBooking: this.formGroup.get('nameBooking').value,
      specialRequest: this.formGroup.get('specialRequest').value,
      invites: this.invites,
      useBusinessPlan: this.useBusinessPlan,
      paymentMethod: this.paymentMethod,
      officeControl: this.officeControl.value,
      total: this.total
    };
    const checkout = new CheckoutWorkspaceViewModel(checkoutPayload);
    if (!checkout.isValid()) {
      const error = checkout.getError();
      return this.loggedService.showError(error);
    }

    if (!this.loading) {
      this.loading = true;
      if (checkout.isBooking()) {
        this.checkoutWorkspaceService.checkoutProcess(this.workspace, checkout, this.invites).pipe(
          take(1),
          takeUntil(this.killall)
        ).subscribe({
          next: data => {
            this.loading = false;
            if (!data.error) {
              return this.loggedService.openModal(template);
            } else {
              // Error
              console.warn(data.error);
              this.loggedService.showError(data.msg);
            }
          },
          error: error => {
            console.warn(error);
            this.loading = false;
            this.loggedService.showError(error);
          }
        })
      } else {
        this.checkoutWorkspaceService.bookingProcess(this.workspace, checkout, this.invites).pipe(
          take(1),
          takeUntil(this.killall)
        ).subscribe({
          next: data => {
            this.loading = false;
            if (!data.error) {
              return this.loggedService.openModal(template);
            } else {
              // Error
              console.warn(data.error);
              this.loggedService.showError(data.msg);
            }
          },
          error: error => {
            console.warn(error);
            this.loading = false;
            this.loggedService.showError(error);
          }
        })
      }
    }
  }

  goToWorkspace() {
    return this.router.navigate(['/workspace']);
  }

}
