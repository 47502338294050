<div class="time-container">
  <div class="time-header">{{'date-time-picker.select-range' | translate }}</div>
  <div id="timeBox" class="time-body">
    <div
      class="{{hour.past ? 'past' : ''}} {{hour.active ? 'active' : ''}} {{hour.partial ? 'partial' : ''}} {{hour.taken ? 'taken': ''}}"
      *ngFor="let hour of hours"
      (click)="active(hour)">{{hour.time}}</div>
  </div>
  <div *ngIf="!autoSelection" class="time-button" (click)="apply()">OK</div>
</div>
