<div class="file_wrapper">
    <div (click)="file.click()" class="jumbo-mask upload_file">
        <div class="d-flex justify-content-between align-items-center">
            <ng-container *ngIf="customSrc; else placeholderTemp">
                <div class="name_container">{{customSrc}}</div>
            </ng-container>
            <ng-template #placeholderTemp>
                <div class="gray name_container">{{placeholder===undefined? 'letter.pdf':placeholder}}</div>
                <div class="cross"></div>
            </ng-template>
        </div>
    </div>
    <div class="download_icon" *ngIf="result && !cleanMode" (click)="downloadFile()">
        <img src="assets/img/icons/download-icon.png" alt="">
    </div>
    <div class="delete_icon" *ngIf="cleanMode && customSrc" (click)="cleanFile()">
        <img src="assets/img/icons/close_icon.png" alt="">
    </div>
</div>
<input #file (change)="fileChange($event.target.files)" class="inputfile" type="file" />