import { Component, OnInit, Input } from '@angular/core';
import { Payment } from 'src/app/api.interfaces';
import { WalletService } from '../../../store/wallet/wallet.service';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment'

export interface WokeBalance {
  id?: string;
  concept: string;
  date: string;
  value: string | number;
  warning?: boolean;
}

@Component({
  selector: 'app-woke-balance',
  templateUrl: './woke-balance.component.html',
  styleUrls: ['./woke-balance.component.scss']
})
export class WokeBalanceComponent implements OnInit {

  @Input() dynamicColors = false;
  @Input() showTotal = false;
  @Input() shortable = false;

  @Input() payments: Payment[] = [];


  killall = new Subject();
  loading = false;
  url = null;

  constructor(
    private walletService: WalletService
  ) { }

  ngOnInit() {
  }

  downloadFactura(id){
    this.loading = true;
    this.walletService.getInvoice(id).pipe(
      take(1),
      takeUntil(this.killall)
    ).subscribe({
      next: response =>{
        this.loading = false;
        console.log(response);
        this.url = response.url;
        console.log(this.url);
        window.open(environment.resourceUrl + this.url.pdf, "_blank");
      },
      error: err => {
        this.loading = false;
        console.log(err);
      }
    })
  }

  downloadXML(id){
    this.loading = true;
    this.walletService.getInvoice(id).pipe(
      take(1),
      takeUntil(this.killall)
    ).subscribe({
      next: response =>{
        this.loading = false;
        console.log(response);
        this.url = response.url;
        console.log(this.url);
        window.open(environment.resourceUrl + this.url.xml, "_blank");
      },
      error: err => {
        this.loading = false;
        console.log(err);
      }
    })
  }

}
