<div class="chat_item {{chat.active ? 'active' : ''}}">
  <ng-container *ngFor="let user of chat.users; let i = index">
    <div class="chat_thumb" *ngIf="i === chat.users.length - 1">
      <ng-container *ngIf="user.image">
        <img [src]="user.image"  alt="Avatar">
      </ng-container>
    </div>
  </ng-container>
  <div class="chat_content">
    <div class="chat_content_title">
      <ng-container *ngFor="let user of chat.users; let i = index">
        <div class="chat_title_name" *ngIf="i === chat.users.length - 1">{{user.firstName}}</div>
      </ng-container>
      <div class="chat_title_hour">{{chat.message?.createdAt | date:'hh:mm aaaaa\'m\''}}</div>
    </div>
    <div class="chat_down">
      <div class="chat_title_text">{{getMessage()}}</div>
      <div class="badge" *ngIf="chat.isReaded()">{{chat.unread}}</div>
    </div>
  </div>
</div>
