<div class="jumbo-mask mb-5 pt-4">
  <div class="logo_container" [routerLink]="'/workspace/child/' + office.coworkingChild.id">
    <img [src]="loggedService.getUrl(office.coworkingChild.profilePicture)"  alt="office.coworkingChild.name" alt="público">
  </div>
  <div [ngSwitch]="pending">
    <div *ngSwitchCase="true">
      <p><strong>Pending approval from Público Muzquiz.</strong></p>
    </div>
    <div *ngSwitchCase="false">
      <div class="d-flex justify-content-between mb-3">
        <p class="semi-bold">{{office.coworkingChild?.name}}</p>
      </div>

      <div class="mb-3">
        <!-- ? Office Number  -->
        <p class="gray mb-0">{{'workspace.office-cards.office-number' | translate }}</p>
        <p>{{office.officeNumber}}</p>
      </div>

      <div class="mb-3">
        <!-- ? Contract Duration -->
        <p class="gray mb-0">{{'workspace.office-cards.contract-duration' | translate }}</p>
        <p>{{office.duration}} {{'workspace.office-cards.months' | translate }}</p>
      </div>

      <div class="mb-2" *ngIf="office.payment">
        <div (click)="officeDetailCollapsed = !officeDetailCollapsed" [attr.aria-expanded]="!officeDetailCollapsed"
          aria-controls="collapseBasic">
          <!-- ? Amount Due -->
          <p class="mb-0 gray collapseInfo" [ngClass]="{'arrow-up': !officeDetailCollapsed}">{{'workspace.office-cards.ammount.ammount' | translate }}</p>
          <p class="mb-0">{{office.payment.totalNoFee | currency}} MXN {{'workspace.office-cards.iva-included' | translate }}</p>
        </div>

        <div id="collapseBasic" [collapse]="officeDetailCollapsed" [isAnimated]="true">
          <div class="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 ml-sm-5">
              <!--  ? Rent -->
              <p class="d-flex justify-content-between align-items-center mb-0"><span class="gray">{{'workspace.office-cards.ammount.rent' | translate }}</span>
                <span>{{office.payment.rent | currency}}  MXN</span></p>
                <!-- * Services -->
                <p class="d-flex justify-content-between align-items-center mb-0"><span class="gray">{{'workspace.office-cards.ammount.services' | translate }}</span>
                <span >{{office.payment.products | currency}}  MXN</span></p>

                <!-- * Rent difference -->
                <p *ngIf="office.payment.difference && office.payment.difference !== 0 " class="d-flex justify-content-between align-items-center mb-0"><span class="gray">{{'workspace.office-cards.ammount.rent-difference' | translate }}</span>
                <span>{{office.payment.difference | currency}}  MXN</span></p>
                <!-- * Extra Credits -->
                <p *ngIf="office.payment.charges && office.payment.charges !== 0 " class="d-flex justify-content-between align-items-center mb-0"><span class="gray">{{'workspace.office-cards.ammount.charges' | translate }}</span>
                <span>{{office.payment.charges | currency}}  MXN</span></p>
                <!-- * Subtotal -->
                <p class="d-flex justify-content-between align-items-center mt-4 mb-0"><span
                class="gray">Subtotal</span> <span>{{office.payment.subTotal | currency}}  MXN</span></p>
                <!-- * Tax nofee -->
                <p class="d-flex justify-content-between align-items-center mb-0"><span class="gray">IVA</span>
                <span>{{office.payment.taxNoFee | currency}}  MXN</span></p>
                <!-- * Total nofee -->
                <p class="d-flex justify-content-between align-items-center mb-0"><span class="gray">Total</span>
                <strong>{{office.payment.totalNoFee | currency}}  MXN</strong></p>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-between align-items-center mb-3">
        <div>
          <!--  ? Avaliable Credits -->
          <p class="gray mb-0">{{'workspace.office-cards.available-credits' | translate }}</p>
          <p class="mb-0">{{office.remainingCredits}}</p>
        </div>
        <div class="qr_icon" (click)="openModal(modalQR)"></div>
      </div>

      <button
        *ngIf="detail"
        type="button"
        [routerLink]="['/office/detail/' + office.id]"
        class="py-3 mb-2 btn-woke btn-gray w-100">{{'workspace.office-cards.my-office-button' | translate }}</button>

    </div>
  </div>
</div>

<ng-template #modalQR>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <qrcode [qrdata]="office.qr" [cssClass]="'woke_qr'"	 [width]="380" [errorCorrectionLevel]="'M'"></qrcode>
  </div>
</ng-template>
