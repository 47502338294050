<div class="woke_filters" [ngClass]="{'dark': darkMode$ | async }">
  <div class="accordeon" [ngClass]="closed ? 'closed' : ''" (click)="toggle()">
    <h5>{{label}}</h5>
    <span class="arrow_down_icon" [ngClass]="closed ? 'closed' : ''"></span>
  </div>

  <div appWokeHeight class="content">
    <ng-content></ng-content>
  </div>
</div>
