import { Component, OnInit, OnDestroy, TemplateRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-checkout-invites',
  templateUrl: './checkout-invites.component.html',
  styleUrls: ['./checkout-invites.component.scss']
})
export class CheckoutInvitesComponent implements OnInit, OnDestroy {

  invites = [];

  email: UntypedFormControl;

  modalRef: BsModalRef;

  @Output() onInvites = new EventEmitter<string[]>()

  constructor(
    private fb: UntypedFormBuilder,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.email = this.fb.control('', [Validators.email, Validators.required]);
  }

  ngOnDestroy(): void {
    this.closeModal();
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-dialog-centered' });
  }


  addInvite() {
    if (this.email.valid) {
      this.invites.push(this.email.value);
      this.onInvites.emit(this.invites);
      this.email.patchValue('');
      this.closeModal();
    }
  }

  removeInvite(invite: string) {
    this.invites = this.invites.filter(i => invite !== i);
    this.onInvites.emit(this.invites);
  }

  closeModal() {
    this.modalService.hide();
    document.querySelector('body').classList.remove('modal-open');
  }

}
