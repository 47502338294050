<app-woke-card>
    <ng-container *ngIf="payments.length; else emptyHistory">

        <app-spinner *ngIf="loading; else wokeBalance"></app-spinner>
        <ng-template #wokeBalance>
            <div class="woke_balance_container" *ngFor="let balance of payments">
                <div>
                    <div>{{balance.description}}<span *ngIf="balance.bookName && balance.bookName != ''"> - {{balance.bookName}}</span></div>
                    <div class="gray">{{balance.creationDate.date | date:'dd/MM/yyyy H:mm'}}</div>
                </div>
                <ng-container *ngIf="dynamicColors; then colored else black"></ng-container>
                <!-- If you want colors -->
                <ng-template #colored>

                    <div *ngIf="balance.total != '0.00'; then total; else license"></div>

                    <ng-template #total>
                        <div> 
                            {{balance.paymentMethod }} -
                            <span class="text">{{balance.total | currency}}</span> 
                            <span class="icon"> 
                                <div (click)="downloadFactura(balance.id)" class="icon-dowload">&nbsp;</div>
                            </span> 
                            <span class="icon"> 
                                <div (click)="downloadXML(balance.id)" class="icon-document">&nbsp;</div>
                            </span> 
                        </div>
                    </ng-template>
                    <ng-template #license>
                        <div> 
                            <span class="text">Licencia</span>
                            <span class="text"> - {{balance.subtractedCoins}} Créditos</span> 
                            <!--<span class="icon"> 
                                <div (click)="downloadFactura(balance.id)" class="icon-dowload">&nbsp;</div>
                            </span> 
                            <span class="icon"> 
                                <div (click)="downloadXML(balance.id)" class="icon-document">&nbsp;</div>
                            </span>-->
                        </div>

                    </ng-template>

                </ng-template>
                <!-- If you want black -->
                <ng-template #black>
                    <div>{{balance.total | currency}}</div>
                </ng-template>
            </div>
        </ng-template>

    </ng-container>
    <ng-template #emptyHistory>
        <div class="light small">No hay reservas</div>
    </ng-template>

    <ng-container *ngIf="shortable && payments.length; then viewAllTemplate"></ng-container>
    <!-- Shortable -->
    <ng-template #viewAllTemplate>
        <div class="woke_balance_button pink" [routerLink]="'/wallet/payment-history'">{{'wallet.boton-view' | translate }}
        </div>
    </ng-template>
    <ng-container *ngIf="showTotal; then totalTemplate"></ng-container>
    <ng-template #totalTemplate>
        <div class="woke_balance_container total">
            <div class="text">Total</div>
            <div>$4,550.00</div>
        </div>
    </ng-template>
</app-woke-card>