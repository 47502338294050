import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { WokeModule } from 'src/app/woke/woke.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxBootstrapModule } from 'src/app/externalModules/ngx-bootstrap.module';
import { HttpClientModule } from '@angular/common/http';
import { CheckoutWorkspaceComponent } from './checkout-workspace/checkout-workspace.component';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { SelectPaymentComponent } from './select-payment/select-payment.component';
import { AddPaymentComponent } from './add-payment/add-payment.component';
import { PayWithMoneyComponent } from './pay-with-money/pay-with-money.component';
import { WorkspaceDetailComponent } from './workspace-detail/workspace-detail.component';
import { CheckoutInvitesComponent } from './checkout-invites/checkout-invites.component';
import { StripeModule } from 'stripe-angular';

const routes: Routes = [
  { path: ':id', component: CheckoutWorkspaceComponent }
];

@NgModule({
    declarations: [CheckoutWorkspaceComponent, SelectPaymentComponent, AddPaymentComponent, PayWithMoneyComponent, WorkspaceDetailComponent, CheckoutInvitesComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        WokeModule,
        FormsModule,
        ReactiveFormsModule,
        NgxBootstrapModule,
        HttpClientModule,
        UiSwitchModule,
        StripeModule
    ],
    exports: [SelectPaymentComponent, AddPaymentComponent,]
})
export class CheckoutWorkspaceModule { }
