<!-- Modal Header -->
<div class="modal-header">
    <h5 *ngIf="!submittedForm;else checkYourMail" class="modal-title">{{'landing.login.recover' | translate }}</h5>
    <ng-template #checkYourMail>
        <h5 class="modal-title">{{'landing.login.check-email' | translate }}</h5>
    </ng-template>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<!-- Modal Body -->
<div class="modal-body">

    <!-- Recover Password Form -->
    <form *ngIf="!submittedForm" class="minimalist white" [formGroup]="forgotForm">

        <!-- * Email Field -->
        <div class="form-group">
            <input type="email" class="form-control" id="email" placeholder="Correo@mail.com" formControlName="email">
            <small *ngIf="forgotForm.controls.email.invalid && forgotForm.controls.email.dirty" class="text-alert">{{'landing.login.invalid-email' | translate }}</small>
        </div>

    </form>
    <!-- * Found -->
    <p *ngIf="!notFound" class="text-center">{{'landing.login.send-text' | translate }}</p>
    <!-- * Not found -->
    <p *ngIf="notFound" class="text-center">{{'landing.login.user-not-found' | translate }}</p>
</div>

<!-- Modal Footer -->
<div class="modal-footer">

    <!-- Submit Button -->
    <button *ngIf="!submittedForm;else dismissModal" class="red" (click)="recoverPass()" [disabled]="loading">
    <span *ngIf="!loading;else loader">{{'landing.login.send' | translate }}</span>
    <ng-template #loader>
      <div class="spinner-grow spinner-grow-sm" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </ng-template>
  </button>

    <!-- Dismiss Modal "Ok" -->
    <ng-template #dismissModal>
        <button class="btn btn-white" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide()">Ok</button>
    </ng-template>

</div>