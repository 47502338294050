<div class="gradient">
    <!-- ! NavBar -->
    <!-- * Baner -->
    <div class="banner about" id="banner" [ngClass]="{'nav': openSpan}">
        <div class="navbar">
            <a [routerLink]="'/'"><img class="logo" src="/assets/img/logos/Logo_Adstop.png" alt="Adstop"></a>

            <ul class="nav justify-content-end">
                <!--<li class="nav-item">
                    <a class="nav-link" (click)="switchLanguage()">{{ 'ajustes.boton-lenguaje' | translate }}</a>
                </li>-->
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="'/about'">{{'footer.tools' | translate }}</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" [routerLink]="'/register-space'">{{'footer.register-space' | translate }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link log-in" [routerLink]="'/login'">{{'footer.login' | translate }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link register" [routerLink]="'/register'">{{'footer.register' | translate }}</a>
                </li>
            </ul>

            <div (click)="openSpan = !openSpan" [ngClass]="{'open': openSpan}" id="nav-icon"><span></span> <span></span>
                <span></span>
            </div>
        </div>
        <nav class="navbar" id="mobilenav" [ngClass]="{'active': openSpan}">
            <ul>
                <!--<li class="nav-item">
                    <a class="nav-link" (click)="switchLanguage()">{{ 'ajustes.boton-lenguaje' | translate }}</a>
                </li>-->
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="'/about'">{{'footer.tools' | translate }}</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" [routerLink]="'/register-space'">{{'footer.register-space' | translate }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link log-in" [routerLink]="'/login'">{{'footer.login' | translate }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link register" [routerLink]="'/register'">{{'footer.register' | translate }}</a>
                </li>
            </ul>
        </nav>
        <!-- ! Main container with carrousel -->
        <div class="space-container" [ngClass]="{'nav': openSpan}">
            <h3>{{'landing.register-space.text-header' | translate }}</h3>
            <div class="swiper-container" id="spaces">
                <div class="swiper-wrapper">
                    <!-- * Coworkings -->
                    <div class="swiper-slide">
                        <div class="tab-img">
                            <img src="/assets/img/landing/espaciox.png" alt="">
                        </div>
                        <div class="space-text">
                            <h4>{{'landing.register-space.coworkings.title' | translate }}</h4>
                            <ul>
                                <li> <span>-</span>
                                    <p>{{'landing.register-space.coworkings.p-1' | translate }}</p>
                                </li>
                                <li> <span>-</span>
                                    <p>{{'landing.register-space.coworkings.p-2' | translate }}</p>
                                </li>
                                <li> <span>-</span>
                                    <p>{{'landing.register-space.coworkings.p-3' | translate }}</p>
                                </li>

                            </ul>
                            <div class="btns">
                                <button [routerLink]="'/space-register'" class=" btn-link">{{'landing.register-space.register' |
                  translate }}</button>
                                <button [routerLink]="'/contact-space'" class="white btn-link">{{'landing.register-space.more-info' |
                  translate }}</button>
                            </div>
                        </div>
                    </div>
                    <!-- * Hoteles  -->
                    <div class="swiper-slide">
                        <div class="tab-img">
                            <img src="/assets/img/landing/RegisterSpace_Hotels.png" alt="">
                        </div>
                        <div class="space-text">
                            <h4>{{'landing.register-space.hotels.title' | translate }}</h4>
                            <ul>
                                <li> <span>-</span>
                                    <p>{{'landing.register-space.hotels.p-1' | translate }}</p>
                                </li>
                                <li> <span>-</span>
                                    <p>{{'landing.register-space.hotels.p-2' | translate }}</p>
                                </li>
                                <li> <span>-</span>
                                    <p>{{'landing.register-space.hotels.p-3' | translate }}</p>
                                </li>

                            </ul>
                            <div class="btns">
                                <button [routerLink]="'/space-register'" class=" btn-link">{{'landing.register-space.register' |
                  translate }}</button>
                                <button [routerLink]="'/contact-space'" class="white btn-link">{{'landing.register-space.more-info' |
                  translate }}</button>
                            </div>
                        </div>
                    </div>
                    <!-- * Creative -->
                    <div class="swiper-slide">
                        <div class="tab-img">
                            <img src="/assets/img/landing/RegisterSpace_Dinamicos.png" alt="">
                        </div>
                        <div class="space-text">
                            <h4>{{'landing.register-space.dynamics.title' | translate }}</h4>
                            <ul>
                                <li> <span>-</span>
                                    <p>{{'landing.register-space.dynamics.p-1' | translate }}</p>
                                </li>
                                <li> <span>-</span>
                                    <p>{{'landing.register-space.dynamics.p-2' | translate }} <b>{{'landing.register-space.dynamics.types'
                      | translate }}</b></p>
                                </li>
                                <li> <span>-</span>
                                    <p>{{'landing.register-space.dynamics.p-3' | translate }}</p>
                                </li>
                            </ul>
                            <div class="btns">
                                <button [routerLink]="'/space-register'" class=" btn-link">{{'landing.register-space.register' |
                  translate }}</button>
                                <button [routerLink]="'/contact-space'" class="white btn-link">{{'landing.register-space.more-info' |
                  translate }}</button>
                            </div>
                        </div>
                    </div>
                    <!-- * Oficinas -->
                    <div class="swiper-slide">
                        <div class="tab-img">
                            <img src="/assets/img/landing/RegisterSpace_Office.png" alt="">
                        </div>
                        <div class="space-text">
                            <h4>{{'landing.register-space.offices.title' | translate }}</h4>
                            <ul>
                                <li> <span>-</span>
                                    <p>{{'landing.register-space.offices.p-1' | translate }}</p>
                                </li>
                                <li> <span>-</span>
                                    <p>{{'landing.register-space.offices.p-2' | translate }}</p>
                                </li>
                                <li> <span>-</span>
                                    <p>{{'landing.register-space.offices.p-3' | translate }}</p>
                                </li>
                                <li> <span>-</span>
                                    <p>{{'landing.register-space.offices.p-4' | translate }}</p>
                                </li>
                                <li> <span>-</span>
                                    <p>{{'landing.register-space.offices.p-5' | translate }}</p>
                                </li>
                            </ul>
                            <div class="btns">
                                <button [routerLink]="'/space-register'" class=" btn-link">{{'landing.register-space.register' |
                  translate }}</button>
                                <button [routerLink]="'/contact-space'" class="white btn-link">{{'landing.register-space.more-info' |
                  translate }}</button>
                            </div>
                        </div>
                    </div>

                </div>
                <!-- If we need navigation buttons -->
                <div class="car-btns">
                    <div class="swiper-button-prev"></div>
                    <div class="swiper-button-next"></div>
                </div>
            </div>
        </div>

        <!-- ! Register spaces and promo -->
        <div class="round-gradient mt-5">
            <h3>{{'landing.register-space.gradient.title' | translate }}</h3>
            <ul>
                <li><span>-</span>
                    <p>{{'landing.register-space.gradient.p-1' | translate }}</p>
                </li>
            </ul>

            <ul>

                <li><span>1.</span>
                    <p>{{'landing.register-space.gradient.p-2' | translate }}</p>
                </li>
                <li><span>2.</span>
                    <p>{{'landing.register-space.gradient.p-3' | translate }}</p>
                </li>
                <li><span>3.</span>
                    <p>{{'landing.register-space.gradient.p-4' | translate }}</p>
                </li>
                <li><span>4.</span>
                    <p>{{'landing.register-space.gradient.p-5' | translate }}</p>
                </li>
            </ul>
            <button [routerLink]="'/about'" class="white btn-link">{{'landing.register-space.gradient.button' | translate
        }}</button>
        </div>
        <!-- ! License Space calculator -->
        <div class="license-calc">
            <div class="license-calc-text">
                <h3>{{'landing.register-space.license.title' | translate }}</h3>
                <ul>
                    <li><span>-</span>
                        <p>{{'landing.register-space.license.p-1' | translate }}</p>
                    </li>
                    <li><span>-</span>
                        <p>{{'landing.register-space.license.p-2' | translate }}</p>
                    </li>
                    <li><span>-</span>
                        <p>{{'landing.register-space.license.p-3' | translate }}</p>
                    </li>
                    <li><span>-</span>
                        <p>{{'landing.register-space.license.p-4' | translate }}</p>
                    </li>
                    <li><span>-</span>
                        <p>{{'landing.register-space.license.p-5' | translate }}</p>
                    </li>

                </ul>
                <button [routerLink]="'/space-register'" class=" btn-link">{{'landing.register-space.register' | translate
          }}</button>
            </div>
            <div class="tab-img-spaces">
                <img src="/assets/img/landing/espacios.png" alt="">
            </div>
            <!--<div class="calculator">
                <div class="calculator-cont">
                    <h5 class="red">{{'landing.register-space.calculator.title' | translate }}</h5>
                    <p class="fw-600">{{'landing.register-space.calculator.buildings' | translate }}</p>
                    <form [formGroup]="calcuForm">
                        <input formControlName="estate" type="number" placeholder="{{'landing.register-space.calculator.number-buildings' | translate }}">
                        <p class="fw-600">{{'landing.register-space.calculator.capacity' | translate }}</p>
                        <input formControlName="capacity" type="number" placeholder="{{'landing.register-space.calculator.by-capacity' | translate }}">
                    </form>
                    <p class="gray fw-800">
                        <span>{{'landing.register-space.calculator.by-day' | translate }} </span>
                        <span>{{perHour | currency}} {{'landing.register-space.calculator.monthly' | translate }}</span>
                    </p>

                    <p class="gray fw-800">
                        <span class="calculator-Result">{{'landing.register-space.calculator.by-hour' | translate}} </span>
                        <span class="calculator-Result balck">{{perDay | currency}} {{'landing.register-space.calculator.monthly' |
              translate }}</span>
                    </p>
                </div>

            </div>-->
        </div>
    </div>


    <!-- ! Footer -->
    <app-footer></app-footer>
</div>
