<div class="d-flex flex-column p-3">
    <h6 class="mb-4">Select Payment Method</h6>
    <ng-container *ngIf="paymentMethods$ | async as payments; else loading">
        <div class="jumbo-mask p-2 mb-4 {{partialIndex === item.id ? 'active' : ''}}" *ngFor="let item of payments" (click)="partialSelect(item)">
            <!-- <div class="close_icon" (click)="deletePaymentMethod(item)"></div> -->
            <div class="visa_icon"></div>
            <div class="mb-4">
                <div class="gray">Card Number</div>
                <div>{{item.data?.card_number}}</div>
            </div>
            <div>
                <div class="gray">Expiration Date</div>
                <div>{{item.data?.expiration_month}}/{{item.data?.expiration_year}}</div>
            </div>
        </div>
        <div class="d-flex">
            <button class="btn btn-action btn-gray mr-3" type="button" (click)="addNew()">Add new</button>
            <button class="btn btn-action btn-red" type="button" (click)="selectPaymentMethod()">Select</button>
        </div>
    </ng-container>
    <ng-template #loading>
        <h6>Loading...</h6>
    </ng-template>
</div>
