import { Component, OnInit, Output, EventEmitter, Input, OnChanges, OnDestroy } from '@angular/core';
import { TokenFacade } from 'src/store/token/token.facade';
import { takeUntil, take } from 'rxjs/operators';
import { Subject } from 'rxjs';


export interface WeekDay {
  day: number;
  time: 'past' | 'present' | 'future';
  active?: boolean;
  today?: boolean;
  month?: string | number;
  year?: number;
  inactive?: boolean;
  partial?: boolean;
  dateTime?: number;
  taken?: boolean;
  wokeFormat?: string;
}

export class WeekDayClass implements WeekDay {
  day: number;
  time: 'past' | 'present' | 'future';
  active?: boolean;
  today?: boolean;
  month?: string | number;
  year?: number;
  inactive?: boolean;
  partial?: boolean;
  dateTime?: number;
  taken?: boolean;
  wokeFormat?: string;
  isoFormat?: string;

  constructor(payload: any) {
    this.day = payload.day || 0;
    this.time = payload.time || 'past';
    this.active = payload.active;
    this.today = payload.today;
    this.month = payload.month;
    this.year = payload.year;
    this.inactive = payload.inactive;
    this.partial = payload.partial;
    this.dateTime = payload.dateTime;
    this.taken = payload.taken;
    this.wokeFormat = this.parseSelection(payload);
    this.isoFormat = this.parseDateStrToISOStr(this.wokeFormat);
  }

  getStr(day: number) {
    return day > 9 ? `${day}` : `0${day}`
  }

  parseSelection(weekday: WeekDay) {
    return `${this.getStr(weekday.day)}/${this.getStr((weekday.month as number) + 1)}/${weekday.year}`;
  }

  parseDateStrToISOStr(dateStr: string) {
    const [dayStr, monthStr, yearStr] = dateStr.split('/');
    const iso8601 = `${yearStr}-${monthStr}-${dayStr}T06:00:00.000Z`;
    return iso8601;
  }

}

export type RangePicker = {
  first: WeekDayClass;
  last: WeekDayClass;
  days: number;
}

export class RangePickerClass {
  first: WeekDayClass;
  last: WeekDayClass;
  days: number;
  constructor(payload: RangePicker) {
    this.first = payload.first;
    this.last = payload.last;
    this.days = payload.days;
  }

  getRangeString() {
    if (this.first.wokeFormat === this.last.wokeFormat) {
      return this.first.wokeFormat;
    }
    return `${this.first.wokeFormat} - ${this.last.wokeFormat}`;
  }
}

@Component({
  selector: 'app-woke-datepicker',
  templateUrl: './woke-datepicker.component.html',
  styleUrls: ['./woke-datepicker.component.scss']
})
export class WokeDatepickerComponent implements OnInit, OnChanges, OnDestroy {

  @Input() rangeMode: boolean;
  @Input() selectedWeekDay: WeekDay;
  @Input() exclusions: string[] = [];
  @Input() type: string;

  @Output() selection = new EventEmitter<WeekDayClass>();
  @Output() rangeSelection = new EventEmitter<RangePickerClass>();
  @Output() nextMonth = new EventEmitter<string>();
  @Output() prevMonth = new EventEmitter<string>();
  @Output() gotoday = new EventEmitter<string>();

  today = new Date();
  currentYear = this.today.getFullYear();
  currentMonth = this.today.getMonth();


  weeks: Array<WeekDay[]> = this.createRows(this.currentMonth, this.currentYear);
  killall = new Subject();
  first: WeekDay;
  last: WeekDay;
  language:string

  constructor(private tokenFacade:TokenFacade) { }

  ngOnInit() {
    this.tokenFacade.language$.pipe(
      take(1),
      takeUntil(this.killall)
    ).subscribe({
      next: lan =>{
        this.language = lan
      }
    })
  }

  ngOnDestroy(){
    this.killall.next()
    this.killall.complete()
  }

  parseExclusions(exclusions: any[], currentYear: number) {
    return exclusions.map(
      exclusion => {
        const [year, month, day] = exclusion.split('-');
        return { year: +year, month: +month - 1, day: +day };
      }
    )
      // We can filter to avoid other years
      .filter(element => element.year === currentYear)
  }

  ngOnChanges() {
    this.weeks = this.createRows(this.currentMonth, this.currentYear);
  }

  getMonthsByLang(){
    if(this.language === 'es'){
      return ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
    }else{
      return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    }
  }

  goToday() {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    this.jump(month, year);
    this.gotoday.emit(`1/${month + 1}/${year}`);
  }

  getMonthName(index: number) {
    let months = this.getMonthsByLang();
    return months[index];
  }


  daysRange(startDate, endDate, steps = 1) {
    const dateArray = [];
    let currentDate = new Date(startDate);
  
    while (currentDate <= new Date(endDate)) {
      dateArray.push(new Date(currentDate).toISOString().slice(0,10));
      // Use UTC date to prevent problems with time zones and DST
      currentDate.setUTCDate(currentDate.getUTCDate() + steps);
    }
  
    return dateArray;
  }
  

  active(weekday: WeekDay) {

    let startDate = new Date(weekday.year, this.currentMonth, weekday.day);
    let endDate = new Date(weekday.year, this.currentMonth, weekday.day);
    let days = 0;

    if(this.type == "Quarterly"){
      endDate.setDate(startDate.getDate() + 14);
      days = 14;
    }else{
      endDate.setDate(startDate.getDate() + 30);
      days = 30;
    }

    const monthsEn = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    let monthEnd = monthsEn[endDate.getMonth()]

    console.log(monthEnd);

    let daysRange = this.daysRange(startDate, endDate, 1);

    let weekdayEnd = { ...weekday };
    weekdayEnd.day = endDate.getDate();
    weekdayEnd.month = monthEnd;

    let validRange = true;

    this.exclusions.forEach(exclusion => {
      daysRange.forEach(dayRange => {
        if(exclusion == dayRange){
          validRange = false;
        }
      });
    });

    if(validRange){

      const range = new RangePickerClass({
        first: new WeekDayClass({
          ...weekday,
          month: startDate.getMonth(),
        }), 
        last: new WeekDayClass({
          ...weekdayEnd,
          month: endDate.getMonth(),
        }), 
        days: 1
      })

      this.rangeSelection.emit(range);

    } else {
      alert('El rango de selección incluye días que ya están ocupados');
    }

    /*if (weekday.time !== 'future' && weekday.time !== 'past' && !weekday.inactive && !weekday.taken) {
      if (!this.rangeMode) {
        this.activeOne(weekday);
      } else {
        this.activeRange(weekday);
      }
    }*/

  }


  activeOne(weekday: WeekDay) {

    this.weeks.forEach(week => {
      week.forEach(weekday => {
        weekday.active = false;
      });
    });
    weekday.active = true;
    let months = this.getMonthsByLang();
    const month = months.indexOf(this.getMonthName(this.currentMonth))
    const payload = {
      ...weekday,
      month: month,
    };

    this.selection.emit(new WeekDayClass(payload));
  }

  activeRange(weekday: WeekDay) {
    if (!this.first) {
      this.first = weekday;
      weekday.active = true;
    } else {
      if (!this.last) {
        this.last = weekday;
        weekday.active = true;
      } else {
        this.weeks.forEach(week => {
          week.forEach(weekday => {
            weekday.active = false;
            weekday.partial = false;
          });
        });
        this.last = undefined;
        this.first = weekday;
        weekday.active = true;
      }
    }
    this.activeBetween();
  }

  activeBetween() {
    if (this.first && this.last) {
      const arraySorted = this.orderFirstAndLast(this.first, this.last);
      this.first = arraySorted[0];
      this.last = arraySorted[1];
      this.activePartialRange(this.weeks, this.first, this.last);
    }
  }

  orderFirstAndLast(first: WeekDay, last: WeekDay) {
    const array = [first, last];
    const arraySorted = array.sort((a, b) => {
      const bandA = a.dateTime;
      const bandB = b.dateTime;
      let comparison = 0;
      if (bandA > bandB) {
        comparison = 1;
      } else if (bandA < bandB) {
        comparison = -1;
      }
      return comparison;
    });
    return arraySorted;
  }

  activePartialRange(rows: Array<WeekDay[]>, first: WeekDay, last: WeekDay) {
    let validRange = true;
    let startRow = 0;
    let startCol = 0;
    let endRow = 0;
    let endCol = 0;

    rows.forEach((row, index) => {
      const a = row.findIndex(x => {
        return x.dateTime === first.dateTime
      });
      const b = row.findIndex(x => {
        return x.dateTime === last.dateTime
      });

      if (a >= 0) {
        startRow = index;
        startCol = a;
      };
      if (b >= 0) {
        endRow = index;
        endCol = b;
      };
    });
    let days = 0;
    for (let i = startRow; i <= endRow; i++) {
      if (i === endRow && endRow !== startRow) {
        for (let j = 0; j <= endCol; j++) {
          rows[i][j].partial = true;
          if (rows[i][j].taken) {
            validRange = false;
          }
          days++;
        }
      }
      if (i === startRow && endRow !== startRow) {
        for (let k = startCol; k < rows[i].length; k++) {
          rows[i][k].partial = true;
          if (rows[i][k].taken) {
            validRange = false;
          }
          days++;
        }
      }
      if (i < endRow && i > startRow && endRow !== startRow) {
        for (let l = 0; l < rows[i].length; l++) {
          rows[i][l].partial = true;
          if (rows[i][l].taken) {
            validRange = false;
          }
          days++;
        }
      }
      if (endRow === startRow) {
        for (let m = startCol; m <= endCol; m++) {
          rows[i][m].partial = true;
          if (rows[i][m].taken) {
            validRange = false;
          }
          days++;
        }
      }

    }


    const monthsEs = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
    const monthsEn = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    const firstMonth = monthsEs.indexOf(first.month as string) !== -1 ? monthsEs.indexOf(first.month as string) : monthsEn.indexOf(first.month as string) ;
    const lastMonth = monthsEs.indexOf(last.month as string) !== -1 ? monthsEs.indexOf(last.month as string) : monthsEn.indexOf(last.month as string);



    if (validRange) {

      const range = new RangePickerClass({
        first: new WeekDayClass({
          ...first,
          month: firstMonth,
        }), 
        last: new WeekDayClass({
          ...last,
          month: lastMonth,
        }), days
      })

      this.rangeSelection.emit(range);
    } else {
      alert('El rango de selección incluye días que ya están ocupados');
    }

  }


  // Algoritmo Generador

  daysInMonth(iMonth: number, iYear: number): number {
    return 32 - new Date(iYear, iMonth, 32).getDate();
  }

  getPastMonth(months: string[], month: number, year: number): { month: string, year: number } {
    if (month === 0) {
      return { month: months[months.length - 1], year: year - 1 };
    }
    return { month: months[month - 1], year };
  }

  getNextMonth(months: string[], month: number, year: number): { month: string, year: number } {
    if (month === months.length - 1) {
      return { month: months[0], year: year + 1 };
    }
    return { month: months[month + 1], year };
  }

  getPastDay(day: number, months: string[], month: number, year: number): WeekDay {
    const date = new Date(year, month, day);
    const dateTime = date.getTime();
    return { time: 'past', day, ...this.getPastMonth(months, month, year), dateTime }
  }

  getFutureDay(day: number, months: string[], month: number, year: number): WeekDay {
    const date = new Date(year, month, day);
    const dateTime = date.getTime();
    return { time: 'future', day, ...this.getNextMonth(months, month, year), dateTime }
  }

  getDay(day: number, months: string[], month: number, year: number): WeekDay {
    const date = new Date(year, month, day);
    const dateTime = date.getTime();
    let weekDay: WeekDay = { time: 'present', day, month: months[month], year, dateTime };
    const today = new Date();
    if (day === today.getDate() && year === today.getFullYear() && month === today.getMonth()) {
      weekDay.today = true;
    }
    return weekDay;
  }

  getWeekDay(i: number, j: number, day: number, month: number, year: number): WeekDay {
    let months =  this.getMonthsByLang();
    let firstDay = (new Date(year, month)).getDay();
    if (i === 0 && j < firstDay) {
      return this.getPastDay(day, months, month, year);
    } else if (day > this.daysInMonth(month, year)) {
      return this.getFutureDay(day, months, month, year);
    } else {
      return this.getDay(day, months, month, year);
    }
  }

  createRows(month: number, year: number) {
    let rows = [];
    let day = 1;
    for (let i = 0; i < 6; i++) {
      let row: WeekDay[] = [];
      for (let j = 0; j < 7; j++) {
        const weekDay = this.getWeekDay(i, j, day, month, year);
        row = [...row, weekDay];
        if (weekDay.time === 'present') {
          day++;
        }
      }
      rows = [...rows, row];
    }
    this.parseFuture(rows);
    this.parsePast(rows);
    this.parsePresent(rows);
    const taken = this.parseExclusions(this.exclusions, year);
    this.parseTaken(rows, taken);
    this.findActive(rows);
    return rows;
  }

  next() {
    this.currentYear = (this.currentMonth === 11) ? this.currentYear + 1 : this.currentYear;
    this.currentMonth = (this.currentMonth + 1) % 12;
    this.weeks = this.createRows(this.currentMonth, this.currentYear);
    this.nextMonth.emit(`1/${this.currentMonth+1}/${this.currentYear}`);
  }

  previous() {
    this.currentYear = (this.currentMonth === 0) ? this.currentYear - 1 : this.currentYear;
    this.currentMonth = (this.currentMonth === 0) ? 11 : this.currentMonth - 1;
    this.weeks = this.createRows(this.currentMonth, this.currentYear);
    this.prevMonth.emit(`1/${this.currentMonth+1}/${this.currentYear}`);
  }

  jump(currentMonth: number, currentYear: number) {
    this.currentMonth = currentMonth;
    this.currentYear = currentYear;
    this.weeks = this.createRows(this.currentMonth, this.currentYear);
  }

  parsePast(rows: Array<WeekDay[]>) {
    const pastMonths = rows[0].filter(s => s.time === 'past')
    if (pastMonths.length) {
      const pastMonth = pastMonths[0]
      let months = this.getMonthsByLang();
      const month = months.indexOf(pastMonth.month as string)
      const year = pastMonth.year
      const q = pastMonths.length - 1
      let day = this.daysInMonth(month, year) - q;
      for (let i = 0; i < rows.length; i++) {
        for (let j = 0; j < rows[i].length; j++) {
          if (rows[i][j].time === 'past') {
            rows[i][j].day = day;
            day++;
          }
        }
      }
    }
  }

  parseTaken(rows: Array<WeekDay[]>, takens: Array<{ year: number, month: number, day: number }>) {
    let months = this.getMonthsByLang();

    for (let i = 0; i < rows.length; i++) {
      for (let j = 0; j < rows[i].length; j++) {
        const month = months.indexOf(rows[i][j].month as string)
        const day = rows[i][j].day
        const taken = takens.find(element => element.day === day && element.month === month);
        if (taken) {
          rows[i][j].taken = true;
        }
      }
    }
  }

  parseFuture(rows: Array<WeekDay[]>) {
    let day = 1;
    for (let i = 0; i < rows.length; i++) {
      for (let j = 0; j < rows[i].length; j++) {
        if (rows[i][j].time === 'future') {
          rows[i][j].day = day;
          day++;
        }
      }
    }
  }

  parsePresent(rows: Array<WeekDay[]>) {
    const today = new Date();
    const day = today.getDate();
    const todayMonth = today.getMonth();
    const year = today.getFullYear();
    let months = this.getMonthsByLang();

    for (let i = 0; i < rows.length; i++) {
      for (let j = 0; j < rows[i].length; j++) {
        if (rows[i][j].year < year) {
          rows[i][j].inactive = true;
        }
        if (rows[i][j].year === year) {
          const month = months.indexOf(rows[i][j].month as string);
          if (month < todayMonth) {
            rows[i][j].inactive = true;
          }
          if (month === todayMonth) {
            if (rows[i][j].day < day) {
              rows[i][j].inactive = true;
            }
          }
        }
      }
    }
  }

  findActive(rows: Array<WeekDay[]>) {
    if (this.selectedWeekDay) {
      for (let i = 0; i < rows.length; i++) {
        for (let j = 0; j < rows[i].length; j++) {
          const weekday = rows[i][j];
          let months = this.getMonthsByLang();
          if (
            months[this.selectedWeekDay.month] === weekday.month &&
            this.selectedWeekDay.day === weekday.day &&
            this.selectedWeekDay.year === weekday.year) {
            weekday.active = true;
            this.first = weekday;
          }
        }
      }
    }
  }

}
