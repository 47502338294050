
<div class="swiper-container">
  <!-- Additional required wrapper -->
  <div class="swiper-wrapper">
    <ng-container *ngFor="let slide of slides">
    </ng-container>
  </div>
  <!-- If we need pagination -->
  <div class="swiper-pagination"></div>
</div>
