
<app-spinner class="mt-5" *ngIf="loading; else eventTemplate"></app-spinner>
<ng-template #eventTemplate>
  <div class="header_wrapper">
  <button [routerLink]="'/'" class="logo_red"></button>
  </div>
  <div class="woke container">
    <meta property="og:url"                content="https://woke.mx/event/event.id"/>
    <meta property="og:type"               content="article" />
    <meta property="og:title"              [content]="event.name" />
    <meta property="og:description"        [content]="event.description" />
    <meta property="og:image"              content="loggedService.getUrl(event.profilePicture)" />

    <!-- <meta name="og:title" content="event.name">
    <meta name="og:description" content="event.description">
    <meta name="og:image" content="loggedService.getUrl(event.profilePicture)">
    <meta name="og:url" content="https://woke.mx/event/event.id">
    <meta name="fb:app_id" content="1118016105237895">
    <meta name="og:type" content="website"> -->


  <h4 class="mb-4">{{event.name}}</h4>

  <div class="container-flex d-flex fit-photo">
    <img [src]="loggedService.getUrl(event.profilePicture)" alt="">
  </div>

  <div class="mb-4">
    {{event.description}}
  </div>

  <div class="mb-3">
    <div class="semi-bold mb-2">Host</div>
    <div class="jumbo-mask">
      {{event.coworkingChild?.name}}
    </div>
  </div>

  <div class="mb-3">
    <div class="semi-bold mb-2">Category</div>
    <div class="jumbo-mask">
      {{event.category}}
    </div>
  </div>

  <div class="mb-3">
    <div class="semi-bold mb-2">Location</div>
    <div *ngIf="event.location; else digitalTemp" class="jumbo-mask">
      {{event.location}}
    </div>
    <ng-template #digitalTemp>
      <div class="jumbo-mask">
        Digital
      </div>
    </ng-template>
  </div>

  <div class="mb-3">
    <div class="semi-bold mb-2">Date</div>
    <div class="jumbo-mask">
      {{event.date}}
    </div>
  </div>

  <div class="mb-3">
    <div class="semi-bold mb-2">Time</div>
    <div class="jumbo-mask">
      {{event.time}}
    </div>
  </div>

  <div class="mb-5">
    <div class="semi-bold mb-2">Price</div>
    <div class="jumbo-mask">
      {{event.price | currency}}
    </div>
  </div>

  <div *ngIf="!event.limitedVacancy">
    <button class="btn btn-red btn-action" type="button" [routerLink]="'/network/add-tickets/' + event.id">
      Book
    </button>
  </div>
  <button (click)="shareOnFB()" class="btn btn-action btn-gray mt-4 ">
 Share on Facebook
</button>
</div>


</ng-template>
