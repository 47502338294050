<div class="background-gradient">
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <div class="col-10 col-md-7 col-lg-5">
                <h5 class="white text-center">Recover your Password</h5>
                <!-- Login Form -->
                <form class="minimalist transparent" [formGroup]="forgotForm">

                    <!-- Email Field -->
                    <div class="form-group">
                        <input type="email" class="form-control" id="email" placeholder="Correo@gmail.com" formControlName="email">
                        <small *ngIf="forgotForm.controls.email.invalid && forgotForm.controls.email.touched" class="text-alert">invalid email</small>
                    </div>

                    <!-- Submit Button -->
                    <button class="btn btn-woke btn-white mt-5" (click)="recoverPass(modalSuccess)" [disabled]="loading">
            <span *ngIf="!loading;else loader">Send</span>
            <ng-template #loader>
              <div class="spinner-grow spinner-grow-sm" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </ng-template>
          </button>
                </form>


            </div>
        </div>

    </div>
</div>

<ng-template #modalSuccess>
    <div class="modal-header">
        <h5 *ngIf="!notFound" class="modal-title">Check your email!</h5>
        <h5 *ngIf="notFound" class="modal-title">Ups!</h5>
    </div>
    <div class="modal-body">
        <p *ngIf="!notFound" class="text-center">We will be sending you an email shortly with instructions on how to recover your password.</p>
        <p *ngIf="notFound" class="text-center">User not found. Verify your email.</p>
    </div>
    <div class="modal-footer">
        <button (click)="closeModal()" type="button" class="btn btn-link red" data-dismiss="modal">Ok</button>
    </div>
</ng-template>