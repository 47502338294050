<app-woke-card *ngIf="workspace.active">

    <div class="logo_container" [routerLink]="'/workspace/child/' + workspace.coworkingChild.id">
        <img [src]="loggedService.getUrl(workspace.coworkingChild.profilePicture)" alt="">
    </div>

    <div class="img_container" [routerLink]="'/workspace/space/' + workspace.id">
        <img [src]="loggedService.getUrl(workspace.profilePicture)" alt="">
    </div>

    <div class="description_container">
        <div class="text_container contaner-between mb-2">
            <div class="title-data">
                <div class="text mb-1">
                    <span class="text-bold">{{workspace.name}} - </span><span>{{workspace.coworkingChild.name}}</span>
                </div>
                <ng-container *ngIf="(language$ | async) as lang">
                    <div class="text light">{{lang === 'en' ? workspace.type : workspace.type_es}}</div>
                </ng-container>
            </div>

        </div>

        <div class="text d-flex mb-2">
            <div class="justify-content-center star_icons">
                <div class="star_icon {{star.state}}" *ngFor="let star of stars; let i = index"></div>
            </div>
        </div>


        <div class="text">
            <span class="text-bold"> <!--{{'workspace.space.capacity' | translate }}: -->{{workspace.capacity}} </span>
        </div>

        <ng-container *ngIf="(language$ | async) as lang">
            <div class="text mb-1">{{workspace.externalPrice | currency}}/{{lang === 'en' ? workspace.minimumBookingTime : workspace.minimumBookingTime_es}} </div>
        </ng-container>


        <div class="text_container mb-2">
            <div class="text">
                <span class="light">{{workspace.short_location}}</span>
            </div>
        </div>

    </div>


    <div *ngIf="cardForBooking">
        <div class="description_container" *ngIf="!upcomingBooking && !pastBooking">
            <!-- ? Book -->
            <div class="booking_button" (click)="book(workspace)" [ngClass]="{'dark': darkMode$ | async }">{{'workspace.space.book' | translate }}</div>
        </div>
    </div>

</app-woke-card>
