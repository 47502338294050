<app-woke-card>
  <div class="wrapper" [routerLink]="link">
    <div class="item_thumbnail">
      <img [src]="image" alt="">
    </div>
    <div class="name">
      <ng-content></ng-content>
    </div>
  </div>
</app-woke-card>
