<!--  Invites -->
<ng-container *ngIf="invites.length">
  <div class="d-flex">
    <!-- * Guests -->
    <p class="fw-600">{{'checkout.guests' | translate }}</p>
  </div>
  <!-- ng-for -->
  <div class="jumbo-mask d-flex justify-content-between align-items-center mb-4" *ngFor="let invite of invites">
    {{invite}}
    <button type="button" class="close pull-right" aria-label="Close" (click)="removeInvite(invite)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</ng-container>

<!-- ? Add guests -->
<button *ngIf="invites.length < capacity" class="btn btn-woke-action gray jumbo-mask mb-4" (click)="openModal(addGuest)">{{'checkout.add-guests' | translate }}</button>


<ng-template #addGuest>
  <div class="modal-body px-3">
    <!-- ? Add Guest -->
    <p class="black fw-600 mb-4 text-left">{{'checkout.add-guest' | translate }}</p>
    <form>
      <div class="form-group">
        <input [formControl]="email" class="jumbo-mask" type="text" placeholder="Email">
      </div>
      <!-- <span class="red" *ngIf="email.invalid && email.dirty">Please verify the email</span> -->
      <button [disabled]="!email.valid" (click)="addInvite()" type="button" class="btn btn-woke-action red mt-4">{{'checkout.add-button' | translate }}</button>
    </form>
  </div>
</ng-template>
