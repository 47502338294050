import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-woke-file',
  templateUrl: './woke-file.component.html',
  styleUrls: ['./woke-file.component.scss']
})
export class WokeFileComponent implements OnInit {

  @Input() placeholder;
  @Input() cleanMode = false;

  @Output() filechange = new EventEmitter<any>();
  @Output() fileclear = new EventEmitter<any>();

  @ViewChild('file', { static: true }) fileTemplate: ElementRef;

  customSrc: string;
  file: File;

  result: any;

  constructor() { }

  ngOnInit() {
  }

  fileChange(files: File[]) {
    if (files.length) {
      this.file = files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.result = reader.result;
        this.customSrc = this.file.name;
        this.filechange.emit(this.file);
      };
      reader.readAsDataURL(files[0]);
    }
  }

  downloadFile() {
    const linkSource = this.result;
    const downloadLink = document.createElement('a');
    const fileName = this.customSrc;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  cleanFile() {
    this.fileTemplate.nativeElement.value = null;
    this.customSrc = undefined;
    this.result = undefined;
    this.file = undefined;
    this.fileclear.emit(true);
  }

}
