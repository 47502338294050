<app-woke-card>
    <div class="event_title">{{event.name}}</div>
    <div class="img_container" [routerLink]="'/network/event-detail/' + event.id">
        <img [src]="loggedService.getUrl(event.profilePicture)" alt="">
    </div>
    <div class="description_container">
        <div>
            <div class="text_container">
                <div class="text">
                    <span class="light" [innerHTML]=event.description>
          </span>
                </div>
            </div>
        </div>
    </div>
    <div class="description_container">
        <div>
            <div class="text_container" *ngIf="event.coworkingChild">
                <div class="text">
                    <span class="bold">{{event.coworkingChild.name}}</span>
                </div>
                <div class="text">{{event.category}}</div>
            </div>
        </div>
        <div class="text">
            <span class="bold pink" *ngIf="event.limitedVacancy">{{'network.upcoming-events.vacancy' | translate }}</span>
        </div>
    </div>
    <div>
        <div class="text_container">
            <div class="text descriptive">
                <span class="light">{{event.location}}</span>
            </div>
            <div class="text descriptive">
                <span class="light">{{event.date}} - {{event.time}} hrs.</span>
            </div>
            <div class="text descriptive">
                <span class="light">{{ event.price | currency }}</span>
            </div>
            <div class="text descriptive">
                <span class="light">{{ event.remaining }} {{'network.upcoming-events.remaining' | translate }}</span>
            </div>
        </div>

    </div>
    <div class="description_container">
        <div class="button_event" [ngClass]="{'dark': darkMode$ | async }" [routerLink]="'/network/event-detail/' + event.id">{{'network.upcoming-events.view-more' | translate }}</div>
    </div>
</app-woke-card>
