import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { auditTime, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Workspace, WorkspacePins, coresLanding } from 'src/app/api.interfaces';
import { ApiService } from 'src/app/api.service';
import { LoggedService } from 'src/app/logged/logged.service';
import { environment } from 'src/environments/environment';
import { TokenFacade } from 'src/store/token/token.facade';
import { ClusterIconStyle } from '@google/markerclustererplus';
import Swiper from 'swiper';
import { areasQuick, mapsResult } from 'src/app/logged/workspaces/workspace/quick/quick.model';
declare var $: any;

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit, AfterViewInit, OnDestroy {
  swiperNew: any;
  swiperBest: any;
  swiperResults: any;
  searchControl: UntypedFormControl;
  killall = new Subject()
  workspaces: Workspace[] = [];
  loading = false;
  loadingBest = true;
  loadingFilterSpaces = true;
  loaded = false;
  mapForm: UntypedFormGroup;
  resourceUrl = environment.imageUrl
  areas
  types
  openSpan = false
  language: string;
  recentlyAdded: Workspace[]
  bestRated: Workspace[]

  // ? Variables For Map
  searchedMap = false;
  fitBounds = false;
  selectedLocation = true;
  workspacePins: WorkspacePins[] = [];
  filteredAreas: any[]
  results = 0;
  geometry: mapsResult;
  areasMap: areasQuick
  lat: number;
  lng: number;
  zoom: number = 12;
  counterHide = 0;
  previous: number = null

  elmnt: HTMLElement
  textHeader: string
  spacesEn = ['Meeting Rooms', 'Photo Studios', 'Desks', 'Rooftops', 'Offices', 'Confernece Rooms', 'Recording Studios', 'Workation Spaces', 'Therapy Rooms']
  spacesEs = ['Salas de Juntas', 'Estudios de Foto', 'Escritorios', 'Terrazas', 'Oficinas', 'Salas de Conferencia', 'Estudios de Grabación', 'Espacios Workation', 'Salas de Terapia']

  //* Map Style
  mapStyles: Array<any> = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dadada"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#c9c9c9"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    }
  ];

  pinClusterStyle: ClusterIconStyle = {
    url: '/assets/img/markers/no-active.png',
    textColor: '#fff',
    textSize: 18,
    textLineHeight: 100,
    width: 43,
    height: 43,
    backgroundPosition: 'center'
  }

  //* Coworkings
  coworkings: coresLanding[]




  constructor(private fb: UntypedFormBuilder,
    private apiService: ApiService,
    public loggedService: LoggedService,
    private tokenFacade: TokenFacade,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.tokenFacade.language$.pipe(
      takeUntil(this.killall)
    ).subscribe({
      next: lan => {
        this.language = lan
        this.getRandomSpaces()
      }
    })
    this.getMyCurrentPosition()
    this.initFormGroup();
    this.initControl();
    this.getWorkspaces();
    this.getProfilePictures();
    this.getAreasMap()
    this.findLocation()
    this.getTypes();
  }

  ngAfterViewInit() {
    this.initSwiperNew();
    this.initSwiperBest();
    this.initSwiperResults();
  }

  ngOnDestroy() {
    this.killall.next();
    this.killall.complete();
    this.swiperBest.destroy();
    this.swiperNew.destroy();
    this.swiperResults.destroy();
  }

  getRandomSpaces() {
    let arr = this.language == 'en' ? this.spacesEn : this.spacesEs
    let n = 1
    var result = new Array(n),
      len = arr.length,
      taken = new Array(len);
    if (n > len)
      throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
      var x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    // ? this.textHeader = `Book ${result} where you need it, when you need it`
    // : this.textHeader = `Reserva ${result} donde lo necesites, cuando lo necesites.`
    this.language === 'en'
      ? this.textHeader = `Book ${result} where you need it, when you need it`
      : this.textHeader = `En Adstop puedes encontrar el espacio ideal para tu campaña publicitaria.`
  }

  initSwiperNew() {
    this.swiperNew = new Swiper(`#new`, {

      autoHeight: true,
      observer: true,
      observeParents: true,
      slidesPerView: 3,
      slidesPerGroup: 2,
      simulateTouch: true,
      breakpoints: {
        640: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          simulateTouch: true
        },
        768: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          simulateTouch: true
        },
        1024: {
          centerInsufficientSlides: true,
          slidesPerView: 5,
          slidesPerGroup: 2,
          simulateTouch: true
        }
      },
      navigation: {
        nextEl: '.swiper-button-next.new',
        prevEl: '.swiper-button-prev.new',
      }

    });

    this.swiperNew.on('observerUpdate', function () {
      setTimeout(() => {
        this.update()
      }, 300);
    })
  }

  initSwiperBest() {
    this.swiperBest = new Swiper(`#best`, {
      observer: true,
      initialSlide: 0,
      observeParents: true,
      simulateTouch: true,
      watchOverflow: true,
      breakpoints: {
        320: {
          slidesPerView: 2,
          slidesPerGroup: 4,
          slidesPerColumn: 2,
          spaceBetween: 10
        },
        640: {
          slidesPerView: 4,
          slidesPerGroup: 2,
        }
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }

    });

    this.swiperBest.on('observerUpdate', function () {
      setTimeout(() => {
        this.update()
      }, 300);
    })
  }

  initSwiperResults() {

    this.swiperResults = new Swiper(`#results`, {
      observer: true,
      observeParents: true,
      watchOverflow: true,
      simulateTouch: true,

      breakpoints: {
        320: {
          slidesPerView: 2,
          slidesPerGroup: 2,
          slidesPerColumn: 2,
          slidesPerColumnFill: 'row',
          initialSlide: 0,
          setWrapperSize: true,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 4,
          slidesPerGroup: 4,
          slidesPerColumn: 2,
          slidesPerColumnFill: 'row',
          initialSlide: 0,
          setWrapperSize: true,
        }
      },
      navigation: {
        nextEl: '.swiper-button-next.result',
        prevEl: '.swiper-button-prev.result',
      }

    });

    this.swiperResults.on('observerUpdate', function () {
      setTimeout(() => {
        this.update()
      }, 800);
    })
  }

  getProfilePictures() {
    this.apiService.getCoresProfilePicture().pipe(
      take(1),
      takeUntil(this.killall)
    ).subscribe({
      next: coworkings => {
        //console.log(coworkings);

        this.coworkings = coworkings.map(cow => {
          return {
            ...cow,
            profilePicture: `${environment.imageUrl}${cow.profilePicture}`
          }
        })

      }
    })
  }

  getWorkspaces() {
    combineLatest([
      this.apiService.getRecentlyAdded(),
      this.apiService.getBestRated()
    ]).pipe(
      take(1),
      takeUntil(this.killall)
    ).subscribe({
      next: ([recent, rated]) => {
        this.recentlyAdded = recent.slice(0, 8);
        // console.log("recents", this.recentlyAdded);
        this.bestRated = rated.slice(0, 4);
        this.loadingFilterSpaces = false;
        this.loadingBest = false;

      },
      error: err => {
        this.loadingFilterSpaces = false;
        console.warn(err);
        this.loadingBest = false;
      }
    })
  }


  initControl() {

    this.mapForm.get('type').valueChanges.pipe(
      tap(() => {
        this.workspaces = [];
        this.workspacePins = [];
        this.searchedMap = false;
        this.loading = true;
        this.results = 0;
      }),
      takeUntil(this.killall)
    ).subscribe({
      next: () => {
        this.search()
      }
    })

  }

  search() {

    if (this.mapForm.get('type').value && this.mapForm.get('location').value) {
      this.searchMap()
    } else {
      this.loading = false;
    }
  }

  searchMap() {
    this.loading = true
    // * Para encontrar el timeType del tipo de espacio seleccionado
    const timetype = this.types.filter(type => this.mapForm.get('type').value === type.type)
    // * Para generar el QuickModel
    const quickType = {
      location: this.mapForm.get('location').value,
      type: this.mapForm.get('type').value,
      time: timetype[0].time,
    };


    if (this.mapForm.get('location').value === 'See Locations Near Me' || this.mapForm.get('location').value === "Ver espacios cerca de mí") {
      this.getMyCurrentPosition()
      this.searchNearest(quickType)
    } else {
      this.searchByAddress(quickType)
    }

  }

  // * Busca mandando la dirección o address al endpoint
  searchByAddress(quick) {
    this.elmnt.scrollIntoView({ behavior: 'smooth' });
    combineLatest([
      this.apiService.getBoundaries(quick.location),
      this.apiService.getSpacesbyLocation(quick)
    ]).pipe(
      take(1),
      takeUntil(this.killall)
    ).subscribe({
      next: ([boundaries, results]) => {
        // console.log("boundaries", boundaries)
        // console.log("Todos los points", results)
        let newsSpaces = [];
        results.map(getSpaces => {
          getSpaces.workspaces.forEach(spaces => {
            newsSpaces.push({
              active: "Active",
              "distance": spaces.distance,
              "distance_value": spaces.distance_value,
              "duration": spaces.duration,
              "duration_value": spaces.duration_value,
              "id": spaces.id,
              "lat": spaces.lat,
              "lng": spaces.lng,
              "location": spaces.location,
              "name": spaces.name,
              "profilePicture": spaces.profilePicture,
              "rating": spaces.rating,
              "workspaces": [spaces]
            });
          });
        })

        console.log('newsSpaces ', newsSpaces)

        this.loading = false
        this.loaded = true;
        this.geometry = boundaries[0];
        this.lat = this.geometry.geometry.location.lat
        this.lng = this.geometry.geometry.location.lng
        results.forEach(element => {
          this.results += element.workspaces.length
        });
        this.workspacePins = newsSpaces.filter(pin => pin.active === 'Active')
        this.workspacePins = this.parsePins(this.workspacePins)
        this.searchedMap = true;

        console.log('results', results);
        console.log('workspacePinsAdress', this.workspacePins);
        console.log('searchedMap', this.searchedMap);
        console.log('zoom', this.zoom);
      },
      error: (error) => {
        this.loading = false
        this.loaded = true;
        this.searchedMap = true;
        console.warn(error);
        return this.loggedService.showError();
      }
    });


  }

  // * Busca mandando las coordenadas de mi posición al endpoint
  searchNearest(quick) {
    console.log('searchNearest');
    console.log('lat', this.lat)
    console.log('lng', this.lng)
    this.elmnt.scrollIntoView({ behavior: 'smooth' });

    this.apiService.getSpacesbyLocation(quick, `${this.lat}`, `${this.lng}`).pipe(
      take(1),
      takeUntil(this.killall)
    ).subscribe({
      next: results => {
        let newsSpaces = [];
        results.map(getSpaces => {
          getSpaces.workspaces.forEach(spaces => {
            newsSpaces.push({
              active: "Active",
              "distance": spaces.distance,
              "distance_value": spaces.distance_value,
              "duration": spaces.duration,
              "duration_value": spaces.duration_value,
              "id": spaces.id,
              "lat": spaces.lat,
              "lng": spaces.lng,
              "location": spaces.location,
              "name": spaces.name,
              "profilePicture": spaces.profilePicture,
              "rating": spaces.rating,
              "workspaces": [spaces]
            });
          });
        })

        this.loading = false
        this.loaded = true;

        results.forEach(element => {
          this.results += element.workspaces.length
        });
        this.workspacePins = newsSpaces.filter(pin => pin.active === 'Active')
        this.workspacePins = this.parsePins(this.workspacePins)
        this.searchedMap = true;

        if (this.workspacePins) {
          const distance = this.workspacePins[0].distance_value
          distance < 2000 ? this.zoom = 15 :
            distance > 2000 && distance < 10000 ? this.zoom = 11 :
              distance > 10000 && distance < 50000 ? this.zoom = 11 : this.fitBounds = true
        }

      },
      error: (error) => {
        this.loading = false
        this.loaded = true;
        this.searchedMap = true;
        console.warn(error);
        return this.loggedService.showError();
      }
    });
  }

  //* Parseamos los pines para agregar isClicked
  parsePins(pins: WorkspacePins[]) {
    let id = 0
    const parsedPins = pins.map(pin => {
      return {
        ...pin,
        isClicked: false,
        pinId: id++
      }
    })
    return parsedPins
  }

  emptyArray(location) {
    if (location === "") {
      this.selectedLocation = true; // Se esconde el cuadro de opciones si location está vacío
      this.filteredAreas = []
    }
  }

  initFormGroup() {
    let nearme = this.language === 'en' ? "See Locations Near Me" : "Ver espacios cerca de mí";
    this.mapForm = this.fb.group({
      location: [nearme, Validators.required],
      type: [null, Validators.required]
    });
  }



  getTypes() {
    this.apiService.getTypesNoAuth().pipe(
      take(1),
      takeUntil(this.killall)
    ).subscribe({
      next: types => {
        // let typesHour = this.parseTypes(types.filter(type => type !== null && type.time === 'Hour'))
        // let typesDay = this.parseTypes(types.filter(type => type !== null && type.time === 'Day'))

        let typesWeekly = this.parseTypes(types.filter(type => type !== null && type.time === 'Weekly'))
        let typesQuarterly = this.parseTypes(types.filter(type => type !== null && type.time === 'Quarterly'))
        let typesMonthly = this.parseTypes(types.filter(type => type !== null && type.time === 'Monthly'))

        let allTypes = this.parseTypes(types)

        this.parseTypesByLang(typesWeekly, typesQuarterly, typesMonthly, allTypes)

        //console.log(this.types);

      },
      error: err => {
        console.warn(err);
      }
    })
  }

  //* Parseamos las areas para agegar el tipo y controlar estilos
  parseTypes(types: any[]) {
    const filteredTypes = types.map(type => {
      return {
        type: type.type,
        type_es: type.type_es,
        time: type.time,
        isTitle: false
      }
    })
    return filteredTypes
  }

  parseTypesByLang(typesW, typesQ, typesM, allTypes) {

    this.types = allTypes;

    /*this.types = [
      {
        type: 'Quarterly',
        type_es: 'Catorcena',
        time: '',
        isTitle: true
      },
      ...typesQ,
      {
        type: 'Monthly',
        type_es: 'Mensual',
        time: '',
        isTitle: true
      },
      ...typesM
    ]*/

  }

  switchLanguage() {
    let lang = this.language == 'en' ? 'es' : 'en'
    this.tokenFacade.switchLanguage(lang);
    this.translate.use(lang);
    this.initFormGroup()
    this.findLocation()
    this.initControl();
  }


  //* All for the Map


  markerClicked(pin, el?: HTMLElement) {

    if (this.previous !== null && this.previous !== pin.pinId) {
      this.workspacePins[this.previous].isClicked = false
    }

    this.workspacePins[pin.pinId].isClicked = true
    this.previous = pin.pinId

    this.workspaces = pin.workspaces
    el.scrollIntoView({ behavior: 'smooth' });
  }

  onMouseOver(infoWindow, $event: MouseEvent) {
    infoWindow.open();
  }

  onMouseOut(infoWindow, $event: MouseEvent) {
    infoWindow.close();
  }

  getAreasMap() {
    this.apiService.getAreasMap().pipe(
      take(1),
      takeUntil(this.killall)
    ).subscribe({
      next: areas => {
        this.areasMap = areas
      },
      error: err => {
        console.warn(err);
      }
    })
  }

  findLocation() {
    this.mapForm.get('location').valueChanges.pipe(
      tap(() => {
        this.selectedLocation = false;
        this.results = 0;
        this.workspaces = [];
        this.workspacePins = [];
        this.searchedMap = false;
      }),
      auditTime(300),
      tap(search => this.emptyArray(search)),
      filter(search => search !== ""),
      map(search => this.filterLocation(search)),
      takeUntil(this.killall)
    ).subscribe({
      next: () => {

      }
    })
  }

  // * Filtro de
  filterLocation(location: string) {
    let nearme = this.language === 'en' ? "See Locations Near Me" : "Ver espacios cerca de mí";
    let lowcaselocation = location ? location.toLowerCase() : ''
    //*                                                                                 Revisamos si el area escrita está incluida en el texto sin acentos (cleanString) o en el texto normal (location)
    let municipality = this.parseAreas(this.areasMap.municipality, "municipality").filter(area => area.cleanString.toLowerCase().includes(lowcaselocation) || area.location.toLowerCase().includes(lowcaselocation));
    let neighborhood = this.parseAreas(this.areasMap.neighborhood, "neighborhood").filter(area => area.cleanString.toLowerCase().includes(lowcaselocation) || area.location.toLowerCase().includes(lowcaselocation))
    let state = this.parseAreas(this.areasMap.state.filter(area => area !== null), "state").filter(area => area.cleanString.toLowerCase().includes(lowcaselocation) || area.location.toLowerCase().includes(lowcaselocation))
    this.filteredAreas = [{ location: nearme, type: "near me" }, ...state, ...neighborhood, ...municipality]
  }

  //* Parseamos las areas para agegar el tipo y controlar estilos
  parseAreas(areas: string[], type: string) {
    const locations = areas.map(area => {
      return {
        location: area,
        type: type,
        cleanString: area.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      }
    })
      return locations
  }



  getElm(el: HTMLElement) {
    this.elmnt = el
  }

  hideAnywhere() {
    const esp = 'Ver espacios cerca de mí';
    const en = 'See Locations Near Me';
    if (this.mapForm.get('location').value === en || this.mapForm.get('location').value === esp && this.counterHide === 0) {
      this.mapForm.get('location').patchValue('')
      this.selectedLocation = true; // * Esto para que no salga el cuadrito de abajo del type and select la primera vez que le das click
      this.counterHide++
    }
  }

  selectOption(area: any, el?: HTMLElement) {
    this.elmnt = el

    if (this.mapForm.get('type').value) {
      el.scrollIntoView({ behavior: 'smooth' });
    }

    this.mapForm.get('location').patchValue(area.location)
    area.type === 'state' ? this.zoom = 11.5 : area.type === 'municipality' ? this.zoom = 13 : area.type === 'neighborhood' ? this.zoom = 14 : this.fitBounds = true

    this.selectedLocation = true
    this.search()
  }

  //* Obtiene mis coordenadas
  getMyCurrentPosition() {
    navigator.geolocation.getCurrentPosition((pos) => {
      this.lat = pos.coords.latitude
      this.lng = pos.coords.longitude
    })
  }

}
