<div class="background-gradient">
    <div class="navbar">
        <!-- * Logotype -->
        <a [routerLink]="'/'"><img class="logo" src="/assets/img/logos/Logo_Adstop.png" alt="Adstop"></a>

        <button class="close-public" (click)="goBack()">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>

    <div class="wrap">
        <div class="form-container">
            <!--  ? Registra tu espacio -->
            <div class="white-text text-center fw-600">{{'landing.register.register-space' | translate }}</div>
            <div class="text-center new mb-4">Y se parte de la red más amplia de espacios publicitarios.</div>

            <!-- * Register Space Form -->
            <form class="minimalist transparent pb-5" [formGroup]="spaceForm" (ngSubmit)="contact(modalContactSuccess,fillAllTemplate)">

                <!-- *  Name Field -->
                <div class="form-group">
                    <label for="firstName">{{'landing.contact.first-name' | translate }}</label>
                    <input type="text" class="form-control" id="firstName" placeholder="Nombre" formControlName="firstName">
                    <small *ngIf="spaceForm.controls.firstName.invalid && spaceForm.controls.firstName.touched" class="text-alert">{{'landing.contact.only-letters' | translate }}</small>
                </div>

                <!-- *  LastName Field -->
                <div class="form-group">
                    <label for="lastName">{{'landing.register.last-name' | translate }}</label>
                    <input type="text" class="form-control" id="lastName" placeholder="Apellido" formControlName="lastName">
                    <small *ngIf="spaceForm.controls.lastName.invalid && spaceForm.controls.lastName.touched" class="text-alert">{{'landing.contact.only-letters' | translate }}</small>
                </div>

                <!-- *  Username Field -->
                <div class="form-group">
                    <label for="username">{{'landing.register.username' | translate }}</label>
                    <input type="text" class="form-control" id="username" placeholder="Usuario" formControlName="username">
                </div>

                <!-- * Email Field -->
                <div class="form-group">
                    <label for="email">{{'landing.contact.email' | translate }}</label>
                    <input type="email" class="form-control" id="email" placeholder="Correo@mail.com" formControlName="email">
                    <small *ngIf="spaceForm.controls.email.invalid && spaceForm.controls.email.touched" class="text-alert">{{'landing.contact.invalid-email' | translate }}</small>
                </div>

                <!-- * Password Field -->
                <div class="form-group">
                    <label for="password">{{'landing.register.password' | translate }}</label>
                    <input type="password" class="form-control" id="password" placeholder="********" formControlName="password">
                    <small *ngIf="spaceForm.controls.password.hasError('minlength') && spaceForm.controls.password.dirty" class="white text-alert">{{'landing.register.invalid-pass' | translate }}</small>
                    <small *ngIf="!spaceForm.controls.password.hasError('minlength')" class="text-alert text-alert-valid">{{'landing.register.minimum-characters' | translate }}</small>
                </div>

                <!-- * Confirm Password Field -->
                <div class="form-group">
                    <label for="confirmPassword">{{'landing.register.confirm-pass' | translate }}</label>
                    <input type="password" class="form-control" id="confirmPassword" placeholder="********" formControlName="confirmPassword">
                    <small *ngIf="spaceForm.errors?.invalid && spaceForm.controls.confirmPassword.dirty && spaceForm.controls.password.dirty" class="white text-alert">{{'landing.register.no-match' | translate }}</small>
                </div>

                <!-- * Brand Name  -->
                <div class="form-group">
                    <label for="coreName">{{'landing.register-space.form.core-name' | translate }}</label>
                    <input type="text" class="form-control" id="coreName" placeholder="{{'landing.register-space.form.core-name-ph' | translate }}" formControlName="coreName">
                </div>

                <!-- * Brand Description  -->
                <!-- <div class="form-group">
                    <label for="coreDescription">{{'landing.register-space.form.core-desc' | translate }}</label>
                    <textarea type="text" class="form-control" id="coreDescription" placeholder="{{'landing.register-space.form.core-desc-ph' | translate }}" formControlName="coreDescription"></textarea>
                </div> -->

                <!-- * Branch Name  -->
                <!-- <div class="form-group">
                    <label for="branchName">{{'landing.register-space.form.branch-name' | translate }}</label>
                    <input type="text" class="form-control" id="branchName" placeholder="{{'landing.register-space.form.branch-name-ph' | translate }}" formControlName="branchName">
                </div> -->

                <!-- * Branch Description  -->
                <!-- <div class="form-group">
                    <label for="branchDescription">{{'landing.register-space.form.branch-desc' | translate }}</label>
                    <textarea type="text" class="form-control" id="branchDescription" placeholder="{{'landing.register-space.form.branch-desc-ph' | translate }}" formControlName="branchDescription"></textarea>
                </div> -->
                <!-- <p style="cursor: pointer; color: red;" class="text-center text-alert text-alert-valid mt-4 mb-3">
                    {{'landing.register-space.form.able' | translate }}</p> -->
                <!-- * Street -->
                <!-- <div class="form-group">
                    <label for="street">{{'landing.register-space.form.street' | translate }}</label>
                    <input type="text" class="form-control" id="street" placeholder="{{'landing.register-space.form.street-ph' | translate }}" formControlName="street">
                </div> -->

                <!-- <div class="row"> -->
                <!-- * Exterior number -->
                <!-- <div class="form-group col">
                        <label for="exteriorNumber">{{'landing.register-space.form.ext-number' | translate }}</label>
                        <input type="text" class="form-control" id="exteriorNumber" placeholder="#" formControlName="exteriorNumber">
                    </div> -->
                <!-- * Interior number -->
                <!-- <div class="form-group col">
                        <label for="interiorNumber">{{'landing.register-space.form.int-number' | translate }}</label>
                        <input type="text" class="form-control" id="interiorNumber" placeholder="#" formControlName="interiorNumber">
                    </div>
                </div> -->

                <!-- * Zipcode -->
                <!-- <div class="form-group">
                    <label for="zipCode">{{'landing.register-space.form.zipcode' | translate }}</label>
                    <input type="text" class="form-control" id="zipCode" placeholder="000000" formControlName="zipCode" autocomplete="nope">
                </div> -->

                <!-- * Municipality -->
                <!-- <div class="form-group">
                    <label for="municipality">{{'landing.register-space.form.municipality' | translate }}</label>
                    <input type="text" class="form-control" id="municipality" placeholder="{{'landing.register-space.form.municipality' | translate }}" formControlName="municipality">
                </div> -->

                <!-- * State -->
                <!-- <div class="form-group">
                    <label for="state">{{'landing.register-space.form.state' | translate }}</label>
                    <input type="text" class="form-control" id="state" placeholder="{{'landing.register-space.form.state' | translate }}" formControlName="state">
                </div> -->

                <!-- * Country -->
                <!-- <div class="form-group">
                    <label for="country">{{'landing.register-space.form.country' | translate }}</label>
                    <input type="text" class="form-control" id="country" placeholder="{{'landing.register-space.form.country' | translate }}" formControlName="country">
                </div> -->

                <!-- * Neighborhood -->
                <!-- <div class="form-group">
                    <label for="neighborhood">{{'landing.register-space.form.neighborhood' | translate }}</label>
                    <input type="text" class="form-control" id="neighborhood" placeholder="{{'landing.register-space.form.neighborhood' | translate }}" formControlName="neighborhood">
                </div> -->

                <!-- * Privacy Notice Button -->
                <div class="policy-section">
                    <div class="radio_container mb-3" [ngClass]="checkTerms ? 'active' : ''">
                        <div (click)="changeTerms(1)" class="radio_control"></div>
                    </div>
                    <div class="section-polycy">
                        <p class="policy-text">
                            {{'landing.contact.by-sending' | translate }}
                            <b class="label-polycy" (click)="showTerms(wokeTemplate)">{{'landing.contact.terms' | translate
                }}</b> {{'landing.contact.and' | translate }}
                            <b class="label-polycy" (click)="showPolicies(wokeTemplate)">{{'landing.contact.policy' | translate }}</b>
                        </p>
                    </div>

                </div>


                <!-- * Submit Button -->
                <button type="submit" class="btn btn-woke btn-white" [disabled]="!spaceForm.valid && !checkTerms">
          <span *ngIf="!loading;else loader">{{'landing.contact.send' | translate }}</span>
          <ng-template #loader>
            <div class="spinner-grow spinner-grow-sm" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </ng-template>
        </button>

            </form>

        </div>
    </div>

</div>

<ng-template #wokeTemplate>
    <div class="modal-header">
        <div class="back_arrow" (click)="modalRef.hide()"></div>
        <h5 class="modal-title">{{title}}</h5>
    </div>
    <app-woke-terms [content]=content></app-woke-terms>
</ng-template>

<ng-template #modalContactSuccess>
    <div class="modal-header">
        <!-- * Thank you for contacting us -->
        <h5 class="modal-title">{{'landing.contact.modal.title' | translate }}</h5>
    </div>
    <div class="modal-body">
        <p class="text-center">{{'landing.contact.modal.body' | translate }}</p>
        <p class="text-center">{{'landing.contact.modal.body-2' | translate }}</p>
    </div>
    <div class="modal-footer">
        <button (click)="closeModal()" type="button" class="btn btn-link red" data-dismiss="modal">Ok</button>
    </div>
</ng-template>

<ng-template #fillAllTemplate>
    <div class="modal-header">
        <h5 class="modal-title semi-bold">Ups!</h5>
    </div>
    <div class="modal-body delete confirm">
        <ng-container [ngSwitch]="errorMessage">
            <p *ngSwitchCase="'Email already exists.'" class="mb-0 error m-auto">
                {{'landing.register.email-exist' | translate}}
            </p>
            <p *ngSwitchCase="'Username already exists.'" class="mb-0 error m-auto">
                {{'landing.register.user-exist' | translate}}
            </p>
            <p *ngSwitchDefault class="mb-0 error m-auto">
                {{'landing.register.fill-in' | translate}}
            </p>
        </ng-container>
    </div>
    <div class="modal-footer">
        <div class=" col-6 py-0 justify-content-center">
            <button class="btn red m-auto" (click)="modalRef.hide()">Ok</button>
        </div>
    </div>
</ng-template>
