<main class="background-gradient">
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-10 col-md-8 mt-5">
          <div class="container title-msg align-items-center">
            <h3>Create Business Profile</h3>
            <!-- <small *ngIf="!registerForm.valid" class="text-alert-header">Please fill in all available fields.</small> -->
          </div>
          <!-- Register Form -->
          <form >
            <p>Create a Business Profile in Woke. If you don’t wish to do so, you can create one later on in the “Settings” section.
                By creating a Business Profile you will be able to get hired for complex projects, register other admin members to the profile,
                as well as register an office plan to the Business within Woke, or existing coworking spaces.</p>
            <div class="mb-3">
              <label class="semi-bold">Name</label>
              <input  class="jumbo-mask" type="text" placeholder="Your business's name">
            </div>

            <div class="mb-3">
              <label class="semi-bold">Username</label>
              <input  class="jumbo-mask" type="text" placeholder="@username">
            </div>
<!--
            <div class="mb-3">
              <label class="semi-bold">Email</label>
              <input  class="jumbo-mask" type="text" placeholder="Business email">
            </div> -->

            <div class="mb-3">
              <label class="semi-bold">Password</label>
              <input  class="jumbo-mask" type="password" placeholder="********">
            </div>

            <div class="mb-3">
              <label class="semi-bold">Confirm password</label>
              <input  class="jumbo-mask" type="password" placeholder="********">
            </div>

            <div class="row justify-content-around">
                <div class="col-6">
                    <button type="button" class="btn btn-action btn-red">Continue</button>
                </div>
              </div>
          </form>

        </div>
      </div>

    </div>
  </main>

