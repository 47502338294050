
import { Workspace } from 'src/app/api.interfaces';
import { startOfDay, addMinutes, addHours, differenceInHours, differenceInDays, differenceInMinutes } from 'date-fns';

export type WorkspacesResponse = {
  total: number;
  workspaces: Workspace[];
  current?: number;
}

export type RadioFilter = {
  id: number;
  name: string;
  active: boolean;
}

export type BookingCoworking = {
  id: number;
  name: string;
  profilePicture: string;
  rating: number;
}

export type BookingWorkspace = {
  active?:boolean;
  closesAt?:string;
  opensAt?:string;
  id: number;
  location: string;
  minimumBookingTime: 'Hour' | 'Day';
  name: string;
  profilePicture: string;
  type: string;
  capacity?:number;
}

export type BookingConfig = {
  invitation: boolean;
}

export type TimeManagerPayload = {
  type: string;
  time?: string;
  date?: string;
  minBookingTime?:string
}

export class TimeManager {

  type: string;
  date: string;
  time: string;
  dateStart: string;
  dateStartDate: Date;
  dateEnd: string;
  dateEndDate: Date;
  hourStart: string;
  minuteStart: string;
  hourEnd: string;
  minuteEnd: string;
  startTimeAt: string;
  endTimeAt: string;
  minBookintTime:string;

  constructor(payload: TimeManagerPayload) {
    this.type = payload.type;
    this.minBookintTime = payload.minBookingTime
    if (payload.date) {
      this.date = payload.date;
      const [dateStart, dateEnd] = payload.date.split(' - ');
      this.dateStart = dateStart;
      this.dateStartDate = this.parseDateStrToDate(dateStart);
      if (dateEnd === undefined) {
        this.dateEnd = dateStart;
        this.dateEndDate = this.parseDateStrToDate(dateStart);
      } else {
        this.dateEnd = dateEnd;
        this.dateEndDate = this.parseDateStrToDate(dateEnd);
      }
    }

    if (payload.time) {
      this.time = payload.time;
      this.setTime(payload.time);
    }
  }

  setTime(time: string) {
    const [start, end] = time.split(' - ');
    this.startTimeAt = start;
    this.endTimeAt = end;
    const [hourStart, minuteStart] = start.split(':');
    const [hourEnd, minuteEnd] = end.split(':');
    this.hourStart = hourStart;
    this.minuteStart = minuteStart;
    this.hourEnd = hourEnd;
    this.minuteEnd = minuteEnd;
  }

  parseDateStrToDate(dateStr: string = '31/12/2020') {
    const [dayStr, monthStr, yearStr] = dateStr.split('/');
    const monthNumber = Number(monthStr) - 1;
    const date = new Date(+yearStr, monthNumber, +dayStr);
    return date;
  }

  parseDateStrToISOStr(dateStr: string) {
    const [dayStr, monthStr, yearStr] = dateStr.split('/');
    const iso8601 = `${yearStr}-${monthStr}-${dayStr}T06:00:00.000Z`;
    return iso8601;
  }

  getTimeSelected(): number {
    let timeSelected = 1;
    if (this.minBookintTime === 'Hour') {
      const date = new Date();
      const today = startOfDay(date);
      const todayStart = addMinutes(addHours(today, +this.hourStart), +this.minuteStart);
      const todayEnd = addMinutes(addHours(today, +this.hourEnd), +this.minuteEnd);

      return differenceInHours(todayEnd, todayStart);
    }
    timeSelected += differenceInDays(this.dateEndDate, this.dateStartDate);
    return timeSelected;
  }


}

export class ModifyBookingModel extends TimeManager {

  constructor(payload: TimeManagerPayload) {
    super(payload);
  }

  getFormData(type: 'modify' | 'cancel'): FormData {
    const formData = new FormData();

    formData.append('type', type);

    formData.append('specialRequest', '');

    if (type === 'modify') {
      if (this.dateStart) {
        formData.append('startAt', this.dateStart);
      }
      if (this.dateEnd) {
        formData.append('endAt', this.dateEnd);
      }
      if (this.startTimeAt) {
        formData.append('startTimeAt', this.startTimeAt);
      }
      if (this.endTimeAt) {
        formData.append('endTimeAt', this.endTimeAt);
      }
      const timeSelected = this.getTimeSelected();
      formData.append('timeSelected', `${timeSelected}`);
    }

    return formData;
  }

}

export class BookingClass  {

  coworkingChild: BookingCoworking;
  endAt: string;
  endTimeAt?: string;
  id: number;
  internal:boolean;
  isModified: boolean;
  qr: string;
  startAt: string;
  startTimeAt?: string;
  withMoney: boolean;
  workspace: BookingWorkspace;
  invites = [];
  timeSelected?:number;

  invitation: boolean;

  constructor(payload: any, config?: BookingConfig) {
    this.coworkingChild = payload.coworkingChild || <BookingCoworking>{};
    this.endAt = payload.endAt || '';
    this.endTimeAt = payload.endTimeAt;
    this.id = payload.id || 0;
    this.internal = payload.internal;
    this.isModified = payload.isModified;
    this.qr = payload.qr;
    this.startAt = payload.startAt || '';
    this.startTimeAt = payload.startTimeAt;
    this.withMoney = payload.withMoney;
    this.timeSelected = payload.timeSelected;
    this.workspace = payload.workspace || <BookingWorkspace>{};
    if (payload.invites && Array.isArray(payload.invites)) {
      this.invites = payload.invites;
    } else {
      this.invites = this.parseObjToArray(payload.invites);
    }
    if (config) {
      this.invitation = config.invitation;
    }
  }

  parseObjToArray(obj: any) {
    const array = [];
    if (obj) {
      for (const [key, value] of Object.entries(obj)) {
        array.push(value);
      }
    }
    return array;
  }

  getPiecesOfDate(date: string) {
    const [day, month, year] = date.split('-');
    return {
      day: +day,
      month: +month - 1,
      year: +year
    }
  }

  getPiecesOfTime(time: string) {
    const [hours, minutes] = time.split(':');
    return {
      hours: +hours,
      minutes: +minutes
    }
  }

  createDateWithPieces(day: number, month: number, year: number) {
    return new Date(year, month, day)
  }

  createTimeWithPieces(hours: number, minutes: number) {
    return addMinutes(addHours(startOfDay(new Date()), hours), minutes)
  }

  getUntilDate() {
    const today = startOfDay(new Date());
    const {day, month, year} = this.getPiecesOfDate(this.startAt);
    const bookingDate = this.createDateWithPieces(day, month, year);
    const daysUntil = differenceInDays(bookingDate, today);


    return daysUntil;
  }

  getUntilTime() {
    const today = new Date();
    const {hours, minutes} = this.getPiecesOfTime(this.startTimeAt);
    const bookingTime = this.createTimeWithPieces(hours, minutes);

    return differenceInHours(bookingTime, today);
  }

  canModify() {
    // Espacios de tipo other no pueden modificarse
    if(this.workspace.type === 'Other'){
      return false
    }
    //* Usuarios internos pueden modificar hasta una hora antes del booking
    if (this.internal === true) {
        if(this.getUntilDate() >= 1){
          return true
         }else{
          if (this.getUntilTime() >= 1){
            return true
          }
          return false
         }
    } else if (this.internal === false && this.getUntilDate() > 1 ){ //* Usuarios externos pueden modificar hasta un día antes del booking
      //console.log("canmodify external", this.getUntilTime());
      return true
    }
    return false
  }

  canCancel() {
    //* Sólo pueden cancelarse bookings cuando se pagaron con créditos y cuando se es interno
   if (this.withMoney === false) {
    //console.log(this.getUntilTime(), this.getUntilDate());

     //* Sólo pueden cancelarse bookings hasta una hora antes de que sucedan
     if(this.getUntilDate() >= 1){
      return true
     }else{
      if (this.getUntilTime() >= 1){
        return true
      }
      return false
     }
    }
    return false
  }

}

export type ModifyBookingPayload = {
  type: 'modify' | 'cancel';
  startAt: string;
  endAt: string;
  startTimeAt: string;
  endTimeAt: string;
  timeSelected: number;
}

export type BookingInfo = {
  id?:number,
  type: string,
  by_hours?: boolean,
  meeting_credits?: string,
  meeting_hours?: number,
  credits_limit?:number,
  currency_value?: string,
  internalPrice?:string,
  user_type?: string,
  previous?:number,
  cost?: {
    subTotal: number,
    paymentFeeIva: number,
    paymentFee: number,
    tax: number,
    total: number,
    speiPaymentFee: number,
    speiTax: number,
    speiTotal: number,
  },
  amount?: {
    subTotal: number,
    paymentFeeIva?: number,
    paymentFee?: number,
    tax?: number,
    total: number,
  }
}
