<!-- ! Footer -->
<footer class="d-flex desk">

    <div class="logo-social">
        <div>
            <img class="logo" src="/assets/img/logos/logoaDSTOP.png" alt="Adstop">

            <!-- Social Networks -->
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link" href="https://twitter.com/edit/gidemorelos" target="_blank">
                        <i class="fab fa-twitter"></i>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="https://www.facebook.com/grupogiamx" target="_blank">
                        <i class="fab fa-facebook-f"></i>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="https://www.instagram.com/grupogia" target="_blank">
                        <i class="fab fa-instagram"></i>
                    </a>
                </li>
            </ul>
            <div class="badges">
                <!-- <a href='https://play.google.com/store/apps/details?id=com.wokeliteapp'><img alt='Google Play' [src]=androidBadge /></a>
                <a href='https://apps.apple.com/mx/app/bauns/id1515925891'><img id="apple" alt='App Store' [src]=iosBadge /></a>
            -->
            </div>

        </div>
        <p>Adstop 2024 ©</p>
    </div>

    <div class="faq-links">
        <a [routerLink]="'/'" class="btn-link">{{'footer.home' | translate }}</a>
        <a [routerLink]="'/about'" class="btn-link">{{'footer.tools' | translate }}</a>
        <a [routerLink]="'/register-space'" class="btn-link">{{'footer.register-space' | translate }}</a>

    </div>

    <div class="terms-policys">
        <div class="faq-links-info">
            <a [routerLink]="'/log-in'" class="btn-link">{{'footer.login' | translate }}</a>
            <a [routerLink]="'/register'" class="btn-link">{{'footer.register' | translate }}</a>
            <a [routerLink]="'/contact'" class="btn-link">{{'footer.contact' | translate }}</a>
        </div>
        <div class="button-terminos">
            <a [routerLink]="'terms-and-conditions'" target="_blank" class="">{{'footer.terms' | translate
        }}</a>
            <a [routerLink]="'privacy-policy'" target="_blank" class="">{{'footer.policy' | translate }}</a>

        </div>



    </div>
</footer>
<footer class="d-flex mobile">

    <div class="logo-social">
        <div>
            <img class="logo" src="/assets/img/logos/Logo_Adstop.png" alt="Adstop">
            <!-- Social Networks -->
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link" href="https://twitter.com/baunsco" target="_blank">
                        <i class="fab fa-twitter"></i>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="https://www.facebook.com/bauns.co" target="_blank">
                        <i class="fab fa-facebook-f"></i>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="https://www.instagram.com/bauns.co" target="_blank">
                        <i class="fab fa-instagram"></i>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="https://www.linkedin.com/company/baunsco/" target="_blank">
                        <i class="fab fa-linkedin-in"></i>
                    </a>
                </li>
            </ul>
            <div class="badges">
                <a href='https://play.google.com/store/apps/details?id=com.wokeliteapp'><img alt='Google Play' [src]=androidBadge /></a>
                <a href='https://apps.apple.com/mx/app/bauns/id1515925891'><img id="apple" alt='App Store' [src]=iosBadge /></a>
            </div>
        </div>
        <div class="faq-links">
            <a [routerLink]="'/about'" class="btn-link">{{'footer.tools' | translate }}</a>
            <a [routerLink]="'/register'" class="btn-link">{{'footer.register' | translate }}</a>
            <a [routerLink]="'/register-space'" class="btn-link">{{'footer.register-space' | translate }}</a>
            <a [routerLink]="'/log-in'" class="btn-link">{{'footer.login' | translate }}</a>
            <a [routerLink]="'/contact'" class="btn-link">{{'footer.contact' | translate }}</a>
        </div>
    </div>

    <div class="terms-policy">
        <p>Adstop 2024 ©</p>
        <!-- <a [routerLink]="'terms-and-conditions'" target="_blank" class="btn-link">{{'footer.terms' | translate }}</a>
        <a [routerLink]="'privacy-policy'" target="_blank" class="btn-link">{{'footer.policy' | translate }}</a> -->

    </div>
</footer>
