<!-- Rating -->
<app-woke-subsection>
  <app-woke-subsection-title>
    <div>Ratings & Reviews</div>
    <div class="coworking_rate">
      <span class="big_number">4.3</span>
      <span>out of 5</span>
    </div>
  </app-woke-subsection-title>
  <app-woke-subsection-actions>
    <span>See More</span>
    <div class="coworking_rate">
      137 ratings
    </div>
  </app-woke-subsection-actions>
  <app-woke-rating></app-woke-rating>
</app-woke-subsection>
