<div>
    <!-- Description -->
    <div class="my-5" *ngIf="(bookingInfo$ | async) as bookingInfo">

        <ng-container *ngIf="bookingInfo.amount">
            <div class="d-flex justify-content-between mb-3">
                <div class="gray">Subtotal</div>
                <div>${{bookingInfo.amount.subTotal}}</div>
            </div>
            <div class="d-flex justify-content-between mb-3">
                <div class="gray">Payment Fee</div>
                <div>${{bookingInfo.amount.paymentFee}}</div>
            </div>
            <div class="d-flex justify-content-between mb-5">
                <div class="gray">Tax</div>
                <div>${{bookingInfo.amount.tax}}</div>
            </div>
            <div class="d-flex justify-content-between">
                <h4 class="red semi-bold">Total</h4>
                <h4 class="red semi-bold">${{bookingInfo.amount.total}}</h4>
            </div>
        </ng-container>

    </div>
    <!-- Payment methodss -->
    <div class="mb-5">

        <div class="d-flex justify-content-between mb-3">
            <h6>Payment Method</h6>
            <ng-container *ngIf="paymentDefault$ | async; else addNew">
                <div class="red pointer" (click)="changePaymentMethod()">Change</div>
            </ng-container>
            <ng-template #addNew>
                <div class="red pointer" (click)="addPaymentMethod()">Add</div>
            </ng-template>
        </div>

        <ng-container *ngIf="paymentDefault$ | async as payment">
            <div class="jumbo-mask p-2 mb-4">
                <div class="visa_icon"></div>
                <div class="mb-4">
                    <div class="gray">Card Number</div>
                    <div>{{payment.data?.card_number}}</div>
                </div>
                <div>
                    <div class="gray">Expiration Date</div>
                    <div>{{payment.data?.expiration_month}}/{{payment.data?.expiration_year}}</div>
                </div>
            </div>
        </ng-container>

    </div>

    <!-- Invoice -->
    <div class="d-flex justify-content-between mb-3">
        <h6>Invoice</h6>
        <div class="red pointer" [routerLink]="'/wallet/billing-information'">Edit</div>
    </div>
    <ng-container *ngIf="billingInformation && billingInformation.rfc">
        <app-woke-select [formControl]="cfdiControl" [options]="cfdis" valueAccesor="id">
            <ng-template #item let-option="option">
                {{option.name}}
            </ng-template>
            <ng-template #selected let-value="value">
                {{value.name}}
            </ng-template>
        </app-woke-select>
        <div class="jumbo-mask mb-5 mt-3">
            <div class="mb-3 d-flex justify-content-between">
                <div>
                    <div class="gray">Full Name</div>
                    <div> {{billingInformation.fullName}}</div>
                </div>
            </div>
            <div class="mb-3">
                <div class="gray">Address</div>
                <div>{{billingInformation.street}} {{billingInformation.exteriorNumber}}, {{billingInformation.neighborhood}}, {{billingInformation.zipCode}}, {{billingInformation.municipality}}, {{billingInformation.state}}, {{billingInformation.country}}</div>
            </div>
            <div class="mb-3">
                <div class="gray">RFC</div>
                <div>{{billingInformation.rfc}}</div>
            </div>
        </div>
    </ng-container>

</div>
