<h4 class="mb-5">Checkout</h4>

<app-workspace-detail (onWorkspace)="onWorkspace($event)"></app-workspace-detail>

<form [formGroup]="formGroup">
  <div class="mb-4">
    <label class="form-label semi-bold">Event Name</label>
    <input formControlName="nameBooking" class="jumbo-mask" type="text" placeholder="How will your event be called?">
  </div>
</form>

<div class="d-flex justify-content-between mb-4" *ngIf="(bookingType === 'Hour' && offices.length) || bookingType === 'Hour'">
  <div>
    <div class="semi-bold">Use Office Plan</div>
    <div class="gray">Use the credits in your Business Plan to book this workspace</div>
  </div>
  <div>
    <ui-switch defaultBgColor="#f5f5f5" size="small" color="#5ED2AA" [(ngModel)]="useBusinessPlan"></ui-switch>
  </div>
</div>

<!-- Pay with Money -->
<ng-container *ngIf="!useBusinessPlan; else payWithOffice">
  <app-pay-with-money (onPayment)="onPayment($event)"></app-pay-with-money>
</ng-container>

<!-- Pay with Office -->
<ng-template #payWithOffice>
  <div>
    <div class="form-group">
      <label class="form-label semi-bold">Office</label>
      <app-woke-select
        [formControl]="officeControl"
        [options]="offices"
        valueAccesor="id">
        <ng-template #item let-option="option">
          Oficina {{option.officeNumber}}
        </ng-template>
        <ng-template #selected let-value="value">
          Oficina {{value.officeNumber}}
        </ng-template>
      </app-woke-select>
    </div>

    <div class="mb-3" *ngIf="total">
      <label class="form-label semi-bold">Total</label>
      <div class="jumbo-mask option-selected">{{total.text}}</div>
    </div>
  </div>
</ng-template>


<app-checkout-invites (onInvites)="onInvites($event)"></app-checkout-invites>

<button class="btn btn-red btn-action" type="button" (click)="confirm(modalRequest)">
  <span *ngIf="!loading;else loader">Confirm</span>
  <ng-template #loader>
    <div class="spinner-grow spinner-grow-sm" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </ng-template>
</button>


<ng-template #modalRequest>
  <div class="modal-header">
    <h5 class="modal-title">Payment successful!</h5>
  </div>
  <div class="modal-body">
    <p class="text-center">Your payment has been processed.
      Enjoy your workspace!</p>
  </div>
  <div class="modal-footer">
    <button (click)="goToWorkspace()" type="button" class="btn btn-link red" data-dismiss="modal">Ok</button>
  </div>
</ng-template>
