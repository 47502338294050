<main class="background-gradient">
  <div class="container">
    <div class="row justify-content-center align-items-center">
      <div class="col-10 col-md-7 col-lg-5">

        <!-- Login Form -->
        <form class="minimalist transparent" [formGroup]="recoverForm" >

          <!-- Email Field -->
          <div class="form-group">
            <label for="password">Contraseña</label>
            <input type="password" class="form-control" id="password" placeholder="********" formControlName="password">
            <small *ngIf="recoverForm.controls.password.hasError('minlength') && recoverForm.controls.password.dirty" class="text-alert">Contraseña Invalida</small>
            <small *ngIf="!recoverForm.controls.password.hasError('minlength')" class="text-alert text-alert-valid" >Minimo 8 caracteres</small>
          </div>

          <!-- Confirm Password Field -->
          <div class="form-group">
            <label for="confirmPassword">Confirmar Contraseña</label>
            <input type="password" class="form-control" id="confirmPassword" placeholder="********" formControlName="confirmPassword">
            <small *ngIf="recoverForm.errors?.invalid && recoverForm.controls.confirmPassword.dirty && recoverForm.controls.password.dirty" class="text-alert">La contraseña no coincide</small>
          </div>
  <!-- Submit Button -->
          <button class="btn btn-woke btn-wine" [disabled]="loading" (click)="recoverPass(modalSuccess)">
            <span *ngIf="!loading;else loader">Cambiar Contraseña</span>
            <ng-template #loader>
              <div class="spinner-grow spinner-grow-sm" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </ng-template>
          </button>
        </form>


      </div>
    </div>

  </div>
</main>

<ng-template #modalSuccess>
  <div class="modal-header">
    <h5 class="modal-title">!Felicitaciones!</h5>
  </div>
  <div class="modal-body">
    <p class="text-center">Tu contraseña ha sido cambiada satisfactoriamente</p>
  </div>
  <div class="modal-footer">
    <button (click)="modalRef.hide()" [routerLink]="['/']" type="button" class="btn btn-link red" data-dismiss="modal">Ok</button>
  </div>
</ng-template>
