import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PlanService } from '../../../logged/workspaces/workspace/plan/plan.service';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { WorkspaceService } from 'src/app/logged/workspaces/workspace/workspace.service';
import { CoworkingChild } from 'src/app/api.interfaces';
import { CoworkingChildsFacade } from 'src/store/childs/childs.facade';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TokenFacade } from 'src/store/token/token.facade';


@Component({
  selector: 'app-workspace',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.scss']
})
export class WorkspaceComponent implements OnInit, OnDestroy {

  showBusinessForm = true;
  showOfficeform = false;
  errorMsj:string
  isError:boolean
  registeringBusiness = false;
  registeringOffice = false;


  creatingBusiness = false;
  creatingOffice = false;

  featured$ = this.service.featured$;
  registerForm: UntypedFormGroup;

  formGroup = this.service.initOfficeFormGroup();

  name: string;
  username: string;
  password: string;

  language:string
  killall = new Subject();

  constructor(
    private fb: UntypedFormBuilder,
    public modalRef: BsModalRef,
    private modalService: BsModalService,
    private service: PlanService,
    private workspaceService: WorkspaceService,
    private childsFacade: CoworkingChildsFacade,
    private tokenFacade: TokenFacade
  ) { }

  ngOnInit() {
    this.tokenFacade.language$.pipe(
      take(1),
      takeUntil(this.killall)
    ).subscribe({
      next: lan => {
        this.language = lan
      }
    })
    this.initRegisterForm()
    this.workspaceService.loadChilds().pipe(
      take(1)
    ).subscribe((childs: CoworkingChild[]) => {
      this.childsFacade.loadSucces(childs);
    })

  }

  ngOnDestroy() {
    this.closeModal();
    this.killall.next();
    this.killall.complete();
  }

  initRegisterForm() {
    this.registerForm = this.fb.group({
      name: ['', [Validators.required,Validators.minLength(3)]],
      usernameBusiness: ['', [Validators.required,Validators.minLength(8)]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
    },{validator: this.passwordConfirming});
  }

  passwordConfirming(pass: AbstractControl): { invalid: boolean } {
    if (pass.get('password').value !== pass.get('confirmPassword').value) {
        return {invalid: true};
    }
  }

  skipBusiness() {
    this.closeModal();
  }

  businessContinue() {
    this.registeringBusiness = true;
    if(this.registerForm.valid){
      this.service.registerBusinessProfile(this.registerForm.value).subscribe({
      next: () => {
        console.log('Advance to next slide');
        this.registeringBusiness = false;
        this.showBusinessForm = false;
        this.showOfficeform = true;

      },
      error: error => {
        console.log(error);

        this.isError = true;
        this.errorMsj = this.language == 'en' ? error.error.error : 'El usuario ya existe.'
        this.registeringBusiness = false;
      },
      complete: () => console.log('Register Completed')
      })
    }else{
      this.isError = true;
    }
  }



  skipOffice() {
    this.closeModal();
    window.location.reload();
  }

  officeContinue() {
    this.registeringOffice = true;
    this.service.officeApply(this.formGroup.value).pipe(
      take(1),
      takeUntil(this.killall)
    ).subscribe({
      next: data => {
        console.log(data);
        this.registeringOffice = false;
        this.closeModal();
        window.location.reload();
      },
      error: () => {
        this.registeringOffice = false;
      },
      complete: () => console.log('Apply office complete')
    });
  }

  closeModal() {
    this.modalService.hide();
    document.querySelector('body').classList.remove('modal-open');
  }

}
