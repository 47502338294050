<main class="background-gradient-lite">
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <div class="register col-11 col-md-8 mt-5 mb-5">
                <div class="container title-msg align-items-center">
                    <h3>Create an Account</h3>
                    <small *ngIf="fill_in" class="text-alert-header">Please fill in all available fields.</small>
                </div>
                <!-- Register Form -->
                <!-- <form class="minimalist white pb-5" [formGroup]="registerForm" (ngSubmit)="register()">   -->
                <form class="minimalist white pb-5" [formGroup]="registerForm">
                    <!-- First Name Field -->
                    <div class="form-group">
                        <label for="firstName">First Name</label>
                        <input (input)="fill_in = false" type="text" class="form-control" id="firstName" placeholder="Jane" formControlName="firstName">
                        <small *ngIf="registerForm.controls.firstName.invalid && registerForm.controls.firstName.dirty && !fill_in" class="red text-alert">Only letters please</small>
                        <small *ngIf="fill_in && !registerForm.controls.firstName.dirty" class=" red text-alert">This field is required</small>

                    </div>

                    <!-- Last Name Field -->
                    <div class="form-group">
                        <label for="lastName">Last Name</label>
                        <input type="text" class="form-control" id="lastName" placeholder="Doe" formControlName="lastName">
                        <small *ngIf="registerForm.controls.lastName.invalid && registerForm.controls.lastName.dirty" class="red text-alert">Only letters please</small>
                    </div>

                    <!-- User Name Field -->
                    <div class="form-group">
                        <label for="username">Username</label>
                        <input (input)="fill_in = false" type="text" class="form-control" id="username" placeholder="jane.doe" formControlName="username">
                        <small *ngIf="registerForm.controls.username.invalid && registerForm.controls.username.dirty && !fill_in" class=" red text-alert">This field is required</small>
                        <small *ngIf="fill_in && !registerForm.controls.username.dirty" class=" red text-alert">This field is required</small>
                    </div>

                    <!-- Email Field -->
                    <div class="form-group">
                        <label for="email">Email address</label>
                        <input (input)="fill_in = false" type="email" class="form-control" id="email" placeholder="Correo@gmail.com" formControlName="email">
                        <small *ngIf="registerForm.controls.email.invalid && registerForm.controls.email.dirty && !fill_in" class="red text-alert">Invalid email</small>
                        <small *ngIf="fill_in && !registerForm.controls.email.dirty" class=" red text-alert">This field is required</small>
                    </div>

                    <!-- Password Field -->
                    <div class="form-group">
                        <label for="password">Password</label>
                        <input type="password" class="form-control" id="password" placeholder="********" formControlName="password">
                        <small *ngIf="registerForm.controls.password.hasError('minlength') && registerForm.controls.password.dirty" class="red text-alert">Invalid Password</small>
                        <small *ngIf="!registerForm.controls.password.hasError('minlength')" class="text-alert text-alert-valid">Minimum 8 characters</small>
                    </div>

                    <!-- Confirm Password Field -->
                    <div class="form-group">
                        <label for="confirmPassword">Confirm Password</label>
                        <input type="password" class="form-control" id="confirmPassword" placeholder="********" formControlName="confirmPassword">
                        <small *ngIf="registerForm.errors?.invalid && registerForm.controls.confirmPassword.dirty && registerForm.controls.password.dirty" class="red text-alert">Password does not match</small>
                    </div>

                    <!-- Privacy Notice Button -->
                    <p class="policy">
                        <span>By creating an account you agree to our</span>
                        <a (click)="showTerms(wokeTemplate)" class="btn btn-link">Terms and Conditions</a>
                        <span>and</span>
                        <a (click)="showPolicies(wokeTemplate)" class="btn btn-link">Privacy Policy</a>
                    </p>

                    <!-- Submit Button -->
                    <button type="button" class="btn btn-woke btn-red" [disabled]="loading" (click)="register(fillAllTemplate,successTemplate)">
            <span *ngIf="!loading;else loader">Continue</span>
            <ng-template #loader>
              <div class="spinner-grow spinner-grow-sm" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </ng-template>
          </button>

                </form>

            </div>
        </div>

    </div>
</main>
<ng-template #wokeTemplate>
    <div class="modal-header">
        <div class="back_arrow" (click)="modalRef.hide()"></div>
        <h5 class="modal-title">{{title}}</h5>
    </div>
    <app-woke-terms [content]=content></app-woke-terms>
</ng-template>

<ng-template #fillAllTemplate>
    <div class="modal-header">
        <h5 class="modal-title semi-bold">Ups!</h5>
    </div>
    <div class="modal-body delete confirm">
        <p class="mb-0 error m-auto">
            {{errorMessage !=='' ? errorMessage : 'Please fill in all available fields.'}}
        </p>
    </div>
    <div class="modal-footer">
        <div class=" col-6 py-0 justify-content-center">
            <button class="btn red m-auto" (click)="modalRef.hide()">Ok</button>
        </div>
    </div>
</ng-template>

<ng-template #successTemplate>
    <div class="modal-header">
        <h5 class="modal-title semi-bold">Congratulations!</h5>
    </div>
    <div class="modal-body delete confirm">
        <p class="mb-0 error m-auto">
            Your user has been created successfully, please return to WokeApp.
        </p>
    </div>
    <div class="modal-footer">
        <div class=" col-6 py-0 justify-content-center">
            <button class="btn red m-auto" (click)="modalRef.hide()">Ok</button>
        </div>
    </div>
</ng-template>