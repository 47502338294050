import { createAction, props } from '@ngrx/store';
import { ProfileData, EditProfileData, Experience, AcademicInformation, Recommendation, Category, PortfolioImage, Portfolio, MyOffice, ExtraOffice, ProfileDataV2, References } from 'src/app/api.interfaces';
import { MyLicense } from 'src/app/logged/my-license/my-license.types';

export const ProfileActions = {

  loadLicensesSuccess: createAction(
    `[API] Load Licenses Success`,
    props<{ licenses: MyLicense[] }>()
  ),

  loadOfficesSuccess: createAction(
    `[API] Load Offices Success`,
    props<{ offices: MyOffice[] }>()
  ),

  loadExtraOfficesSuccess: createAction(
    `[API] Load ExtraOffices Success`,
    props<{ extraOffices: ExtraOffice[] }>()
  ),

  loadCategories: createAction(
    `[API] Load Categories`,
  ),
  loadCategoriesSuccess: createAction(
    `[API] Load Categories Success`,
    props<{ categories: Category[] }>()
  ),
  loadCategoriesError: createAction(
    `[API] Load Categories Error`,
  ),

  // Load personal profile
  load: createAction(
    `[API] Load Profile`
  ),
  loadSuccess: createAction(
    `[API] Load Profile Success`,
    props<{ data: ProfileData }>()
  ),
  loadData: createAction(
    `[API] Load Profile Data`,
    props<{ data: ProfileData }>()
  ),
  loadError: createAction(
    `[API] Load Profile Error`
  ),

  // Load Business Profile
  loadBusiness: createAction(
    `[API] Load Business Profile`,
    props<{ username: string }>()
  ),
  loadBusinessSuccess: createAction(
    `[API] Load Business Profile Success`,
    props<{ data: ProfileData }>()
  ),
  loadBusinessError: createAction(
    `[API] Load Business Profile Error`
  ),

  // Edit profile
  editProfile: createAction(
    `[API] Edit Profile`,
    props<{ actualEmail:string, payload: EditProfileData, businessProfile: boolean }>()
  ),
  editProfileSuccess: createAction(
    `[API] Edit Profile Success`,
    props<{ payload: EditProfileData }>()
  ),
  editProfileError: createAction(
    `[API] Edit Profile Error`,
  ),


  // Update the profile Picture
  updateProfilePicture: createAction(
    `[API] Update Profile Picture`,
    props<{ image: string, businessProfile: boolean }>()
  ),
  updateProfilePictureSuccess: createAction(
    `[API] Update Profile Picture Success`,
    props<{ image: string }>()
  ),
  updateProfilePictureError: createAction(
    `[API] Update Profile Picture Error`
  ),


  // Add new Category
  addCategory: createAction(
    `[API] Add Category`,
    props<{ idCategory: string, businessProfile: boolean }>()
  ),
  addCategorySuccess: createAction(
    `[API] Add Category Success`
  ),
  addCategoryError: createAction(
    `[API] Add Category Error`
  ),


  // Remove a Category
  removeCategory: createAction(
    `[API] Remove Category`,
    props<{ idCategory: string, businessProfile: boolean }>()
  ),
  removeCategorySuccess: createAction(
    `[API] Remove Category Success`
  ),
  removeCategoryError: createAction(
    `[API] Remove Category Error`
  ),


  // Add new specialti
  addSpecialti: createAction(
    `[API] Add Specialti`,
    props<{ specialti: string, businessProfile: boolean }>()
  ),
  addSpecialtiSuccess: createAction(
    `[API] Add Specialti Success`
  ),
  addSpecialtiError: createAction(
    `[API] Add Specialti Error`
  ),


  // Remove a Specialti
  removeSpecialti: createAction(
    `[API] Remove Specialti`,
    props<{ index: number, businessProfile: boolean }>()
  ),
  removeSpecialtiSuccess: createAction(
    `[API] Remove Specialti Success`
  ),
  removeSpecialtiError: createAction(
    `[API] Remove Specialti Error`
  ),

  // Add new feature
  addFeature: createAction(
    `[API] Add Feature`,
    props<{ feature: string, businessProfile: boolean }>()
  ),
  addFeatureSuccess: createAction(
    `[API] Add Feature Success`
  ),
  addFeatureError: createAction(
    `[API] Add Feature Error`
  ),

  // Remove a Feature
  removeFeature: createAction(
    `[API] Remove Feature`,
    props<{ index: number, businessProfile: boolean }>()
  ),
  removeFeatureSuccess: createAction(
    `[API] Remove Feature Success`
  ),
  removeFeatureError: createAction(
    `[API] Remove Feature Error`
  ),

  // Add new Location
  addLocation: createAction(
    `[API] Add Location`,
    props<{ location: string, businessProfile: boolean }>()
  ),
  addLocationSuccess: createAction(
    `[API] Add Location Success`
  ),
  addLocationError: createAction(
    `[API] Add Location Error`
  ),

  // Remove a Feature
  removeLocation: createAction(
    `[API] Remove Location`,
    props<{ index: number, businessProfile: boolean }>()
  ),
  removeLocationSuccess: createAction(
    `[API] Remove Location Success`
  ),
  removeLocationError: createAction(
    `[API] Remove Location Error`
  ),



  // Add new portfolio
  addPortfolio: createAction(
    `[API] Add Portfolio`,
    props<{ files: File[], payload: any, businessProfile: boolean }>()
  ),
  addPortfolioSuccess: createAction(
    `[API] Add Portfolio Success`
  ),
  addPortfolioError: createAction(
    `[API] Add Portfolio Error`
  ),


  // Remove a portfolio
  removePortfolio: createAction(
    `[API] Remove Portfolio`,
    props<{ id: number, businessProfile: boolean }>()
  ),
  removePortfolioSuccess: createAction(
    `[API] Remove Portfolio Success`
  ),
  removePortfolioError: createAction(
    `[API] Remove Portfolio Error`
  ),


  // Update info from portfolio
  updatePortfolioInfo: createAction(
    `[API] Update Portfolio Info`,
    props<{ id: number, payload: any, businessProfile: boolean }>()
  ),
  updatePortfolioInfoSuccess: createAction(
    `[API] Update Portfolio Info Success`
  ),
  updatePortfolioInfoError: createAction(
    `[API] Update Portfolio Info Error`
  ),


  // Update a picture from portfolio
  updatePortfolioPicture: createAction(
    `[API] Update Portfolio Picture`,
    props<{ sort: number, file: File, image: PortfolioImage, portfolio: Portfolio, businessProfile: boolean  }>()
  ),
  updatePortfolioPictureSuccess: createAction(
    `[API] Update Portfolio Picture Success`
  ),
  updatePortfolioPictureError: createAction(
    `[API] Update Portfolio Picture Error`
  ),


  // Add Academic Formation
  addAcademicFormation: createAction(
    `[API] Add Academic Formation`,
    props<{ item: AcademicInformation, businessProfile: boolean }>()
  ),
  addAcademicFormationSuccess: createAction(
    `[API] Add Academic Formation Success`
  ),
  addAcademicFormationError: createAction(
    `[API] Add Academic Formation Error`
  ),


  // Update Academic Formation
  updateAcademicFormation: createAction(
    `[API] Update Academic Formation`,
    props<{ id: number, item: AcademicInformation, businessProfile: boolean }>()
  ),
  updateAcademicFormationSuccess: createAction(
    `[API] Update Academic Formation Success`
  ),
  updateAcademicFormationError: createAction(
    `[API] Update Academic Formation Error`
  ),

  // Remove Academic Formation
  removeAcademicFormation: createAction(
    `[API] Remove Academic Formation`,
    props<{ id: number, businessProfile: boolean }>()
  ),
  removeAcademicFormationSuccess: createAction(
    `[API] Remove Academic Formation Success`
  ),
  removeAcademicFormationError: createAction(
    `[API] Remove Academic Formation Error`
  ),


  // Add Experience
  addExperience: createAction(
    `[API] Add Experience`,
    props<{ experience: Experience, businessProfile: boolean }>()
  ),
  addExperienceSuccess: createAction(
    `[API] Add Experience Success`
  ),
  addExperienceError: createAction(
    `[API] Add Experience Error`
  ),


  // Update Experience
  updateExperience: createAction(
    `[API] Update Experience`,
    props<{ id: number, experience: Experience, businessProfile: boolean }>()
  ),
  updateExperienceSuccess: createAction(
    `[API] Update Experience Success`
  ),
  updateExperienceError: createAction(
    `[API] Update Experience Error`
  ),

  // Remove Experience
  removeExperience: createAction(
    `[API] Remove Experience`,
    props<{ id: number, businessProfile: boolean }>()
  ),
  removeExperienceSuccess: createAction(
    `[API] Remove Experience Success`
  ),
  removeExperienceError: createAction(
    `[API] Remove Experience Error`
  ),


  // Add Recommendation
  addRecommendation: createAction(
    `[API] Add Recommendation`,
    props<{ recommendation: Recommendation, businessProfile: boolean }>()
  ),
  addRecommendationSuccess: createAction(
    `[API] Add Recommendation Success`
  ),
  addRecommendationError: createAction(
    `[API] Add Recommendation Error`
  ),


  // Update Recommendation
  updateRecommendation: createAction(
    `[API] Update Recommendation`,
    props<{ id: number, recommendation: Recommendation, businessProfile: boolean }>()
  ),
  updateRecommendationSuccess: createAction(
    `[API] Update Recommendation Success`
  ),
  updateRecommendationError: createAction(
    `[API] Update Recommendation Error`
  ),


  // Remove Recommendation
  removeRecommendation: createAction(
    `[API] Remove Recommendation`,
    props<{ id: number, businessProfile: boolean }>()
  ),
  removeRecommendationSuccess: createAction(
    `[API] Remove Recommendation Success`
  ),
  removeRecommendationError: createAction(
    `[API] Remove Recommendation Error`
  ),

  // Remove References
removeReferences: createAction(
  `[API] Remove References`,
  props<{ id: number, businessProfile: boolean }>()
),
removeReferencesSuccess: createAction(
  `[API] Remove References Success`
),
removeReferencesError: createAction(
  `[API] Remove References Error`
),



  // Add references
  addReferences: createAction(
    `[API] Add References`,
    props<{ references: References, businessProfile: boolean }>()
  ),
  addReferencesSuccess: createAction(
    `[API] Add References Success`
  ),
  addReferencesError: createAction(
    `[API] Add References Error`
  ),

  // Update References
  updateReferences: createAction(
    `[API] Update References`,
    props<{ id: number, references: References, businessProfile: boolean }>()
  ),
  updateReferencesSuccess: createAction(
    `[API] Update References Success`
  ),
  updateReferencesError: createAction(
    `[API] Update References Error`
  ),



}
