import { Component, OnInit, OnDestroy, TemplateRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { LoggedService } from '../../logged/logged.service';
import { Subject } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {


  recoverForm: UntypedFormGroup;
  loading = false;
  killall = new Subject();
  modalRef: BsModalRef


  constructor( private modalService: BsModalService,
                 private loggedService: LoggedService,
                private route: ActivatedRoute,
                private fb: UntypedFormBuilder,
                private api: ApiService) { }


  ngOnInit() {
    this.initRecoveryForm()
  }

  ngOnDestroy() {
    this.killall.next();
    this.killall.complete();
  }

  initRecoveryForm() {
    this.recoverForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
    },{validator: this.passwordConfirming});
  }

  passwordConfirming(pass: AbstractControl): { invalid: boolean } {
    if (pass.get('password').value !== pass.get('confirmPassword').value) {
        return {invalid: true};
    }
  }

  recoverPass(template: TemplateRef<any>) {
    if (this.recoverForm.valid) {
      this.loading = true;

      this.route.paramMap.pipe(
        map(params => params.get('id')),
        switchMap(id => this.api.recoverPass(id,this.recoverForm.get('password').value)),
        take(1),
        takeUntil(this.killall),
      ).subscribe({
        next: (response) => {
          console.log(response);
          this.modalRef = this.modalService.show(
            template,
            { class: 'modal-dialog-centered modal-xl' }
          )
          this.loading = false;
        },
        error: error => {
          this.loading = false;
          console.log(error);

          this.loggedService.showError(error.error);
        }
      });
    } else {

      this.loggedService.showError('Please verify the data is correct');
    }
  }

}
