<div class="date-container">
  <div class="main-title">Selecciona la fecha de inicio de tu campaña:</div>
  <div class="date-container-title">
    <div (click)="previous()"><</div>
    <div (click)="goToday()">{{getMonthName(currentMonth)}} {{currentYear}}</div>
    <div (click)="next()">></div>
  </div>
  <div class="date-container-body">
    <div class="date-days">
      <div>{{'date-time-picker.sunday' | translate }}</div>
      <div>{{'date-time-picker.monday' | translate }}</div>
      <div>{{'date-time-picker.tuesday' | translate }}</div>
      <div>{{'date-time-picker.wednesday' | translate }}</div>
      <div>{{'date-time-picker.thursday' | translate }}</div>
      <div>{{'date-time-picker.friday' | translate }}</div>
      <div>{{'date-time-picker.saturday' | translate }}</div>
    </div>
    <div class="date-numbers" *ngFor="let week of weeks">
      <div class="{{weekday.taken ? 'taken' : ''}} {{weekday.time}} {{weekday.partial ? 'partial' : ''}}  {{weekday.today ? 'today' : ''}} {{weekday.inactive ? 'inactive' : ''}} {{weekday.active ? 'active' : ''}}" *ngFor="let weekday of week" (click)="active(weekday)">{{weekday.day}}</div>
    </div>
  </div>
</div>
