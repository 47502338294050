<div class="gradient">
    <!-- ! NavBar -->
    <!-- * Baner -->
    <div class="banner about" id="banner" [ngClass]="{'nav': openSpan}">
        <div class="navbar">
            <a [routerLink]="'/'"><img class="logo" src="/assets/img/logos/Logo_Adstop.png" alt="Adstop"></a>

            <ul class="nav justify-content-end">
                <!--<li class="nav-item">
                    <a class="nav-link" (click)="switchLanguage()">{{ 'ajustes.boton-lenguaje' | translate }}</a>
                </li>-->
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="'/about'">{{'footer.tools' | translate }}</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" [routerLink]="'/register-space'">{{'footer.register-space' | translate }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link log-in" [routerLink]="'/login'">{{'footer.login' | translate }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link register" [routerLink]="'/register'">{{'footer.register' | translate }}</a>
                </li>
            </ul>
            <div (click)="openSpan = !openSpan" [ngClass]="{'open': openSpan}" id="nav-icon"><span></span> <span></span> <span></span></div>
        </div>
        <nav class="navbar" id="mobilenav" [ngClass]="{'active': openSpan}">
            <ul>
                <!--<li class="nav-item">
                    <a class="nav-link" (click)="switchLanguage()">{{ 'ajustes.boton-lenguaje' | translate }}</a>
                </li>-->
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="'/about'">{{'footer.tools' | translate }}</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" [routerLink]="'/register-space'">{{'footer.register-space' | translate }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link log-in" [routerLink]="'/login'">{{'footer.login' | translate }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link register" [routerLink]="'/register'">{{'footer.register' | translate }}</a>
                </li>
            </ul>
        </nav>
        <!-- ! Container del Video en inglés -->
        <ng-container *ngIf="language === 'en'; else espVideo">
            <div class="main-container desk" [ngClass]="{'nav': openSpan}">
                <div class="reserva">
                    <p>{{'landing.about.bauns' | translate }}</p>
                    <p>{{'landing.about.bauns-2' | translate }}</p>
                    <button [routerLink]="'/register'" class=" btn-link">{{'landing.home.register' | translate }}</button>
                </div>
                <div class="video">
                    <video width="100%" height="auto" controls onloadedmetadata="this.muted = true">
              <source src="/assets/img/landing/bauns_en.mp4" type="video/mp4">
            </video>
                </div>
            </div>
            <div class="main-container mobile" [ngClass]="{'nav': openSpan}">
                <div class="reserva">
                    <p>{{'landing.about.bauns' | translate }}</p>
                    <p>{{'landing.about.bauns-2' | translate }}</p>
                </div>
                <video width="50%" height="auto" controls onloadedmetadata="this.muted = true">
          <source src="/assets/img/landing/bauns_en.mp4" type="video/mp4">
        </video>
                <button [routerLink]="'/register'" class=" btn-link">{{'landing.home.register' | translate }}</button>
            </div>
        </ng-container>

        <!-- ! Container del Video en español -->
        <ng-template #espVideo>
            <div class="main-container desk" [ngClass]="{'nav': openSpan}">
                <div class="reserva">
                    <p>{{'landing.about.bauns' | translate }}</p>
                    <p>{{'landing.about.bauns-2' | translate }}</p>
                    <button [routerLink]="'/register'" class=" btn-link">{{'landing.home.register' | translate }}</button>
                </div>
                <div class="tab-img-about">
                    <img class="animate__animated animate__fadeInRight animate__slow" src="/assets/img/landing/nosotros.png" alt="">
                    <button [routerLink]="'/register'" class=" btn-link mobile">{{'landing.home.register' | translate }}</button>
                </div>
            </div>
            <!--<div class="main-container mobile" [ngClass]="{'nav': openSpan}">
                <div class="reserva">
                    <p>{{'landing.about.bauns' | translate }}</p>
                    <p>{{'landing.about.bauns-2' | translate }}</p>
                </div>
                <video width="50%" height="auto" controls onloadedmetadata="this.muted = true">
        <source src="/assets/img/landing/bauns_es.mp4" type="video/mp4">
      </video>
                <button [routerLink]="'/register'" class=" btn-link">{{'landing.home.register' | translate }}</button>
            </div>-->
        </ng-template>

        <!-- ! Tabset -->
        <tabset class="tab-about">
            <!-- * Community -->
            <tab heading="{{'landing.about.community' | translate }}">
                <div class="comunidad">
                    <div class="comunidad-text">
                        <h3>{{'landing.about.tab-community.title' | translate }}</h3>
                        <p>{{'landing.about.tab-community.description' | translate }}</p>
                        <ul>
                            <li><span>-</span>
                                <p>{{'landing.about.tab-community.p-1' | translate }}</p>
                            </li>
                            <li><span>-</span>
                                <p>{{'landing.about.tab-community.p-2' | translate }}</p>
                            </li>
                            <li><span>-</span>
                                <p>{{'landing.about.tab-community.p-3' | translate }}</p>
                            </li>
                            <li><span>-</span>
                                <p>{{'landing.about.tab-community.p-4' | translate }}</p>
                            </li>
                            <li><span>-</span>
                                <p>{{'landing.about.tab-community.p-5' | translate }}</p>
                            </li>
                        </ul>
                        <button [routerLink]="'/register'" class=" btn-link desk">{{'landing.home.register' | translate }}</button>
                    </div>
                    <div class="tab-img">
                        <img class="animate__animated animate__fadeInRight animate__slow" src="/assets/img/landing/comunidadx2.png" alt="">
                        <button [routerLink]="'/register'" class=" btn-link mobile">{{'landing.home.register' | translate }}</button>
                    </div>
                </div>
            </tab>
            <!-- * Spaces -->

            <tab heading="{{'landing.about.spaces' | translate }}">
                <div class="espacios">
                    <div class="tab-img">
                        <img class="animate__animated animate__fadeInLeft animate__slow" src="/assets/img/landing/espaciox2.png" alt="">
                        <button [routerLink]="'/register'" class=" btn-link mobile">{{'landing.home.register' | translate }}</button>
                    </div>
                    <div class="espacios-text">
                        <h3>{{'landing.about.tab-spaces.title' | translate }}</h3>
                        <p>{{'landing.about.tab-spaces.description' | translate }}</p>
                        <ul>
                            <li><span>-</span>
                                <p>{{'landing.about.tab-spaces.p-1' | translate }}</p>
                            </li>
                            <li><span>-</span>
                                <p>{{'landing.about.tab-spaces.p-2' | translate }}</p>
                            </li>
                            <li><span>-</span>
                                <p>{{'landing.about.tab-spaces.p-3' | translate }}</p>
                            </li>
                            <li><span>-</span>
                                <p>{{'landing.about.tab-spaces.p-4' | translate }}</p>
                            </li>
                            <li><span>-</span>
                                <p>{{'landing.about.tab-spaces.p-5' | translate }}</p>
                            </li>
                        </ul>
                        <button [routerLink]="'/register'" class=" btn-link desk">{{'landing.home.register' | translate }}</button>
                    </div>
                </div>
            </tab>
            
            <!-- * Work -->
            <!-- <tab heading="{{'landing.about.work' | translate }}">
      <div class="trabajo">
        <div class="tab-img">
          <img class="animate__animated animate__fadeInLeft animate__slow" src="/assets/img/landing/trabajox2.png" alt="">
          <h3 class="red mobile" >{{'landing.about.tab-work.p-8' | translate }}</h3>
        </div>
        <div class="trabajo-text">
          <h3>{{'landing.about.tab-work.title' | translate }}</h3>
          <p>{{'landing.about.tab-work.description' | translate }}</p>
            <ul>
              <li><span>-</span> <p>{{'landing.about.tab-work.p-1' | translate }}</p> </li>
              <li><span>-</span> <p>{{'landing.about.tab-work.p-2' | translate }}</p> </li>
              <li><span>-</span> <p>{{'landing.about.tab-work.p-3' | translate }}</p></li>
              <li><span>-</span> <p>{{'landing.about.tab-work.p-4' | translate }}</p></li>
              <li><span>-</span> <p>{{'landing.about.tab-work.p-5' | translate }}</p></li>
              <li><span>-</span> <p>{{'landing.about.tab-work.p-6' | translate }}</p></li>
              <li><span>-</span> <p>{{'landing.about.tab-work.p-7' | translate }}</p></li>
            </ul>
          <h3 class="red desk" >{{'landing.about.tab-work.p-8' | translate }}</h3>
        </div>
      </div>
    </tab> -->
        </tabset>

    </div>




    <!-- ! Footer -->
    <app-footer></app-footer>
</div>
