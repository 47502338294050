<!-- * Checkout -->
<h4 class="mb-5">{{'checkout.title' | translate }}</h4>
<app-spinner *ngIf="loadingCheckout; else checkoutLoaded"></app-spinner>
<ng-template #checkoutLoaded>

    <!-- Booking Detail -->
    <div class="bookings jumbo-mask mb-4">
        <div class="logo_container">
            <img [src]="apiUrl+workspace.coworkingChild.profilePicture" alt="">
        </div>
        <div class="img_container">
            <img [src]="loggedService.getUrl(workspace.profilePicture)" alt="">
        </div>
        <div class="description_container">
            <div>
                <div class="text_container">
                    <div class="text">
                        <span class="text-bold">{{workspace.name}} - </span><span>{{workspace.coworkingChild.name}}</span>
                    </div>
                    <div class="text">{{language === 'en' ? workspace.type : workspace.type_es}}</div>
                    <!-- Booking Data -->
                    <!-- <ng-container *ngIf="quickModel$ | async as quickModel">
                        <div class="text" *ngIf="quickModel.date">{{quickModel.date}}</div>
                        <div class="text" *ngIf="quickModel.time">{{quickModel.time}}</div>
                    </ng-container> -->
                </div>
                <div class="text_container">
                    <div class="text">
                        <span class="light">{{workspace.location}}</span>
                    </div>
                    <ng-container *ngIf="quickModel$ | async as quickModel">
                        <div class="text" *ngIf="quickModel.date"><span class="light">{{quickModel.date}}</span></div>
                        <div class="text" *ngIf="quickModel.time"><span class="light">{{quickModel.time}}</span></div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>



    <!-- ? Event name -->
    <div class="mb-3">
        <label class="form-label simon">{{'checkout.event-name' | translate }} (Obligatorio)</label>
        <input (input)="onInput($event.target.value)" name="eventName" [formControl]="eventNameControl" class="jumbo-mask" type="text" placeholder="{{'checkout.event-name-placeholder' | translate }}">
    </div>


    <!-- * Use Promo Code -->
    <div class="mb-3" *ngIf="canUseCode">
        <label class="form-label semi-bold">{{'checkout.promo-code' | translate }}</label>
        <input [formControl]="promoCode" type="text" placeholder="{{'checkout.promo-code' | translate }}" class="jumbo-mask mb-3">
        <button class="btn btn-woke-action gray jumbo-mask mb-4" (click)="onApply()">

      <span *ngIf="!applying;else loader">{{'checkout.apply-code' | translate }}</span>
      <ng-template #loader>
        <div class="spinner-grow spinner-grow-sm" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </ng-template>
    </button>
    </div>

    <!-- * Use Office Switch -->
    <div class="d-flex justify-content-between mb-3">
        <div>
            <div class="semi-bold">{{'checkout.use-office' | translate }}</div>
            <div class="gray">{{'checkout.use-office-description' | translate }}</div>
        </div>
        <ui-switch (ngModelChange)="modelChange($event)" [disabled]="switchPlanDisable" defaultBgColor="#f5f5f5" size="small" color="#5ED2AA" [(ngModel)]="useBusinessPlan"></ui-switch>
    </div>

    <div class="tags">
        <app-woke-tag class="tag mr-3 mb-3" (delete)="removeCode(i)" *ngFor="let s of codes; let i = index" [editable]="true">{{s}}</app-woke-tag>
    </div>

    <!-- * Pay With money -->
    <ng-container *ngIf="!useBusinessPlan; else payWithOffice">
        <!-- Downpayment -->
        <div class="my-5" *ngIf="(bookingInfo$ | async) as bookingInfo">
            <ng-container *ngIf="bookingInfo.amount && bookingInfo.type === 'Other'">
                <div *ngIf="!validCode" class="d-flex justify-content-between mb-3">
                    <div class="gray">Subtotal</div>
                    <div>${{bookingInfo.amount.subTotal}}</div>
                </div>
                <!-- * Descuento -->
                <div *ngIf="validCode" class="d-flex justify-content-between mb-3">
                    <div class="gray">Subtotal</div>
                    <div>${{copyBookingInfo.amount.subTotal}}</div>
                </div>
                <div *ngIf="validCode" class="d-flex justify-content-between mb-3">
                    <div class="gray">{{'checkout.promo-code' | translate }}</div>
                    <div>-${{bookingInfo.previous}}</div>
                </div>

                <div class="d-flex justify-content-between mb-3">
                    <div class="gray">Payment Fee</div>
                    <div>${{bookingInfo.amount.paymentFee}}</div>
                </div>
                <div class="d-flex justify-content-between mb-5">
                    <div class="gray">Tax</div>
                    <div>${{bookingInfo.amount.tax}}</div>
                </div>
                <div class="d-flex justify-content-between">
                    <h4 class="red semi-bold">Total</h4>
                    <h4 class="red semi-bold">${{bookingInfo.amount.total}}</h4>
                </div>
            </ng-container>
            <ng-container *ngIf="bookingInfo.cost">
                <div *ngIf="!validCode" class="d-flex justify-content-between mb-3">
                    <div class="gray">Subtotal</div>
                    <div>${{bookingInfo.cost.subTotal}}</div>
                </div>
                <!-- * Descuento -->
                <div *ngIf="validCode" class="d-flex justify-content-between mb-3">
                    <div class="gray">Subtotal</div>
                    <div>${{copyBookingInfo.cost.subTotal}}</div>
                </div>
                <div *ngIf="validCode" class="d-flex justify-content-between mb-3">
                    <div class="gray">{{'checkout.promo-code' | translate }}</div>
                    <div>-${{bookingInfo.previous}}</div>
                </div>
                <div class="d-flex justify-content-between mb-3">
                    <!-- * Payment fee -->
                    <div class="gray">{{'checkout.fee' | translate }}</div>
                    <div *ngIf="paymentMethod?.id == 'spei'">${{bookingInfo.cost.speiPaymentFee}}</div>
                    <div *ngIf="paymentMethod?.id != 'spei'">${{bookingInfo.cost.paymentFee}}</div>
                </div>
                <div class="d-flex justify-content-between mb-5">
                    <!-- * Tax -->
                    <div class="gray">{{'checkout.tax' | translate }}</div>
                    <div *ngIf="paymentMethod?.id == 'spei'">${{bookingInfo.cost.speiTax}}</div>
                    <div *ngIf="paymentMethod?.id != 'spei'">${{bookingInfo.cost.tax}}</div>
                </div>
                <div class="d-flex justify-content-between">
                    <h4 class="red semi-bold">Total</h4>
                    <h4 class="red semi-bold" *ngIf="paymentMethod?.id == 'spei'">${{bookingInfo.cost.speiTotal}}</h4>
                    <h4 class="red semi-bold" *ngIf="paymentMethod?.id != 'spei'">${{bookingInfo.cost.total}}</h4>
                </div>
            </ng-container>


        </div>

        <!-- Payment Methods -->
        <app-default-payment-method (paymentMethodChange)="onPaymentMethodChange($event)"></app-default-payment-method>


        <!-- Invoice -->
        <app-select-invoice (cfdiChange)="onCfdiChange($event)" (invoiceChange)="onInvoiceChange($event)"></app-select-invoice>
    </ng-container>




    <!-- * Pay with office or license -->

    <ng-template #payWithOffice>
        <!-- Select office -->
        <div class="form-group">
            <label class="form-label semi-bold">{{'checkout.office' | translate }}</label>
            <app-woke-select placeholder="{{'checkout.select-office' | translate }}" [formControl]="officeControl" [options]="offices" valueAccesor="id">
                <ng-template #item let-option="option">
                    {{option.name}}
                </ng-template>
                <ng-template #selected let-value="value">
                    {{value.name }}
                </ng-template>
            </app-woke-select>
        </div>

        <!-- * Total Office credits -->
        <div class="mb-3" *ngIf="officeTotal && officeControl.value">
            <label class="form-label semi-bold">Total</label>
            <div class="jumbo-mask option-selected">{{officeTotal.text}}</div>
        </div>
    </ng-template>



    <!-- * Invites -->


    <!-- * Terms and Conditions -->

    <div *ngIf="terms && terms !== null" class="radio_container mb-3" [ngClass]="checkTerms ? 'active' : ''">
        <div (click)="changeTerms()" class="radio_control"></div>
        <div *ngIf="language === 'en'" class="radio_text">
            <span class="terms">{{'checkout.agree-terms' | translate }} {{workspace.coworkingChild.name}} </span>
            <span class="terms-red" (click)="showTerms()">{{'footer.terms' | translate }}</span>
        </div>
        <div *ngIf="language === 'es'" class="radio_text">
            <span class="terms">
              {{'checkout.agree-terms' | translate }}
            </span>
            <span class="terms-red" (click)="showTerms()">{{'footer.terms' | translate }}</span>
            <!-- <span class="terms">{{'checkout.of' | translate }}
                {{workspace.coworkingChild.name}}
              </span> -->
        </div>
    </div>

    <div *ngIf="paymentMethod?.id == 'spei'; then buttonSpei; else buttonConfirm"></div>


    <ng-template #buttonSpei>
        <!-- * Button Spei -->
        <button [disabled]="!confirmar" class="btn btn-red btn-action paySpei" type="button" (click)="useBusinessPlan ? confirm(modalRequest):confirm(spei)">
            <span *ngIf="!paying;else loader">{{'checkout.confirm' | translate }}</span>
            <ng-template #loader>
            <div class="spinner-grow spinner-grow-sm" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            </ng-template>
        </button>
    </ng-template>

    <ng-template #buttonConfirm>
        <!-- * Button Confirm -->
        <button [disabled]="!confirmar"  class="btn btn-red btn-action payCard" type="button" (click)="confirm(modalRequest)">
            <span *ngIf="!paying;else loader">{{'checkout.confirm' | translate }}</span>
            <ng-template #loader>
            <div class="spinner-grow spinner-grow-sm" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            </ng-template>
        </button>
    </ng-template>

</ng-template>


<ng-template #spei style="max-width:100% !important">
    <div class="modal-header">
        <!-- * Payment successful -->
        <h5 class="modal-title">Pago Pendiente</h5>
    </div>
    <div class="modal-body {{url}}">
        <iframe [src]="url"  width="100%" height="700"></iframe>
    </div>
    <div class="modal-footer">
        <button (click)="goToCampaigns()" type="button" class="btn btn-link red" data-dismiss="modal">Ok</button>
    </div>
</ng-template>

<ng-template #modalRequest>
    <div class="modal-header">
        <!-- * Payment successful -->
        <h5 class="modal-title">{{'checkout.modal-success.title' | translate }}</h5>
    </div>
    <div class="modal-body">
        <p class="text-center">{{'checkout.modal-success.body' | translate }}</p>
    </div>
    <div class="modal-footer">
        <button (click)="goToCampaigns()" type="button" class="btn btn-link red" data-dismiss="modal">Ok</button>
    </div>
</ng-template>
