import { Component, OnInit, TemplateRef } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApiService } from 'src/app/api.service';
import { Location } from '@angular/common';
import { ModalsService } from 'src/app/shared/modals/modals.service'
import { auditTime, filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TokenFacade } from 'src/store/token/token.facade';

const LETTERS = /^[a-zA-ZñáéíóúÑÁÉÍÓÚ]*$/;

@Component({
  selector: 'app-space-register',
  templateUrl: './space-register.component.html',
  styleUrls: ['./space-register.component.scss']
})
export class SpaceRegisterComponent implements OnInit {

  spaceForm: UntypedFormGroup
  loading: boolean = false
  modalRef: BsModalRef
  content: string
  title: string
  contactSpace: boolean
  killall = new Subject()
  errorMessage: string = ''
  env: string = ''
  language: string;
  checkTerms: boolean = false;


  constructor(private apiService: ApiService,
    private modalService: BsModalService,
    private location: Location,
    private wokeService: ModalsService,
    public form: UntypedFormBuilder,
    private tokenFacade: TokenFacade,
    private router: Router) { }

  ngOnInit(): void {
    this.tokenFacade.language$.pipe(
      take(1),
      takeUntil(this.killall)
    ).subscribe({
      next: lan => {
        this.language = lan
      }
    })
    this.initForm();
    this.zipcodeChanges()
    this.env = environment.api.includes('bauns-dev') ? 'stage' : 'prod'
  }

  ngOnDestroy() {
    this.modalService.hide()
    document.querySelector('body').classList.remove('modal-open');
  }

  initForm() {
    this.spaceForm = this.form.group({
      firstName: this.form.control('', [Validators.required, Validators.pattern(LETTERS)]),
      lastName: this.form.control('', [Validators.required, Validators.pattern(LETTERS)]),
      username: this.form.control('', [Validators.required]),
      email: this.form.control('', [Validators.required, Validators.email]),
      password: this.form.control('', [Validators.required, Validators.minLength(8)]),
      confirmPassword: this.form.control('', [Validators.required, Validators.minLength(8)]),
      coreName: this.form.control('', [Validators.required]),
      coreDescription: this.form.control('', [Validators.required]),
      branchName: this.form.control('', [Validators.required]),
      branchDescription: this.form.control('', [Validators.required]),
      street: this.form.control('', [Validators.required]),
      interiorNumber: this.form.control(''),
      exteriorNumber: this.form.control('', [Validators.required]),
      zipCode: this.form.control('', [Validators.required]),
      municipality: this.form.control('', [Validators.required]),
      state: this.form.control('', [Validators.required]),
      country: this.form.control('', [Validators.required]),
      neighborhood: this.form.control('', [Validators.required])
    }, { validator: this.passwordConfirming });
  }

  zipcodeChanges() {
    this.spaceForm.get('zipCode').valueChanges.pipe(
      filter(zip => zip !== null),
      auditTime(600),
      switchMap(zip => this.apiService.getAddress(zip)),
      takeUntil(this.killall)
    ).subscribe({
      next: data => {
        console.log(data);
        this.spaceForm.get('country').patchValue(data.country)
        this.spaceForm.get('municipality').patchValue(data.municipality)
        this.spaceForm.get('neighborhood').patchValue(data.neighborhood)
        this.spaceForm.get('state').patchValue(data.state)
      }
    })
  }

  passwordConfirming(pass: AbstractControl): { invalid: boolean } {
    if (pass.get('password').value !== pass.get('confirmPassword').value) {
      return { invalid: true };
    }
  }

  goBack() {
    this.location.back()
  }
  changeTerms(value) {
    this.checkTerms = !this.checkTerms;
    console.log(this.checkTerms);
  }

  contact(template: TemplateRef<any>, templateErr: TemplateRef<any>) {
    if (!this.loading) {
      this.loading = true;
      this.apiService.registerSpace(this.spaceForm.value).subscribe({
        next: data => {
          console.log(data);
          this.loading = false;
          this.modalRef = this.modalService.show(
            template,
            { class: 'modal-dialog-centered modal-xl' }
          )
        },
        error: error => {
          this.errorMessage = error.error.error;
          this.modalRef = this.modalService.show(
            templateErr,
            { class: 'modal-dialog-centered modal-xl' }
          )
          console.warn(error);
          this.loading = false
        }
      })
    }
  }

  showPolicies(temp) {
    this.wokeService.getPrivacy().subscribe({
      next: (response) => {
        this.content = response.html;
        this.title = this.language === 'en' ? "Privacy Policy" : "Política de Privacidad"
        if (this.content !== "") {
          this.openTagModal(temp)
        }
      },
      error: (err) => {
        console.log(err);
      }
    })
  }

  showTerms(temp) {
    this.wokeService.getTerms().subscribe({
      next: (response) => {
        this.content = response.html;
        this.title = this.language === 'en' ? "Terms and Conditions" : "Términos y Condiciones"
        if (this.content !== "") {
          this.openTagModal(temp)
        }
      },
      error: (err) => {
        console.log(err);
      }
    })

  }

  openTagModal(tagModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(tagModal, { class: 'modal-dialog-centered modal-xl modal-privacy' })
  }

  closeModal() {
    if (this.env === 'stage') {
      window.location.href = environment.resourceUrl + '/admin/login'
    } else {
      window.location.href = environment.resourceUrl + '/admin/login'

    }
    this.modalService.hide();
    document.querySelector('body').classList.remove('modal-open');
  }

}
