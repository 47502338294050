import { Component, OnInit, OnDestroy, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalsService } from 'src/app/shared/modals/modals.service'
import { takeUntil, take } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-recover-lite',
  templateUrl: './recover-lite.component.html',
  styleUrls: ['./recover-lite.component.scss']
})
export class RecoverLiteComponent implements OnInit, OnDestroy {

  forgotForm: UntypedFormGroup;
  loading: boolean = false;
  submittedForm: boolean = false;
  notFound:boolean
  modalRef: BsModalRef
  killall = new Subject()

  constructor(public form: UntypedFormBuilder, private service: ModalsService, private modalService: BsModalService) {
    this.forgotForm = this.form.group({
      email: form.control('', [Validators.required, Validators.email])
    });
  }

  ngOnInit() {}

  ngOnDestroy(){
    this.killall.next();
    this.killall.complete();
  }


  closeModal() {
    this.modalService.hide();
    document.querySelector('body').classList.remove('modal-open');
  }

  openModal(tagModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(tagModal, { class: 'modal-dialog-centered' })
  }

  recoverPass(tagModal: TemplateRef<any>) {
    if(this.forgotForm.valid) {
      console.log(this.forgotForm.get('email').value);

      this.loading = true;
      this.service.forgotPassword(this.forgotForm.get('email').value).pipe(
        take(1),
        takeUntil(this.killall)
      ).subscribe({
        next: response =>{
          console.log(response);
          this.loading = false;
          this.notFound = false;
          this.submittedForm = true;
          this.openModal(tagModal)

        },
        error: err =>{
          console.log(err);
          this.notFound = true;
          this.loading = false;
          this.openModal(tagModal)
        }
      })
    }
  }

}
