<!-- * Checkout Network -->
<h4 class="mb-5">{{'checkout.title' | translate }}</h4>

<app-spinner *ngIf="loadingCheckout; else checkoutTemplate"></app-spinner>
<ng-template #checkoutTemplate>

  <!-- Payment Info -->
  <div *ngIf="info" class="mb-5">
    <!-- * Pago por -->
    <h6 class="semi-bold mb-4">{{'checkout.payment-for' | translate }} {{info.event}}</h6>

    <div class="d-flex justify-content-between mb-4">
      <div class="gray">Subtotal</div>
      <div>${{info.subtotal}}</div>
    </div>

    <div class="d-flex justify-content-between mb-4">
      <!-- * Fee -->
      <div class="gray">{{'checkout.fee' | translate }}</div>
      <div>${{info.paymentFee}}</div>
    </div>

    <div *ngIf="info.tax !== '0.00'" class="d-flex justify-content-between mb-4">
      <!-- * Tax -->
      <div class="gray">{{'checkout.tax' | translate }}</div>
      <div>${{info.tax}}</div>
    </div>

    <div class="d-flex justify-content-between">
      <h4 class="red semi-bold">Total</h4>
      <h4 class="red semi-bold">${{info.total}}</h4>
    </div>
  </div>



  <!-- Payment Methods -->
  <app-default-payment-method (paymentMethodChange)="onPaymentMethodChange($event)"></app-default-payment-method>

  <!-- Invoice -->
  <app-select-invoice (cfdiChange)="onCfdiChange($event)" (invoiceChange)="onInvoiceChange($event)"></app-select-invoice>

  <!-- Button Confirm -->
  <button class="btn btn-red btn-action" type="button" (click)="confirm(modalRequest)">
    <!-- * Confirm -->
    <span *ngIf="!loadingConfirm;else loaderConfirm">{{'checkout.confirm' | translate }}</span>
    <ng-template #loaderConfirm>
      <div class="spinner-grow spinner-grow-sm" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </ng-template>
  </button>


</ng-template>

<ng-template #modalRequest>
  <div class="modal-header">
    <!-- * Payment successful -->
    <h5 class="modal-title">{{'network.add-tickets.modal-success.title' | translate }}</h5>
  </div>
  <div class="modal-body">
    <p class="text-center">{{'network.add-tickets.modal-success.body' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button (click)="goToWorkspace()" type="button" class="btn btn-link red" data-dismiss="modal">Ok</button>
  </div>
</ng-template>
