<app-woke-card [routerLink]="'/workspace/booking/' + workspace.id">

  <div class="logo_container" [routerLink]="'/workspace/child/' + workspace.coworkingChild.id">
    <img [src]="loggedService.getUrl(workspace.coworkingChild.profilePicture)" alt="">
  </div>

  <div class="img_container">
    <img [src]="loggedService.getUrl(workspace.workspace.profilePicture)" alt="">
  </div>
  <div class="description_container">
    <div>
      <div class="text_container">
        <div class="text">
          <span class="text-bold">{{workspace.workspace.name}} - </span><span>{{workspace.coworkingChild.name}}</span>
        </div>
        <div class="text">{{workspace.workspace.type}}</div>
      </div>

      <div class="text_container">
        <div class="text">
          <span class="light">{{workspace.workspace.location}}</span>
        </div>
        <div class="text">
          <span class="light">{{workspace.startAt}} {{workspace.startTimeAt}} - {{workspace.endTimeAt}}</span>
        </div>
        <div class="qr_icon"></div>
      </div>
    </div>

  </div>

  <div class="description_container">
    <ng-container *ngIf="canBookAgain; else modifyTemplate">
      <div class="booking_button" [routerLink]="'/workspace/book/' + workspace.workspace.id" [ngClass]="{'dark': darkMode$ | async }">Book again</div>
    </ng-container>
    <ng-template #modifyTemplate>
      <ng-container *ngIf="workspace.isModifiable; else cantModify">
        <div class="booking_button" [routerLink]="'/workspace/modify-booking/' + workspace.id" [ngClass]="{'dark': darkMode$ | async }">Modify booking</div>
      </ng-container>
    </ng-template>
  </div>

</app-woke-card>
