import { QuickModel } from 'src/app/logged/workspaces/workspace/quick/quick.model';
import { PaymentMethod } from 'src/app/api.interfaces';
import { TokenFacade } from 'src/store/token/token.facade'
export class CheckoutWorkspaceViewModel {
  quickModel: QuickModel;
  promoCode: string;
  nameBooking: string;
  specialRequest: string;
  invites: string[];
  withMoney: 'yes' | 'no';
  office: number;
  license: number;
  invoice: 'yes' | 'no';
  total: {
    text?: string;
    remainingCredits?: string | number;
    credits_limit?: number;
  };

  paymentMethod: PaymentMethod;
  cfdi: number;

  constructor(payload: any) {
    this.quickModel = payload.quickModel;
    if (payload.q)
      console.log(this.quickModel);
    this.nameBooking = payload.nameBooking;
    this.specialRequest = payload.specialRequest;
    this.invites = payload.invites;
    this.withMoney = payload.useBusinessPlan === true ? 'no' : 'yes';
    this.invoice = payload.invoice === true ? 'yes' : 'no';
    this.paymentMethod = payload.paymentMethod;
    this.office = payload.isOffice ? payload.planId : null;
    this.license = payload.license ? null : payload.planId
    this.cfdi = payload.cfdi;
    this.promoCode = payload.promoCode || ''
    if (payload.total) {
      this.total = payload.total;
    }

  }

  isBooking() {
    if (this.quickModel && this.quickModel.bookingId) {
      return true;
    }
    return false;
  }

  getBookingId() {
    return this.quickModel.bookingId;
  }

  getError(creditsNeeded?, lang?) {
    if (this.withMoney === 'yes') {
      if (this.paymentMethod === undefined || !this.paymentMethod.id) {
        return lang === 'en' ? 'Please select a payment method' : 'Por favor seleccione un método de pago';
      }
    } else {
      if (!this.office && !this.license) {
        return lang === 'en' ? 'Please select your plan' : 'Por favor seleccione un plan';
      }
      if (this.license && !this.total.remainingCredits || this.office && !this.total.remainingCredits || this.office && creditsNeeded > this.total.credits_limit || this.license && creditsNeeded > this.total.credits_limit) {
        return lang === 'en' ? 'Please select a plan with available credits' : 'Por favor seleccione un plan con créditos disponibles';
      }
      // if (this.license && !this.total.creditLimit) {
      //   return 'Please select an license with available credits';
      // }
    }
    return undefined;
  }

  isValid(creditsNeeded?) {
    if (this.withMoney === 'yes') {
      if (this.paymentMethod === undefined || !this.paymentMethod.id) {
        return false;
      }
    } else {
      if (!this.office && !this.license) {
        return false;
      }
      if (this.license && !this.total.remainingCredits || this.office && !this.total.remainingCredits || this.office && creditsNeeded > this.total.credits_limit || this.license && creditsNeeded > this.total.credits_limit) {
        return false;
      }
      // if (this.license && !this.total.creditLimit) {
      //   return false;
      // }
    }
    return true;
  }

  getFormData() {
    const formData = new FormData();

    console.log("this.license", this.license);

    // switch (this.quickModel.type) {
    //   case 'Desk':
    //   case 'Espectacular':
    //     formData.append('startAt', this.quickModel.dateStart);
    //     formData.append('endAt', this.quickModel.dateEnd);
    //     if (this.office) {
    //       formData.append('office', `${this.office}`);
    //     }
    if (this.license) {
        formData.append('license', `${this.license}`);
    }
    //     break;
    //   case 'Meeting Room':
    //   case 'Other':
    //     formData.append('startAt', this.quickModel.dateStart);
    //     formData.append('endAt', this.quickModel.dateStart);
    //     formData.append('startTimeAt', this.quickModel.startTimeAt);
    //     formData.append('endTimeAt', this.quickModel.endTimeAt);
    //     if (this.office) {
    //       formData.append('office', `${this.office}`);
    //     }
    //     if (this.license) {
    //       formData.append('license', `${this.license}`);
    //     }
    //     break;
    //   default:
    //     break;
    // }

    formData.append('startAt', this.quickModel.dateStart);
    formData.append('endAt', this.quickModel.dateEnd);
    formData.append('startTimeAt', this.quickModel.startTimeAt);
    formData.append('endTimeAt', this.quickModel.endTimeAt);
    formData.append('timeSelected', `${this.quickModel.getTimeSelected()}`);
    formData.append('nameBooking', this.nameBooking);
    formData.append('specialRequest', this.specialRequest);
    formData.append('invites', this.invites.join());
    formData.append('withMoney', this.withMoney);
    if (this.promoCode !== '') {
      formData.append('code', this.promoCode)
    }
    formData.append('sourceId', this.paymentMethod.id);
    // console.log("getFormData", formData)
    // console.log("startTimeAt", formData.get('startTimeAt'));


    return formData;
  }

  getCheckoutFormData() {
    const formData = new FormData();
    if (this.withMoney === 'yes') {
      formData.append('source_id', this.paymentMethod.id);
    }
    if (this.office) {
      //formData.append('office', `${this.office}`);
    }
    if (this.license) {
      formData.append('license', `${this.license}`);
    }
    if (this.promoCode !== '') {
      formData.append('code', this.promoCode)
    }
    formData.append('invoice', this.invoice);
    formData.append('cdfi', `${this.cfdi}`);
    formData.append('withMoney', this.withMoney);
    formData.append('invites', this.invites.join());
    return formData;
  }


}
