<div class="d-flex justify-content-between mb-3">
    <!-- * Invoice check out -->
    <h6>{{'checkout.invoice' | translate }}</h6>
    <ui-switch size="small" defaultBgColor="#f5f5f5" color="#5ED2AA" [ngModel]="invoice" (ngModelChange)="onInvoiceChange($event)"></ui-switch>
</div>

<div *ngIf="invoice">
    <!-- Invoice data -->
    <ng-container *ngIf="billingInformation">

        <ng-container *ngIf="billingInformation.rfc; else fillBilling">
            <app-woke-select placeholder="{{'checkout.use-of-cfdi' | translate }}" [formControl]="useOfCfdi" bg="white" [options]="cfdis" valueAccesor="id">
                <ng-template #item let-option="option">
                    <div class="d-flex align-items-center">
                        {{option.code}} - {{option.name}}
                    </div>
                </ng-template>
                <ng-template #selected let-value="value">
                    <div class="d-flex align-items-center">
                        {{value.code}} - {{value.name}}
                    </div>
                </ng-template>
            </app-woke-select>

            <div class="jumbo-mask mb-5 mt-3">
                <div class="mb-3 d-flex justify-content-between">
                    <div>
                        <!-- ? Full Name -->
                        <div class="gray">{{'checkout.full-name' | translate }}</div>
                        <div> {{billingInformation.fullName}}</div>
                    </div>
                    <!-- ? change -->
                    <div class="red pointer" [routerLink]="'/wallet/billing-information'">{{'checkout.change' | translate }}</div>
                </div>
                <div class="mb-3">
                    <!-- ? Address -->
                    <div class="gray">{{'checkout.address' | translate }}</div>
                    <div>{{billingInformation.street}} {{billingInformation.exteriorNumber}}, {{billingInformation.neighborhood}}, {{billingInformation.zipCode}}, {{billingInformation.municipality}}, {{billingInformation.state}}, {{billingInformation.country}}</div>
                </div>
                <div class="mb-3">
                    <div class="gray">RFC</div>
                    <div>{{billingInformation.rfc}}</div>
                </div>
            </div>
        </ng-container>

        <ng-template #fillBilling>
            <button [routerLink]="'/wallet/billing-information'" class="btn btn-action btn-gray mb-5" type="button">
        {{'checkout.add-billing-inf' | translate }}
      </button>
        </ng-template>

    </ng-container>
</div>
