<app-woke-card>
  <div class="event_title">{{upcoming.event.name}}</div>

  <div class="img_container" [routerLink]="'/network/event-detail/' + upcoming.event.id">
    <img [src]="loggedService.getUrl(upcoming.event.profilePicture)" alt="">
  </div>

  <div class="description_container">

    <div>

      <div class="text_container">
        <div class="text">
          <span class="light" [innerHTML]=upcoming.event.description>

          </span>
        </div>
      </div>

    </div>

  </div>

  <div class="description_container">

    <div>

      <div class="text_container" *ngIf="upcoming.event.coworkingChild">
        <div class="text">
          <span class="bold">{{upcoming.event.coworkingChild.name}}</span>
        </div>
        <div class="text">{{upcoming.event.category}}</div>
      </div>

    </div>
    <!-- * Limited Vacancy  -->
    <div class="text">
      <span class="bold pink" *ngIf="upcoming.event.limitedVacancy">{{'network.upcoming-events.vacancy' | translate }}</span>
    </div>

  </div>

  <div>
    <div class="d-flex justify-content-between mb-3 p-1">
    <div class="text_container">
      <div class="text ">
        <span class="light">{{upcoming.event.location}}</span>
      </div>
      <div class="text ">
        <span class="light">{{upcoming.event.date}} - {{upcoming.event.time}} hrs.</span>
      </div>
      <div class="text ">
        <span class="light">{{ upcoming.event.price | currency }}</span>
      </div>
    </div>
      <div *ngIf="upcoming.qr" class="qr_icon" (click)="openModal(modalQR)"></div>
    </div>
  </div>
  <div class="description_container">
  <div class="button_event" [ngClass]="{'dark': darkMode$ | async }" [routerLink]="'/network/event-detail/' + upcoming.event.id">{{'network.upcoming-events.view-more' | translate }}</div>
  </div>
</app-woke-card>
<ng-template #modalQR>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <qrcode [qrdata]="upcoming.qr" [cssClass]="'woke_qr'" [width]="380" [errorCorrectionLevel]="'M'"></qrcode>
  </div>
</ng-template>
