<!-- * Checkout Office -->
<h4 class="mb-5">{{'checkout.title' | translate }}</h4>

<app-spinner *ngIf="loadingOffice; else officeLoaded"></app-spinner>
<ng-template #officeLoaded>
  <div *ngIf="office">
    <h6 class="semi-bold mb-4">{{office.coworkingChild.name}} {{office.officeNumber}}</h6>

    <div class="d-flex justify-content-between mb-4">
      <div class="gray">Subtotal</div>
      <div>{{checkoutResponse.subtotal | currency}}</div>
    </div>

    <div class="d-flex justify-content-between mb-4">
      <!-- * Tax -->
      <div class="gray">{{'checkout.tax' | translate }}</div>
      <div>{{checkoutResponse.tax | currency}}</div>
    </div>

    <div class="d-flex justify-content-between mb-4">
      <!-- * Fee -->
      <div class="gray">{{'checkout.fee' | translate }}</div>
      <div>{{checkoutResponse.paymentFee | currency}}</div>
    </div>

    <div class="d-flex justify-content-between">
      <div class="red semi-bold">Total</div>
      <h4 class="red semi-bold">{{checkoutResponse.total | currency}}</h4>
    </div>
    <!-- Payment Methods -->
    <app-default-payment-method (paymentMethodChange)="onPaymentMethodChange($event)"></app-default-payment-method>


    <!-- Invoice -->
    <app-select-invoice (cfdiChange)="onCfdiChange($event)" (invoiceChange)="onInvoiceChange($event)"></app-select-invoice>

    <!-- Button Confirm -->

    <button class="btn btn-red btn-action" type="button" (click)="confirm(modalRequest)">
      <!-- * Confirm -->
      <span *ngIf="!loading;else loader">{{'checkout.confirm' | translate }}</span>
      <ng-template #loader>
        <div class="spinner-grow spinner-grow-sm" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </ng-template>
    </button>
  </div>
</ng-template>

<ng-template #modalRequest>
  <div class="modal-header">
    <!-- * Payment successful -->
    <h5 class="modal-title">{{'checkout.modal-success-office.title' | translate }}</h5>
  </div>
  <div class="modal-body">
    <p class="text-center">{{'checkout.modal-success-office.body' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button (click)="goToDetail()" type="button" class="btn btn-link red" data-dismiss="modal">Ok</button>
  </div>
</ng-template>
