import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { BillingInformation, CFDI } from 'src/app/api.interfaces';
import { CheckoutService } from '../../checkout.service';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { WalletService } from 'src/app/logged/wallet/wallet.service';
import { UntypedFormControl, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-select-invoice',
  templateUrl: './select-invoice.component.html',
  styleUrls: ['./select-invoice.component.scss']
})
export class SelectInvoiceComponent implements OnInit, OnDestroy {

  @Output() invoiceChange = new EventEmitter<boolean>();

  @Output() cfdiChange = new EventEmitter();


  loadingBillingInformation = true;
  invoice = false;
  billingInformation: BillingInformation;
  cfdis: CFDI[] = [];

  killall = new Subject();

  useOfCfdi: UntypedFormControl;

  constructor(
    private checkoutService: CheckoutService,
    private walletService: WalletService,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit() {

    this.useOfCfdi = this.fb.control(null);

    this.useOfCfdi.valueChanges.pipe(
      takeUntil(this.killall)
    ).subscribe({
      next: value => {
        this.cfdiChange.emit(value);
      }
    })

    this.walletService.loadCFDI().pipe(
      take(1),
      takeUntil(this.killall),
    ).subscribe({
      next: data => {
        this.cfdis = data;
        console.log(data);
      }
    })

    this.checkoutService.loadBillingInformation().pipe(
      take(1),
      takeUntil(this.killall)
    ).subscribe({
      next: (billingInformation) => {
        this.loadingBillingInformation = false;
        this.billingInformation = billingInformation;
      },
      error: (error) => {
        console.log(error);
        this.loadingBillingInformation = false;
      },
      complete: () => console.log('Billing info completed')
    })
  }

  ngOnDestroy() {
    this.killall.next();
    this.killall.complete();
  }

  onInvoiceChange(invoice: boolean) {
    this.invoice = invoice;
    this.invoiceChange.emit(invoice);
  }

}
