  <app-spinner class="p-5" *ngIf="loading; else termsTemp"></app-spinner>
  <ng-template #termsTemp>
    <div class="container terms">
      <div class="row">
        <div class="content" [innerHTML]=content ></div>
      </div>
    </div>
  </ng-template>


