<div class="wrapper">
  <div class="title">
    <div>
      <ng-content select="app-woke-title"></ng-content>
      <ng-content select="app-woke-subtitle"></ng-content>
    </div>
    <div>
      <ng-content select="app-woke-section-actions"></ng-content>
      <ng-content select="app-woke-subsection-actions"></ng-content>
    </div>
  </div>
  <ng-content></ng-content>
</div>
