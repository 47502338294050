<div class="background-gradient">
    <div class="navbar">
        <!-- * Logotype -->
        <a [routerLink]="'/'"><img class="logo" src="/assets/img/logos/Logo_Adstop.png" alt="Adstop"></a>

        <button class="close-public" (click)="goBack()">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="row">
        <div class="form-container">
            <!-- ! Create an Account -->
            <div class="white-text text-center fw-600">{{'landing.register.create-account' | translate }}</div>
            <div class="text-center new mb-3">Y encuentra el espacio publicitario ideal para tu empresa.</div>
            <p class="text-center new mb-4">
                    Si deseas dar de alta tus espacios publicitarios,
                    <b (click)="goRegisterSpace()" style="cursor: pointer; color:#5ED2AA;" class="text-center text-alert text-alert-valid mb-5">regístrate aquí</b>
            </p>

            <!-- ? Register Form -->
            <form class="minimalist transparent pb-5" [formGroup]="registerForm">
                <!-- * First Name Field -->
                <div class="form-group">
                    <label for="firstName">{{'landing.register.first-name' | translate }}</label>
                    <input (input)="fill_in = false" type="text" class="form-control" id="firstName" placeholder="Nombre" formControlName="firstName">
                    <!-- * Only letters alert -->
                    <small *ngIf="registerForm.controls.firstName.invalid && registerForm.controls.firstName.dirty && !fill_in" class="white text-alert">{{'landing.register.only-letters' | translate }}</small>
                    <small *ngIf="fill_in && !registerForm.controls.firstName.dirty" class=" white text-alert">{{'landing.register.field-required' | translate }}</small>

                </div>

                <!-- * Last Name Field -->
                <div class="form-group">
                    <label for="lastName">{{'landing.register.last-name' | translate }}</label>
                    <!-- * Only letters alert -->
                    <input type="text" class="form-control" id="lastName" placeholder="Apellido" formControlName="lastName">
                    <small *ngIf="registerForm.controls.lastName.invalid && registerForm.controls.lastName.dirty" class="white text-alert">{{'landing.register.only-letters' | translate }}</small>
                </div>

                <!-- * User Name Field -->
                <div class="form-group">
                    <label for="username">{{'landing.register.username' | translate }}</label>
                    <input (input)="fill_in = false" type="text" class="form-control" id="username" placeholder="@usuario" formControlName="username">
                    <small *ngIf="registerForm.controls.username.invalid && registerForm.controls.username.dirty && !fill_in" class=" white text-alert">{{'landing.register.field-required' | translate }}</small>
                    <small *ngIf="fill_in && !registerForm.controls.username.dirty" class=" white text-alert">{{'landing.register.field-required' | translate }}</small>
                </div>

                <!-- * Email Field -->
                <div class="form-group">
                    <label for="email">{{'landing.register.email' | translate }}</label>
                    <input (input)="fill_in = false" type="email" class="form-control" id="email" placeholder="Correo@mail.com" formControlName="email">
                    <small *ngIf="registerForm.controls.email.invalid && registerForm.controls.email.dirty && !fill_in" class="white text-alert">{{'landing.register.invalid-email' | translate }}</small>
                    <small *ngIf="fill_in && !registerForm.controls.email.dirty" class=" white text-alert">{{'landing.register.field-required' | translate }}</small>
                </div>

                <!-- * Password Field -->
                <div class="form-group">
                    <label for="password">{{'landing.register.password' | translate }}</label>
                    <input type="password" class="form-control" id="password" placeholder="********" formControlName="password">
                    <small *ngIf="registerForm.controls.password.hasError('minlength') && registerForm.controls.password.dirty" class="white text-alert">{{'landing.register.invalid-pass' | translate }}</small>
                    <small *ngIf="!registerForm.controls.password.hasError('minlength')" class="text-alert text-alert-valid">{{'landing.register.minimum-characters' | translate }}</small>
                </div>

                <!-- * Confirm Password Field -->
                <div class="form-group">
                    <label for="confirmPassword">{{'landing.register.confirm-pass' | translate }}</label>
                    <input type="password" class="form-control" id="confirmPassword" placeholder="********" formControlName="confirmPassword">
                    <small *ngIf="registerForm.errors?.invalid && registerForm.controls.confirmPassword.dirty && registerForm.controls.password.dirty" class="white text-alert">{{'landing.register.no-match' | translate }}</small>
                </div>

                <!-- * Privacy Notice Button -->
                <div class="policy-section">

                    <!-- <div class="policy-radio"> -->
                    <!-- <input type="radio" class="mat-radio-button" ng-model="string" value="string"> -->
                    <!-- <div class="radio_control"></div>
                    </div> -->

                    <div class="radio_container mb-3" [ngClass]="checkTerms ? 'active' : ''">
                        <div (click)="changeTerms(1)" class="radio_control"></div>
                    </div>

                    <p class="label">
                        {{'landing.register.by-creating' | translate }} <span class="label-polycy" (click)="showTerms(wokeTemplate)">{{'landing.register.terms' | translate }}</span> y el
                        <span class="label-polycy" (click)="showPolicies(wokeTemplate)">{{'landing.register.policy' | translate
              }}</span>
                    </p>
                    <!-- <b (click)="showTerms(wokeTemplate)" >{{'landing.register.terms' | translate }}</b>
            {{'landing.register.and' | translate }}
            <b (click)="showPolicies(wokeTemplate)" >{{'landing.register.policy' | translate }}</b> -->
                </div>

                <!-- * Submit Button -->
                <!-- [disabled]="loading" -->
                <button type="button" class="btn btn-woke btn-white" [disabled]="!checkTerms" (click)="register(fillAllTemplate)">
          <span *ngIf="!loading;else loader">{{'landing.register.continue' | translate }}</span>
          <ng-template #loader>
            <div class="spinner-grow spinner-grow-sm" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </ng-template>
        </button>

            </form>
            <p (click)="goRegisterSpace()" style="cursor: pointer; color:#5ED2AA;" class="text-center text-alert text-alert-valid mb-5">Registra tus espacios de publicidad</p>
        </div>
    </div>
</div>
<ng-template #wokeTemplate>
    <div class="modal-header">
        <div class="back_arrow" (click)="modalRef.hide()"></div>
        <h5 class="modal-title">{{title}}</h5>
    </div>
    <app-woke-terms [content]=content></app-woke-terms>
</ng-template>

<ng-template #fillAllTemplate>
    <div class="modal-header">
        <h5 class="modal-title semi-bold">Ups!</h5>
    </div>
    <div class="modal-body delete confirm">
        <ng-container [ngSwitch]="errorMessage">
            <p *ngSwitchCase="'Email already exists.'" class="mb-0 error m-auto">
                {{'landing.register.email-exist' | translate}}
            </p>
            <p *ngSwitchCase="'Username already exists.'" class="mb-0 error m-auto">
                {{'landing.register.user-exist' | translate}}
            </p>
            <p *ngSwitchDefault class="mb-0 error m-auto">
                {{'landing.register.fill-in' | translate}}
            </p>
        </ng-container>
    </div>
    <div class="modal-footer">
        <div class=" col-6 py-0 justify-content-center">
            <button class="btn red m-auto" (click)="modalRef.hide()">Ok</button>
        </div>
    </div>
</ng-template>
