import { Component, OnInit, OnDestroy, TemplateRef } from '@angular/core';
import { CheckoutService } from '../checkout.service';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LicenseInfoResponse, LicenseCheckoutPayload } from '../checkout.types';
import { PaymentMethod } from 'src/app/api.interfaces';
import { WalletFacade } from 'src/store/wallet/wallet.facade';
import { LoggedService } from '../../logged.service';
import { ProfileFacade } from 'src/store/profile/profile.facade';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.scss']
})
export class LicenseComponent implements OnInit, OnDestroy {

  // loaders
  content: string
  title: string
  language: string;
  modalRef: BsModalRef;
  loadingCheckout = true;
  loadingConfirm = false;
  checkTerms: boolean = false;
  licenseInfo: LicenseInfoResponse;
  paymentMethod: PaymentMethod;
  invoice = false;
  myLicenseId
  killall = new Subject();

  url: SafeResourceUrl;

  constructor(
    private checkoutService: CheckoutService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private wokeService: ModalsService,
    private walletFacade: WalletFacade,
    private loggedService: LoggedService,
    private profileFacade: ProfileFacade,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.walletFacade.loadPaymentMethods();
    this.loadInfo();
    this.profileFacade.licenses$.pipe(
       take(1),
       takeUntil(this.killall)
     ).subscribe({
       next: response => {
         this.myLicenseId = response[0].id
       }
     })
  }

  ngOnDestroy() {
    this.killall.next();
    this.killall.complete();
  }

  onInvoiceChange(invoice: boolean) {
    this.invoice = invoice;
  }

  onPaymentMethodChange(paymentMethod: PaymentMethod) {
    this.paymentMethod = paymentMethod;
  }

  loadInfo() {

    // a) Load the License Info
    this.route.paramMap.pipe(
      map(params => params.get('id')),
      switchMap(id => this.checkoutService.loadLicenseCheckoutInfo(id)),
    ).pipe(
      take(1),
      takeUntil(this.killall)
    ).subscribe({
      next: licenseInfo => {
        this.loadingCheckout = false;
        this.licenseInfo = licenseInfo;
      },
      error: (error) => {
        console.log(error);
        this.loadingCheckout = false;
      },
      complete: () => console.log('License info completed')
    })
  }

  confirm(template: TemplateRef<any>) {

    this.loadingConfirm = true;
    this.route.paramMap.pipe(
      map(params => params.get('id')),
      switchMap(id => this.checkoutService.loadLicenseCheckoutProcess({
        sourceId: this.paymentMethod.id, invoice: this.invoice, packageId: id
      })),
      take(1),
      takeUntil(this.killall)
    ).subscribe({
      next: data => {
        this.loadingConfirm = false;

        console.log(data);

        if (data.msg === 'succeeded') {
          return this.loggedService.openModal(template);
        }
        if (data.msg === 'requires_action') {
          this.url = this.sanitizer.bypassSecurityTrustResourceUrl(data.url);
          return this.loggedService.openModal(template);
        }
        this.loggedService.showError(data);
      },
      error: error => {
        console.warn(error);
        this.loadingConfirm = false;
        this.loggedService.showError(error);
      },
      complete: () => console.log('Confirm completed')
    })

  }

  showTerms(temp) {
    this.wokeService.getTerms().subscribe({
      next: (response) => {
        this.content = response.html;
        this.title = this.language === 'en' ? "Terms and Conditions" : "Términos y Condiciones"
        if (this.content !== "") {
          this.openTagModal(temp)
        }
      },
      error: (err) => {
        console.log(err);
      }
    })
  }
  openTagModal(tagModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(tagModal, { class: 'modal-dialog-centered modal-xl modal-privacy' })
  }
  changeTerms(value) {
    this.checkTerms = !this.checkTerms;
    console.log(this.checkTerms);
  }

  goToDetail() {
    console.log("succ");

    this.router.navigate(['/my-license/detail/' + this.myLicenseId])
    //this.router.navigate(['/workspace'])
    this.loggedService.closeModal();
  }


}
