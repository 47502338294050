<div class="p-3">
  <!-- * Payment Method -->
  <h5 class="mb-3">{{'wallet.modal-payment-method.title' | translate }}</h5>
  <form [formGroup]="formGroup">
    <fieldset>
      <div class="mb-3">
        <!-- * Card holder -->
        <label class="form-label semi-bold mb-3">{{'wallet.modal-payment-method.cardholder' | translate }}</label>
        <input class="jumbo-mask" formControlName="name" type="text" size="20" autocomplete="off" placeholder="{{'wallet.modal-payment-method.your-full-name' | translate }}"/>
      </div>
      <div class="mb-3">
        <!-- * Card Number -->
        <label class="form-label semi-bold mb-3">{{'wallet.modal-payment-method.card-number' | translate }}</label>
        <div class="jumbo-mask" style="height: 50px; padding-top: 15px;">
          <stripe-card
            #stripeCard
            (catch) = "onStripeError($event)"
            [(invalid)] = "invalidError"
            (tokenChange) = "setStripeToken($event)"
            (sourceChange) = "setStripeSource($event)"
          ></stripe-card>
        </div>
      </div>
    </fieldset>
    <!-- * Save -->
    <button class="btn btn-action btn-red" type="button" (click)="stripeCard.createToken(formGroup.value)">
      <span>{{'wallet.modal-payment-method.save' | translate }}</span>
    </button>
  </form>
</div>
