<!-- * Payment methodss -->
<div class="mb-5 mt-5">
    <div class="d-flex justify-content-between mb-3">
        <h6>{{'checkout.payment-method.title' | translate }}</h6>
        <ng-container *ngIf="paymentDefault$ | async; else addNew">
            <div class="red pointer" (click)="changePaymentMethod()">{{'checkout.payment-method.change' | translate }}</div>
        </ng-container>
        <ng-template #addNew>
            <div class="red pointer" (click)="addPaymentMethod()">{{'checkout.payment-method.add' | translate }}</div>
        </ng-template>
    </div>
    <ng-container *ngIf="paymentDefault$ | async as payment">

        <div *ngIf="payment.id =='spei'; then spei; else card"></div>

        <ng-template #spei>
            <div class="jumbo-mask p-2 mb-2">
                <div class="text-box">
                    <div [ngClass]="getClass(payment.brand)"></div>
                    <div class="mb-2">
                        <div class="center">SPEI</div>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template #card>
            <div class="jumbo-mask p-2 mb-4">
                <div class="text-box">
                    <div [ngClass]="getClass(payment.brand)"></div>
                    <div class="mb-4">
                        <div class="gray">{{'checkout.payment-method.card-number' | translate }}</div>
                        <div>{{payment.data?.card_number}}</div>
                    </div>
                    <div>
                        <div class="gray">{{'checkout.payment-method.expiration' | translate }}</div>
                        <div>{{payment.data?.expiration_month.length > 1 ? payment.data?.expiration_month : '0'+payment.data?.expiration_month }}/{{payment.data?.expiration_year}}</div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-container>
</div>
