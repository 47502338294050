import { Injectable } from '@angular/core';
import { WeekDay } from 'src/app/woke/woke-datepicker/woke-datepicker.component';
import { RangePickerEvent, TimePickerEvent, PartialAmount, ExternalAvailability } from './book.models';
import { HttpClient } from '@angular/common/http';
import { TokenFacade } from 'src/store/token/token.facade';
import { environment } from 'src/environments/environment';
import { switchMap, map, filter } from 'rxjs/operators';
import { MetaResponse, Workspace } from 'src/app/api.interfaces';
import { GetOfficesType } from '../../workspace/plan/plan.models';

@Injectable({ providedIn: 'root' })
export class BookService {
  constructor(
    private http: HttpClient,
    private tokenFacade: TokenFacade
  ) { }

  getStr(day: number) {
    return day > 9 ? `${day}` : `0${day}`
  }

  parseSelection(weekday: WeekDay) {
    console.log(weekday);
    let month = weekday.month as number
    // if(weekday.month === -1 ){
    //   month = 11
    // }else{
    //   month = weekday.month as number
    // }
    return `${this.getStr(weekday.day)}/${this.getStr((month) + 1)}/${weekday.year}`;
  }

  parseRange(event: RangePickerEvent): string {
    const { first, last } = event;
    return `${this.getStr(first.day)}/${this.getStr((first.month as number) + 1)}/${first.year} - ${this.getStr(last.day)}/${this.getStr((last.month as number) + 1)}/${last.year}`;
  }

  getAmout(event: TimePickerEvent, internalPrice: string | number): PartialAmount {
    const hours = Number(event.hours);
    const subtotal = hours * Number(internalPrice);
    const iva = subtotal * 0.16;
    const paymentFee = subtotal * 0.034;
    const total = subtotal + iva + paymentFee;
    return {
      hours,
      subtotal,
      iva,
      paymentFee,
      total,
      time: event.time
    };
  }

  loadWorkspaceDetail(idWorkspace: string) {
    const url = `${environment.api}/workspace/${idWorkspace}`;
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data),
          map(data => Array.isArray(data) && data.length ? data[0] as Workspace : undefined),
          filter(data => data !== undefined)
        )
      }),
    )
  }

  getWorkspaceExclusions(workspaceId:number) {
    const url = `${environment.api}/workspace/booking/${workspaceId}`;
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data),
        )
      }),
    )
  }

  getMonthExclusions(date:string, workspaceId:number){
    const url = `${environment.api}/workspace/schedule/${workspaceId}`;
    const formData = new FormData();
    formData.append('date', date);
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        return this.http.post(url, formData, { headers }).pipe(
          map((response: MetaResponse) => response.data),
        )
      }),
    )
  }

  // * Workspace information for internal or external bookings

  getExternalAvailability(date: string, workspace:string) {
    const url = `${environment.api}/getExternalAvailability`;
    const formData = new FormData();
    formData.append('workspace', workspace);
    formData.append('date', date);
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        return this.http.post(url, formData, { headers }).pipe(
          map((response: MetaResponse) => response.data as ExternalAvailability),
        )
      })
    )
  }

  getTimeExclusions(date: string, workspace: Workspace) {
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => this.http.get(`${environment.api}/workspace/booking/${workspace.id}?day=${date}`, { headers })),
      map((response: MetaResponse) => response.data)
    )
  }

  getBoookingInfoHours(workspace: Workspace, time: TimePickerEvent, id?:string) {
    const url = id && id !== '' ? `${environment.api}/workspace/booking/${workspace.id}?timeSelected=${time.hours}` : `${environment.api}/workspace/booking/${workspace.id}?timeSelected=${time.hours}`
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => this.http.get(url, { headers })),
      map((response: MetaResponse) => response.data),
    )
  }

  getBoookingInfoDays(workspace: Workspace, event: RangePickerEvent, id?:string) {
    const url = id && id !== '' ? `${environment.api}/workspace/booking/${workspace.id}?timeSelected=${event.days}` : `${environment.api}/workspace/booking/${workspace.id}?timeSelected=${event.days}`
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => this.http.get(url, { headers })),
      map((response: MetaResponse) => response.data),
    )
  }

  getBoookingInfoPeriod(workspace: Workspace, time: string, id?:string) {
    const url = id && id !== '' ? `${environment.api}/workspace/booking/${workspace.id}?timeSelected=${time}` : `${environment.api}/workspace/booking/${workspace.id}?timeSelected=${time}`
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => this.http.get(url, { headers })),
      map((response: MetaResponse) => response.data),
    )
  }

  getTotalPromoCode(payload){
    const url = payload && payload.office !== '' ? `${environment.api}/workspace/booking/${payload.id}?timeSelected=${payload.timeSelected}&office=${payload.office}&code=${payload.code}` : `${environment.api}/workspace/booking/${payload.id}?timeSelected=${payload.timeSelected}&code=${payload.code}`
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => this.http.get(url, { headers })),
      map((response: MetaResponse) => response.data),
    )
  }


  getOffices() {
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        const url = `${environment.api}/my-offices`;
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data),
          map((data) => {
            return {
              offices: data.myOffice.filter(office => office.status !== 'rejected' || office.active),
              extraOffices : data.extraOffices
            }
          }),
        )
      })
    )
  }

}
