<div class="woke_filters"  [ngClass]="{'dark': darkMode$ | async }">
  <div class="accordeon" [ngClass]="!showOptions ? 'closed' : ''" (click)="toggle()">
    <span>{{label}}</span>
    <span class="arrow_down_icon" [ngClass]="!showOptions ? 'closed' : ''"></span>
  </div>

  <div *ngIf="showOptions" class="content">
    <ng-content></ng-content>
  </div>
</div>
