<!-- * Checkout License -->
<h4 class="mb-5">{{'licenses.checkout.title' | translate }}</h4>

<ng-template #wokeTemplate>
    <div class="modal-header">
        <div class="back_arrow" (click)="modalRef.hide()"></div>
        <h5 class="modal-title">{{title}}</h5>
    </div>
    <app-woke-terms [content]=content></app-woke-terms>
</ng-template>

<app-spinner *ngIf="loadingCheckout; else checkoutTemplate"></app-spinner>
<ng-template #checkoutTemplate>

    <!-- License Info -->
    <div *ngIf="licenseInfo" class="mb-5">
        <h6 class="semi-bold mb-4">
            {{licenseInfo.name}} {{'licenses.credits' | translate }}
        </h6>

        <div class="d-flex justify-content-between mb-4">
            <div class="gray">Subtotal</div>
            <div>{{licenseInfo.amount.subTotal | currency}}</div>
        </div>

        <div class="d-flex justify-content-between mb-4">
            <!-- * Fee -->
            <div class="gray">{{'checkout.fee' | translate }}</div>
                <div *ngIf="paymentMethod?.id == 'spei'">${{licenseInfo.amount.speiPaymentFee}}</div>
                <div *ngIf="paymentMethod?.id != 'spei'">${{licenseInfo.amount.paymentFee}}</div>
            </div>

        <div class="d-flex justify-content-between mb-4">
            <!-- * Tax -->
            <div class="gray">{{'checkout.tax' | translate }}</div>
            <div *ngIf="paymentMethod?.id == 'spei'">${{licenseInfo.amount.speiTax}}</div>
            <div *ngIf="paymentMethod?.id != 'spei'">${{licenseInfo.amount.tax}}</div>
        </div>



        <div class="d-flex justify-content-between">
            <div class="red semi-bold">Total</div>
            <h4 class="red semi-bold" *ngIf="paymentMethod?.id == 'spei'">${{licenseInfo.amount.speiTotal}}</h4>
            <h4 class="red semi-bold" *ngIf="paymentMethod?.id != 'spei'">${{licenseInfo.amount.total}}</h4>
        </div>
    </div>

    <!-- Payment Methods -->
    <app-default-payment-method (paymentMethodChange)="onPaymentMethodChange($event)"></app-default-payment-method>

    <!-- Invoice -->
    <app-select-invoice (invoiceChange)="onInvoiceChange($event)"></app-select-invoice>

    <!-- * Privacy Notice Button -->
    <div class="policy-section">
        <div class="radio_container mb-3" [ngClass]="checkTerms ? 'active' : ''">
            <div (click)="changeTerms(1)" class="radio_control"></div>
        </div>
        <div class="section-polycy">
            <p class="policy-text">
                Acepto los
                <b class="label-polycy" (click)="showTerms(wokeTemplate)">{{'landing.contact.terms' | translate
          }}</b>
            </p>
        </div>

    </div>
    <!-- Button Confirm -->
    <div *ngIf="paymentMethod?.id == 'spei'; then buttonSpei; else buttonConfirm"></div>

    <ng-template #buttonSpei>
        <!-- * Button Spei -->
        <button class="btn btn-red btn-action" type="button" [disabled]="!checkTerms" (click)="confirm(spei)">
            <span *ngIf="!loadingConfirm;else loaderConfirm">{{'checkout.confirm' | translate }}</span>
            <ng-template #loaderConfirm>
            <div class="spinner-grow spinner-grow-sm" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            </ng-template>
        </button>
    </ng-template>

    <ng-template #buttonConfirm>
        <!-- * Button Confirm -->
        <button class="btn btn-red btn-action" type="button" [disabled]="!checkTerms" (click)="confirm(modalRequest)">
            <span *ngIf="!loadingConfirm;else loaderConfirm">{{'checkout.confirm' | translate }}</span>
            <ng-template #loaderConfirm>
            <div class="spinner-grow spinner-grow-sm" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            </ng-template>
        </button>
    </ng-template>



    <!--<button class="btn btn-red btn-action" type="button" [disabled]="!checkTerms" (click)="confirm(modalRequest)">
        <span *ngIf="!loadingConfirm;else loaderConfirm">{{'checkout.confirm' | translate }}</span>
        <ng-template #loaderConfirm>
        <div class="spinner-grow spinner-grow-sm" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        </ng-template>
    </button>-->


</ng-template>

<ng-template #spei style="max-width:100% !important">
    <div class="modal-header">
        <!-- * Payment successful -->
        <h5 class="modal-title">Pago Pendiente</h5>
    </div>
    <div class="modal-body {{url}}">
        <iframe [src]="url"  width="100%" height="700"></iframe>
    </div>
    <div class="modal-footer">
        <button (click)="goToDetail()" type="button" class="btn btn-link red" data-dismiss="modal">Ok</button>
    </div>
</ng-template>


<ng-template #modalRequest>
    <div class="modal-header">
        <!-- * Payment successful -->
        <h5 class="modal-title">{{'checkout.modal-success-office.title' | translate }}</h5>
    </div>
    <div class="modal-body">
        <p class="text-center">{{'checkout.modal-success-office.body' | translate }}</p>
    </div>
    <div class="modal-footer">
        <button (click)="goToDetail()" type="button" class="btn btn-link red" data-dismiss="modal">Ok</button>
    </div>
</ng-template>
