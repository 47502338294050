<div class="gradient">
    <!-- ! NavBar -->
    <!-- * Baner -->
    <div class="banner" id="banner" [ngClass]="{'nav': openSpan}">
        <div class="navbar">
            <img class="logo" src="/assets/img/logos/logoaDSTOP.png" alt="Adstop">

            <ul class="nav justify-content-end">
                <!--<li class="nav-item">
                    <a class="nav-link" (click)="switchLanguage()">{{ 'ajustes.boton-lenguaje' | translate }}</a>
                </li>-->
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="'about'">{{'footer.tools' | translate }}</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" [routerLink]="'register-space'">{{'footer.register-space' | translate }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link log-in" [routerLink]="'login'">{{'footer.login' | translate }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link register" [routerLink]="'register'">{{'footer.register' | translate }}</a>
                </li>
            </ul>
            <div (click)="openSpan = !openSpan" [ngClass]="{'open': openSpan}" id="nav-icon"><span></span> <span></span> <span></span></div>
        </div>
        <nav class="navbar" id="mobilenav" [ngClass]="{'active': openSpan}">
            <ul>
                <!--<li class="nav-item">
                    <a class="nav-link" (click)="switchLanguage()">{{ 'ajustes.boton-lenguaje' | translate }}</a>
                </li>-->
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="'about'">{{'footer.tools' | translate }}</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" [routerLink]="'register-space'">{{'footer.register-space' | translate }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link log-in" [routerLink]="'login'">{{'footer.login' | translate }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link register" [routerLink]="'register'">{{'footer.register' | translate }}</a>
                </li>
            </ul>
        </nav>
        <!-- ! Main container -->
        <div class="main-container" [ngClass]="{'nav': openSpan}">
            <div id="header">
                <div class="titles">
                    <h1 class="text-center">{{'landing.home.work-anywhere' | translate }}</h1>
                    <h2 class="text-center">{{ textHeader }}</h2>
                </div>
                <!-- ? Filter for the map -->
                <div class="filter">
                    <h3>{{'landing.home.find-spaces' | translate }}</h3>
                    <form [formGroup]="mapForm">
                        <div class="inputs">
                            <!-- ? Location -->
                            <div class="location">
                                <input formControlName="location" type="text" placeholder="{{'workspace.quick.type-anywhere' | translate }}" (focus)="hideAnywhere()">

                                <!-- Estas son las opcioness -->
                                <div class="select-options" *ngIf="!selectedLocation">
                                    <div class="select-option" [ngClass]="{'red':area.type === 'near me','state':area.type === 'state'}" *ngFor="let area of filteredAreas" (click)="selectOption(area,mapsResult)">
                                        {{area.location}}
                                    </div>
                                </div>
                            </div>

                            <!-- * Types -->
                            <app-woke-select (click)="getElm(mapsResult)" formControlName="type" placeholder="{{'landing.home.by-type' | translate }}" [options]="types" className="round" valueAccesor="type">
                                <ng-template #item let-option="option">
                                    <div class="d-flex align-items-center">
                                        <div>
                                            {{ language === 'en' ? option.type : option.type}}
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template #selected let-value="value">
                                    <div class="d-flex align-items-center">
                                        <div class="cut-text">
                                            {{ language === 'en' ? value.type : value.type}}
                                        </div>
                                    </div>
                                </ng-template>
                            </app-woke-select>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>


    <!--  ! Result Spaces -->

    <div class="results" #mapsResult>
        <!-- * Mostrando resultados -->
        <p class="show" *ngIf="loaded">{{'landing.home.show' | translate }} <b>{{results}}</b> {{'landing.home.results' | translate }}</p>
        <!-- * Map -->
        <div class="mb-4">
            <agm-map *ngIf="searchedMap && workspacePins.length" [styles]="mapStyles" [zoom]="zoom" [latitude]="lat" [longitude]="lng" [fitBounds]="fitBounds">
                <agm-marker-cluster [styles]="[pinClusterStyle]">
                    <ng-container *ngFor="let pin of workspacePins">
                        <agm-marker *ngIf="pin.workspaces.length > 0" [latitude]="pin.lat" [longitude]="pin.lng" (markerClick)="markerClicked(pin,workspacediv)" (mouseOver)="onMouseOver(infoWindow, $event)" [markerClickable]="true" [agmFitBounds]="fitBounds" (mouseOut)="onMouseOut(infoWindow, $event)"
                            [label]="{color: pin.isClicked ? '#fff' : '#fff', text: pin.workspaces.length.toString(), fontSize:'16px', fontWeight:'700', textAlign:'center'}" [iconUrl]="pin.isClicked ? '/assets/img/markers/m1.png' : '/assets/img/markers/no-active.png'">
                            <agm-info-window #infoWindow>
                                {{pin.name}}
                            </agm-info-window>
                        </agm-marker>
                    </ng-container>
                </agm-marker-cluster>
            </agm-map>
        </div>
        <!-- * End Map -->
        <div class="d-flex results-btns">
            <!-- If we need navigation buttons -->
            <div class="car-btns">
                <div class="swiper-button-prev result"></div>
                <div class="swiper-button-next result"></div>
            </div>
        </div>
        <div class="spaces-found" #workspacediv>
            <app-spinner *ngIf="loading || loadingBest"></app-spinner>
            <div class="swiper-container" id="results">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" *ngFor="let space of loaded ? workspaces : recentlyAdded">
                        <article class="my-card my-card__1">
                            <figure class="my-card-img">
                                <a [routerLink]="'login'"><img [src]="resourceUrl+space.profilePicture" alt="" /></a>
                            </figure>
                            <div class="my-card__content">
                                <div>
                                    <h2 id="title">{{space.name}}</h2>
                                    <h3>{{language === 'en' ? space.type : space.type_es}}</h3>
                                </div>
                                <p>{{space.short_location}}</p>
                            </div>
                        </article>
                    </div>
                </div>
            </div>


        </div>
        <button [routerLink]="'register'" class="btn-red btn-woke btn-link">{{'landing.home.register' | translate }}</button>
    </div>

    <!-- ! Best spaces -->
    <div class="find-spaces">
        <div class="best-spaces">
            <div class="d-flex title-btns">
                <h1>{{'landing.home.best-spaces' | translate }}</h1>
                <!-- If we need navigation buttons -->
                <!-- <div class="car-btns">
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div> -->
            </div>
            <app-spinner *ngIf="loadingBest"></app-spinner>
            <div class="swiper-container" id="best">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" *ngFor="let space of bestRated">
                        <article class=" my-card my-card__1">
                            <figure class="my-card-img">
                                <a [routerLink]="'login'"><img [src]="resourceUrl+space.profilePicture" alt="" /></a>
                            </figure>
                            <div class="my-card__content">
                                <div>
                                    <h2 id="title">{{space.name}}</h2>
                                    <h3>{{language === 'en' ? space.type : space.type_es}}</h3>
                                </div>
                                <p>{{space.short_location}}</p>
                            </div>
                        </article>
                    </div>
                </div>

            </div>


        </div>

    </div>

    <!-- ! Conecta -->

    <!-- ! Conoce más -->
    <div class="learn">
        <img src="/assets/img/landing/headerx2.png" alt="">
        <div class="text-learn">
            <p>{{'landing.home.learn' | translate }}</p>
            <p>{{'landing.home.learn-2' | translate }}</p>
            <button [routerLink]="'about'" class="btn-woke btn-link btn-red desk">{{'landing.home.learn-btn' | translate }}</button>
        </div>
        <button [routerLink]="'about'" class="btn-woke btn-link btn-red mobile">{{'landing.home.learn-btn' | translate }}</button>
    </div>

    <div class="spaces">
        <div class="new-spaces">
            <div class="d-flex justify-content-between align-items-baseline">
                <h3 style="font-size: 2vw;" class="mb-0">{{'landing.home.new-spaces' | translate }}</h3>
                <!-- If we need navigation buttons -->
                <div class="car-btns">
                    <div class="swiper-button-prev new"></div>
                    <div class="swiper-button-next new"></div>
                </div>
            </div>
            <div class="swiper-container" id="new">
                <div class="swiper-wrapper">
                    <ng-container *ngFor="let coworking of coworkings">
                        <figure *ngIf="coworking.status === 'Active'" class="swiper-slide text-center mb-0 d-block">
                            <img class="child" [src]="coworking.profilePicture" [alt]="coworking.name">
                        </figure>
                    </ng-container>
                </div>

            </div>
        </div>
    </div>

    <app-footer></app-footer>


</div>