<app-spinner *ngIf="loading; else workspaceLoad"></app-spinner>
<ng-template #workspaceLoad>
  <div class="bookings jumbo-mask mb-4">
    <div class="logo_container">
      <img [src]="loggedService.logoPublico"  alt="">
    </div>
    <div class="img_container">
      <img [src]="loggedService.getUrl(workspace.profilePicture)" alt="">
    </div>
    <div class="description_container">
      <div>
        <div class="text_container">
          <div class="text">
            <span class="text-bold">{{workspace.name}} - </span><span>{{workspace.coworkingChild.name}}</span>
          </div>
          <div class="text">{{workspace.type}}</div>
          <ng-container *ngIf="quickModel">
            <div class="text" *ngIf="quickModel.date">{{quickModel.date}}</div>
            <div class="text" *ngIf="quickModel.time">{{quickModel.time}}</div>
          </ng-container>
        </div>
        <div class="text_container">
          <div class="text">
            <span class="light">{{workspace.location}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
