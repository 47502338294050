<!-- * Create Business Profile -->

<!-- Modal Header -->
<div class="modal-body">
  <div *ngIf="showBusinessForm">
    <h6>{{'business-profile.title' | translate }}</h6>
    <div class="justify-content-center align-items-center">
      <div class="text mb-4">{{'business-profile.description' | translate }}</div>
      <!-- Register Form -->
      <form [formGroup]="registerForm">
        <!-- * Business name -->
        <div class="mb-3  d-flex flex-column">
          <label class="semi-bold">{{'business-profile.name' | translate }}</label>
          <input name="name" formControlName="name" class="jumbo-mask" type="text" placeholder="{{'business-profile.name-ph' | translate }}">
          <small *ngIf="registerForm.controls.name.hasError('minlength')" class="red text-alert align-self-end mt-1" >{{'business-profile.min-name-chars' | translate }}</small>

        </div>
        <!-- * Business username -->
        <div class="mb-3  d-flex flex-column">
          <label class="semi-bold">{{'business-profile.username' | translate }}</label>
          <input autocomplete="off" name="usernameBusiness" formControlName="usernameBusiness" class="jumbo-mask" type="text" placeholder="{{'business-profile.username-ph' | translate }}">
          <small *ngIf="registerForm.controls.usernameBusiness.hasError('minlength')" class="red text-alert align-self-end mt-1" >{{'landing.register.minimum-characters' | translate }}</small>
          <small *ngIf="isError" class="red text-alert align-self-end mt-1">{{errorMsj}}</small>

        </div>
        <!-- * Business password -->
        <div class="mb-3  d-flex flex-column">
          <label class="semi-bold">{{'business-profile.pass' | translate }}</label>
          <input autocomplete="off" name="password" formControlName="password" class="jumbo-mask" type="password" placeholder="********">
          <small *ngIf="registerForm.controls.password.hasError('minlength')" class="red text-alert align-self-end mt-1" >{{'landing.register.minimum-characters' | translate }}</small>
        </div>

         <!-- * Confirm Password Field -->
         <div class="mb-3 d-flex flex-column">
          <label class="semi-bold">{{'landing.register.confirm-pass' | translate }}</label>
          <input autocomplete="off" type="password" class="jumbo-mask" id="confirmPassword" placeholder="********" formControlName="confirmPassword">
          <small *ngIf="registerForm.errors?.invalid && registerForm.controls.confirmPassword.dirty && registerForm.controls.password.dirty" class="red text-alert align-self-end mt-1">{{'landing.register.no-match' | translate }}</small>
        </div>

        <div class="row justify-content-around">
          <div class="col-12">
            <button [disabled]="!registerForm.valid" type="button" (click)="businessContinue()" class="btn btn-action btn-red">
              <span *ngIf="!registeringBusiness;else loaderBusiness">{{'business-profile.continue' | translate }}</span>
              <ng-template #loaderBusiness>
                <div class="spinner-grow spinner-grow-sm" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </ng-template>
            </button>
          </div>
        </div>

      </form>

    </div>
  </div>
  <!-- * Your workspace -->
  <div *ngIf="showOfficeform">
    <h6>{{'business-profile.your-workspace.title' | translate }}</h6>
    <div class="text mb-4">{{'business-profile.your-workspace.description' | translate }}</div>
    <div class="text mb-4">{{'business-profile.your-workspace.description-2' | translate }}</div>

    <!-- Register Form -->
    <form [formGroup]="formGroup">
      <div class="mb-3">
        <!-- * Building -->
        <label class="semi-bold">{{'business-profile.your-workspace.building' | translate }}</label>
        <app-woke-select  formControlName="coworkingChild" placeholder="{{'business-profile.your-workspace.building-ph' | translate }}" [options]="featured$ | async" valueAccesor="id" noValue="true" >
          <ng-template #item let-option="option">
            {{option.name}}
          </ng-template>
          <ng-template #selected let-value="value">
            {{value.name}}
          </ng-template>
        </app-woke-select>
      </div>

      <div class="mb-3">
        <label class="semi-bold">{{'business-profile.your-workspace.reference' | translate }}</label>
        <input formControlName="reference" class="jumbo-mask" type="text" placeholder="{{'business-profile.your-workspace.reference-ph' | translate }}">
      </div>

      <div class="row justify-content-around">
        <div class="col-6">
          <button (click)="skipOffice()" type="button" class="btn btn-action btn-skip">{{'business-profile.your-workspace.skip' | translate }}</button>
        </div>
        <div class="col-6">
          <button type="button" class="btn btn-action btn-red" (click)="officeContinue()">
            <span *ngIf="!registeringOffice;else loaderOffice">{{'business-profile.your-workspace.continue' | translate }}</span>
            <ng-template #loaderOffice>
              <div class="spinner-grow spinner-grow-sm" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </ng-template>
          </button>
        </div>
      </div>
    </form>


  </div>
</div>
