import { Component, OnInit, OnDestroy, TemplateRef } from '@angular/core';
import { BsModalService, ModalOptions, BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { ModalsService } from 'src/app/shared/modals/modals.service'
import { ApiService } from 'src/app/api.service';
import { LoggedService } from '../../logged/logged.service';
import { Location } from '@angular/common';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Router } from '@angular/router';



const LETTERS = /^[a-zA-ZñáéíóúÑÁÉÍÓÚ\s]*$/;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {

  registerForm: UntypedFormGroup;
  loading = false;
  modalRef: BsModalRef
  content:string;
  title:string;
  errorMessage:string = '';
  terms:string = ''
  checkTerms:boolean = false;


  modalConfig: ModalOptions = {
    class: 'modal-dialog-centered modal-xl modal-privacy'
  };

  fill_in: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private modalService: BsModalService,
    private api: ApiService,
    private loggedService: LoggedService,
    private wokeService: ModalsService,
    private location:Location,
    private gtmService: GoogleTagManagerService,
    private router: Router
  ) {
     // push GTM data layer with a custom event
      const gtmTag = {
        event: 'register-page',
        data: 'user-is-in-register-page',
      };
      this.gtmService.pushTag(gtmTag);
  }

  ngOnInit() {
    this.initRegisterForm()
  }

  ngOnDestroy() {
    this.modalService.hide()
    document.querySelector('body').classList.remove('modal-open');
  }
  goBack(){
    this.location.back()
  }

  initRegisterForm() {
    this.registerForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.pattern(LETTERS)]],
      lastName: ['', [Validators.pattern(LETTERS)]],
      username: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
    },{validator: this.passwordConfirming});
  }
  showPolicies(temp){
    this.wokeService.getPrivacy().subscribe({
      next: (response)=>{
        this.content = response.html;
        this.title = "Privacy Policy"
        if(this.content !== ""){
          this.openTagModal(temp)
        }
      },
      error: (err) =>{
        console.log(err);

      }
    })
  }
  showTerms(temp) {
    this.wokeService.getTerms().subscribe({
      next: (response)=>{
        this.content = response.html;
        this.title = "Terms and Conditions"
        if(this.content !== ""){
          this.openTagModal(temp)
        }
      },
      error: (err) =>{
        console.log(err);

      }
    })

  }
  openTagModal(tagModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(tagModal, {class: 'modal-dialog-centered modal-xl modal-privacy'})
  }

  openErrorModal(tagModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(tagModal, {class: 'modal-dialog-centered modal-xl'})

  }

  passwordConfirming(pass: AbstractControl): { invalid: boolean } {
    if (pass.get('password').value !== pass.get('confirmPassword').value) {
        return {invalid: true};
    }
}

  register(tagModal: TemplateRef<any>) {
    if (this.registerForm.valid) {
      this.loading = true;
      this.customEvent()
      const register$ = this.api.register(this.registerForm.value);
      register$.subscribe(
        () => {this.loading = false
          //this.loggedService.showOnboarding(this.createOnboard());
        },
        failedResponse => {
          this.errorMessage = failedResponse.error.error;
          this.openErrorModal(tagModal)
          this.loading = false;
        }
      );
    } else {
      this.fill_in = true
      this.openErrorModal(tagModal)
    }
  }

  goRegisterSpace(){
    this.router.navigateByUrl('/space-register')
  }

  customEvent() {
    // push GTM data layer with a custom event
    const gtmTag = {
      event: 'register-success',
      data: 'user-registered',
    };
    this.gtmService.pushTag(gtmTag);
  }
  changeTerms(value){
    this.checkTerms = !this.checkTerms;
    console.log(this.checkTerms);
  }


  createOnboard() {
    return {
      name: 'Register',
      sections: [
        {
          title: "Welcome to Woke!",
          description: "We are very excited to welcome you to our community where independent workers and clients can interact, work and grow together. Here, you will find all sorts of services and tools to ignite your full worklife potential",
          image: "assets/img/onboardings/home.png"
        }
      ]
    };
  }

}
