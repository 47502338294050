import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ForgotPasswordComponent } from '../../shared/modals/forgot-password/forgot-password.component'
import { ApiService } from 'src/app/api.service';
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  authForm: UntypedFormGroup;
  loading = false;
  env: string = ''
  errorMessage = false;

  modalConfig: ModalOptions = {
    class: 'modal-dialog-centered'
  };

  killall = new Subject();
  error: boolean;

  constructor(
    public fb: UntypedFormBuilder,
    private modalService: BsModalService,
    private api: ApiService,
    private location: Location,
    private router: Router
  ) { }

  ngOnInit() {

    this.initForm();
       this.env = environment.api.includes('manager-dev') ? 'stage' : 'prod'
  }

  ngOnDestroy() {
    this.killall.next();
    this.killall.complete();
    this.modalService.hide()
    document.querySelector('body').classList.remove('modal-open');
  }

  initForm() {
    this.authForm = this.fb.group({
      username: this.fb.control('', [Validators.required]),
      password: this.fb.control('', [Validators.required, Validators.minLength(5)])
    });
  }

  showModal() {
    this.modalService.show(ForgotPasswordComponent, this.modalConfig)
  }


  goRegister() {
    this.router.navigateByUrl('/register')
  }

  goManager() {
    if (this.env === 'stage') {
            window.location.href = environment.resourceUrl + '/admin/login'
    } else {
      window.location.href = environment.resourceUrl + '/admin/login'
         }
  }

  logIn() {
    if (this.authForm.valid) {
      this.loading = true;
      this.api.login(this.authForm.value).pipe(
        take(1),
        takeUntil(this.killall)
      ).subscribe(
        () => this.loading = false,
        error => {
          console.log(error);
          this.errorMessage = true
          this.error = error.error.message === 'Invalid credentials.' ? true : false
          this.loading = false;
        }
      );
    }
  }

  goBack() {
    this.router.navigateByUrl('/')
    //this.location.back()
  }

}
