import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CheckoutWorkspaceViewModel } from 'src/app/logged/wallet/checkout-workspace/checkout-workspace/checkout-workspace.view-model';
import { map, tap, switchMap, catchError } from 'rxjs/operators';
import { MetaResponse } from 'src/app/api.interfaces';
import { of } from 'rxjs';

export interface BookingResponse {
  id: number;
  msg: string; // Booking Created
  continueTo: string | 'checkout' | 'workspace' | 'invites'; // checkout
  url: string;
}

export interface CheckingOutResponse {
  id: string;
  msg: string;
  error?: boolean;
  url: string;
}

export type CheckoutWorkspaceValidate = {
  chargeId: string;
  id: number;
  status?: 'completed' | 'pending';
  msg?: 'Emails to Invites sent';
  url: string;
}

export type CurrentBookingDataForInvitation = {
  id: number;
  startAt?: string;
  endAt?: string;
  startTimeAt?: string;
  endTimeAt?: string;
  isModifiable?: boolean;
  qr?: string;
  workspace?: {
    id: number;
    name: string;
    profilePicture: string;
    type: string;
  };
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class QuickBookingService {

  constructor(private http: HttpClient) { }

  loadWorkspaceDetail(headers: HttpHeaders, workspaceId: string) {
    return this.http.get(`${environment.api}/workspace/${workspaceId}`, { headers });
  }

  bookingProcess(headers: HttpHeaders, workspaceId: string, checkoutViewModel: CheckoutWorkspaceViewModel) {
    const formData = checkoutViewModel.getFormData();
    return this.http.post(
      `${environment.api}/workspace/booking/process/${workspaceId}`,
      formData,
      { headers }
    ).pipe(
      map((response: MetaResponse) => response.data),
      tap((response: BookingResponse) => console.log(response)),
      switchMap(booking => {
        let checkoutResponse: CheckingOutResponse = { id: `${booking.id}`, msg: 'success', url: `${booking.url}` };
        if (booking.continueTo === 'checkout') {
          const formData = checkoutViewModel.getCheckoutFormData();
          return this.http.post(
            `${environment.api}/workspace/booking/stripe/checkout/process/${booking.id}`,
            formData,
            { headers }
          ).pipe(
            map((response: MetaResponse) => response.data as CheckingOutResponse),
            catchError(() => {
              checkoutResponse.msg = 'Error Checkout';
              return of(checkoutResponse);
            })
          )
        }
        return of(checkoutResponse);
      })
    )
  }

}
