<div class="chat_message_container" [ngClass]="current ? 'current': ''">
  <div class="chat_message" [ngClass]="{'dark': darkMode$ | async }">
    <div class="chat_message_text">
      <ng-content></ng-content>
    </div>
    <div class="chat_message_hour">
      <ng-content select="[hour]"></ng-content>
    </div>
  </div>
</div>
